import styles from './PageLayout.module.scss';
import { ReactElement } from "react";
import BoxLayout from "Layouts/BoxLayout";
import Loader from "Assets/SVG/Loader";
import PageHeader from "./PageHeader";

interface IProps {
    title: string;
    icon: ReactElement;
    children: ReactElement;
    header?: ReactElement;
    includeBox?: boolean;
    loading?: boolean;
}
const PageLayout = (props: IProps) => {
    
    const { title, icon, children, header, includeBox, loading } = props;
    
    return (
        <div className={styles.container}>
            <PageHeader title={title} icon={icon} header={header} />
            
            <div className={`${styles.content} ${!includeBox && styles.padding}`}>
                {includeBox ? (
                    <BoxLayout>
                        {loading ? (<Loader />) : children}
                    </BoxLayout>
                ) : (
                    loading ? (<Loader />) : children
                )}
            </div>
        </div>
    )
}

export default PageLayout
