import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {InvoiceStatus} from "../../../Apis/Models/IInvoiceDto";


interface InvoiceStatusWithLabel {
    value: string | undefined;
    label: string;
}
export const useInvoiceStatus = (): {invoiceStatuses: InvoiceStatusWithLabel[], getInvoiceStatusLabel: (InvoiceStatus: InvoiceStatus) => string} => {

    const { t } = useTranslation();
    
    const invoiceStatuses = useMemo(() => [
        { value: undefined,label: t('invoiceStatus.open')},
        { value: 'Draft', label: t('invoiceStatus.draft')},
        { value: 'Booked', label: t('invoiceStatus.booked')},
        { value: 'Paid', label: t('invoiceStatus.paid')},
    ], [t]);
    
    const getInvoiceStatusLabel = (invoiceStatus: InvoiceStatus): string => {
        return invoiceStatuses.find(x => x.value === invoiceStatus)?.label ?? '';
    }
    
    return { invoiceStatuses, getInvoiceStatusLabel };
    
}