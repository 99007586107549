import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import HandsIcon from "Assets/SVG/HandsIcon";
import { PartnerForm } from "Features/Partners";
import {useDeletePartner, usePartner} from "Apis/PartnerApi";
import Button from "../../Components/Button";
import XIcon from "../../Assets/SVG/XIcon";

const Edit = () => {
    
    const { t } = useTranslation();
    
    const { id } = useParams();
    
    const navigate = useNavigate();

    const { data: partner } = usePartner(parseInt(id!));

    const { mutateAsync: deletePartnerMutation, isLoading: isDeletingPartner } = useDeletePartner();

    const deletePartner = async () => {
        await deletePartnerMutation(partner!);

        navigate(-1)
    }
    
    return (
        <PageLayout title={t('updatePartner')} icon={<HandsIcon />} includeBox header={(
            <Button type={"secondary"} loading={isDeletingPartner} prefix={<XIcon fill={"#FFF"} />} text={t('deletePartner')} onClick={deletePartner} />
        )}>
            <PartnerForm partner={partner} onSubmit={_ => navigate(-1)} />
        </PageLayout>
    )
}

export default Edit;
