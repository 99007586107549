import {useTranslation} from "react-i18next";
import React, {useEffect,  useState} from "react";
import Label from "../../../Components/Label";
import Select from "../../../Components/Select";
import {useCustomers} from "../../../Apis/CustomerApi";
import {IQueryParam} from "../../../Apis/Models/IQueryParams";
import {useProjects} from "../../../Apis/ProjectApi";
import useFilteredList from "../../../Hooks/UseFilteredList";
import {usePartners} from "../../../Apis/PartnerApi";
import {useOrderType} from "../Hooks/UseOrderType";
import {useOrderStatus} from "../Hooks/UseOrderStatus";
import {useTags} from "../../../Apis/TagApi";

interface Fields {
    [key: string]: IQueryParam;
}
interface IProps {
    filterUpdated: (params: Fields, resetParams: boolean) => void;
    currentValue: Fields;
}
const OrderListFilter = (props: IProps) => {

    const { filterUpdated, currentValue } = props;

    const { t } = useTranslation();

    const { data: customers } = useCustomers({ includeAll: true });
    const { data: projects } = useProjects({ includeAll: true });
    const { data: partners } = usePartners({ includeAll: true });
    const { data: tags } = useTags({ includeAll: true });

    const { orderTypes } = useOrderType();

    const { statusses } = useOrderStatus();

    const [ query, setQuery ] = useState<Fields>(currentValue);

    useEffect(() => {
        setQuery(currentValue);
    }, [currentValue]);

    const [ filteredProjects, setProjectFilter, projectFilterValue ] = useFilteredList(projects?.items ?? [], x => [x.name]);
    const [ filteredCustomers, setCustomerFilter, customerFilterValue ] = useFilteredList(customers?.items ?? [], x => [x.name, x.vatNumber]);
    const [ filteredStatuses, setFilteredStatuses ] = useFilteredList(statusses, x => [x.label]);
    const [ filteredOrderTypes, setFilteredOrderTypes ] = useFilteredList(orderTypes, x => [x.label]);
    const [ filteredPartners, setPartnerFilter, partnerFilterValue ] = useFilteredList(partners?.items ?? [], x => [x.name, x.vatNumber]);
    const [ filteredTags, setTagFilter ] = useFilteredList(tags?.items ?? [], x => [x.name]);
    const [ filteredReverseTagSearch, setReverseTagFilter ] = useFilteredList([
        {
            value: "false",
            label: t('hasTag')
        },
        {
            value: "true",
            label: t('doesNotHaveTag')
        }
    ], x => [x.label]);

    const setQueryField = (fieldName: 'customerId' | 'projectId' | 'status' | 'referralId' | 'orderType' | 'tags' | 'reverseTagSearch', value: IQueryParam) => {
        const newValue = {...query, [fieldName]: value }
        setQuery(newValue);
        filterUpdated(newValue, true);
    }

    return (
        <div style={{display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: 20}}>
            <div>
                <Label placeholder={t('customer')}/>
                <Select name={"customerId"} value={query['customerId']}
                        onChange={value => setQueryField('customerId', value)} onFilter={setCustomerFilter}>
                    {!customerFilterValue && (
                        <Select.Option value={undefined} label={t('all')}/>
                    )}
                    {filteredCustomers?.map(customer => <Select.Option key={customer.id} value={customer.id}
                                                                       label={customer.name}/>)}
                </Select>
            </div>
            <div>
                <Label placeholder={t('project')}/>
                <Select name={"projectId"} value={query['projectId']}
                        onChange={value => setQueryField('projectId', value)} onFilter={setProjectFilter}>
                    {!projectFilterValue && (
                        <Select.Option value={undefined} label={t('all')}/>
                    )}
                    {filteredProjects?.map(project => <Select.Option key={project.id} value={project.id}
                                                                     label={project.name}/>)}
                </Select>
            </div>
            <div>
                <Label placeholder={t('referral')}/>
                <Select name={"referralId"} value={query['referralId']}
                        onChange={value => setQueryField('referralId', value)} onFilter={setPartnerFilter}>
                    {!partnerFilterValue && (
                        <Select.Option value={undefined} label={t('all')}/>
                    )}
                    {filteredPartners?.map(partner => <Select.Option key={partner.id} value={partner.id}
                                                                     label={partner.name}/>)}
                </Select>
            </div>
            <div>
                <Label placeholder={t('orderType.label')}/>
                <Select name={"orderType"} value={query['orderType']}
                        onChange={value => setQueryField('orderType', value)}
                        onFilter={setFilteredOrderTypes}>
                    {filteredOrderTypes?.map((status, index) => <Select.Option key={index} value={status.value}
                                                                               label={status.label}/>)}
                </Select>
            </div>
            <div>
                <Label placeholder={t('status')}/>
                <Select name={"status"} value={query['status']} onChange={value => setQueryField('status', value)} onFilter={setFilteredStatuses}>
                    {filteredStatuses?.map((status, index) => <Select.Option key={index} value={status.value} label={status.label}/>)}
                </Select>
            </div>

            <div>
                <Label placeholder={t('reverseTagSearch')}/>
                <Select name={"reverseTagSearch"} value={query['reverseTagSearch']} onChange={value => setQueryField('reverseTagSearch', value)} onFilter={setReverseTagFilter}>
                    {filteredReverseTagSearch?.map((tag, index) => <Select.Option key={index} value={tag.value} label={tag.label}/>)}
                </Select>
            </div>

            <div>
                <Label placeholder={t('tag')}/>
                <Select name={"tags"} multiple value={query['tags']} onChange={value => setQueryField('tags', value)} onFilter={setTagFilter}>
                    <Select.Option value={undefined} label={t('select')} />
                    {filteredTags?.map((tag, index) => <Select.Option key={index} value={tag.id} label={tag.name}/>)}
                </Select>
            </div>

        </div>
    )

}

export default OrderListFilter