import * as React from "react"
import { SVGProps } from "react"

const UncheckedIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} {...props} viewBox={"0 0 16 16"}>
        <g data-name="Group 1329" transform="translate(10361.389 -4533.612)">
            <circle
                data-name="Ellipse 43"
                cx={8}
                cy={8}
                r={8}
                transform="translate(-10361.389 4533.612)"
                fill="#EADEDE"
            />
        </g>
    </svg>
)

export default UncheckedIcon
