import * as React from "react"
import { SVGProps } from "react"
const CalculatorIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={"0 0 13.333  16"} width={13.333} height={16} {...props}>
        <path
            fill={props.fill ?? '#707070'}
            d="M11.5 0H1.833A1.835 1.835 0 0 0 0 1.833v12.334A1.835 1.835 0 0 0 1.833 16H11.5a1.835 1.835 0 0 0 1.833-1.833V1.833A1.835 1.835 0 0 0 11.5 0ZM5.187 12.48a.5.5 0 1 1-.707.707l-.48-.48-.48.48a.5.5 0 0 1-.707-.708l.48-.479-.48-.48a.5.5 0 0 1 .707-.707l.48.48.48-.48a.5.5 0 0 1 .707.707l-.48.48ZM4.833 8.5H4.5v.333a.5.5 0 1 1-1 0V8.5h-.333a.5.5 0 0 1 0-1H3.5v-.333a.5.5 0 1 1 1 0V7.5h.333a.5.5 0 0 1 0 1Zm5.333 4.833H8.5a.5.5 0 1 1 0-1h1.667a.5.5 0 1 1 0 1Zm0-1.667H8.5a.5.5 0 1 1 0-1h1.667a.5.5 0 1 1 0 1Zm0-3H8.5a.5.5 0 0 1 0-1h1.667a.5.5 0 0 1 0 1Zm1.167-4.5a1.168 1.168 0 0 1-1.167 1.167h-7A1.168 1.168 0 0 1 2 4.167v-1A1.168 1.168 0 0 1 3.167 2h7a1.168 1.168 0 0 1 1.167 1.167Z"
            data-name="002-balance-sheet"
        />
    </svg>
)
export default CalculatorIcon
