import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PrintIcon from "Assets/SVG/PrintIcon";
import {usePrintingType} from "../../../Apis/PrintingTypeApi";
import PrintingTypeForm from "../../../Features/Prints/Components/PrintingTypeForm";

const Edit = () => {
    
    const { t } = useTranslation();
    
    const { id } = useParams();
    
    const { data: printingType } = usePrintingType(parseInt(id!));
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={t('updatePrintingType')} icon={<PrintIcon />} includeBox>
            <PrintingTypeForm printingType={printingType} onSubmit={_ => navigate(-1)} />
        </PageLayout>
    )
}

export default Edit;
