import { useTranslation } from "react-i18next";
import PageLayout from "Layouts/PageLayout";
import { useInventoryItems } from "Apis/InventoryItemsApi";
import { InventoryItemsList } from "Features/Products";
import InventoryIcon from "Assets/SVG/InventoryIcon";
import InventoryListFilter from "Features/Products/Components/InventoryListFilter";
import Separator from "Components/Separator";
import Pagination from "Components/Pagination";
import useQueryParams from "Hooks/UseQueryParams";

const Index = () => {
    
    const { t } = useTranslation();

    const [ filter, setFilter ] = useQueryParams();
    
    const {  data: inventoryItems, isLoading } = useInventoryItems(filter);

    return (
        <PageLayout title={t('inventoryItems')} icon={<InventoryIcon />}>
            <div>
                <InventoryListFilter filterUpdated={setFilter} currentValue={filter} />

                <Separator style={{margin: '30px 0'}} />

                <InventoryItemsList inventoryItems={inventoryItems?.items} isLoading={isLoading} />

                <Pagination paginatedList={inventoryItems} onPageChange={newPage => setFilter({page: newPage})} />
            </div>
        </PageLayout>
    )
}

export default Index
