import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PartnerForm } from "Features/Partners";
import HandsIcon from "Assets/SVG/HandsIcon";

const New = () => {
    
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={t('newPartner')} icon={<HandsIcon />} includeBox>
            <PartnerForm onSubmit={_ => navigate(-1)} />
        </PageLayout>
    )
}

export default New
