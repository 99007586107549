import { IOrderLineDto} from "Apis/Models/IOrderDto";
import styles from './OrderLineDetails.module.scss';
import {useTranslation} from "react-i18next";
import {formatNumber} from "../../../Utils/NumberUtils";
import CheckmarkIcon from "../../../Assets/SVG/CheckmarkIcon";
import XIcon from "../../../Assets/SVG/XIcon";
import { useNavigate } from "react-router-dom";
import {getOrderLineUnitSalesPrice, getOrderLineTotal} from "../Utils/OrderLineUtils";

interface IProps {
    orderLine: IOrderLineDto;
}
const OrderLineDetails = (props: IProps) => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const { orderLine } = props;
    
    return (
        <div className={styles.container}>
            <div>
                <img height={100} width={100} src={orderLine.product.productImages?.find(Boolean)?.url} alt={orderLine.product.productImages?.find(Boolean)?.fileName} />
            </div>

            <div onClick={() => navigate(`/products/edit/${orderLine.productId}`)}>
                <div className={styles.header}>
                    {t('product')}
                </div>

                <div className={`${styles.title} ${styles.link}`}>
                    {orderLine.product.readyForUse ? <CheckmarkIcon/> : <XIcon/>} {orderLine.product.name}
                </div>

                <div className={`${styles.subtitle}`}>
                    {t('sku')}: {orderLine.product.sku}
                </div>

                <div className={styles.subtitle}>
                    {orderLine.comment}
                </div>
            </div>

            <div>
                {orderLine.product.productType === 'Product' && (
                    <>
                    <div className={styles.header}>
                            {t('inventoryItem')}
                        </div>
                        <div className={styles.title}>
                            {orderLine.product?.inventoryItem?.name}
                        </div>
                        <div className={styles.subtitle}>
                            {orderLine.product?.inventoryItem?.sku}
                        </div>
                    </>
                )}
            </div>

            <div>
                <div className={styles.header}>
                    {t('price')}
                </div>
                <div className={styles.title}>
                     {orderLine.quantity} X {getOrderLineUnitSalesPrice(orderLine)} DKK
                </div>
                <div className={styles.subtitle}>
                    {formatNumber(getOrderLineTotal(orderLine))} DKK
                </div>
            </div>

        </div>
    )

}

export default OrderLineDetails;