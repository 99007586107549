import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PrintsIcon from "Assets/SVG/PrintsIcon";
import {useDeletePrint, usePrint} from "Apis/PrintApi";
import PrintForm from "Features/Prints/Components/PrintForm";
import Button from "../../Components/Button";
import XIcon from "../../Assets/SVG/XIcon";

const Edit = () => {
    
    const { t } = useTranslation();
    
    const { id } = useParams();
    
    const navigate = useNavigate();

    const { data: print } = usePrint(parseInt(id!));
    const { mutateAsync: deletePrintMutation, isLoading: isDeletingPrint } = useDeletePrint();

    const deletePrint = async () => {
        await deletePrintMutation(print!);

        navigate(-1)
    }

    return (
        <PageLayout title={t('updatePrint')} icon={<PrintsIcon />} includeBox header={(
            <Button type={"secondary"} loading={isDeletingPrint} prefix={<XIcon fill={"#FFF"} />} text={t('deletePrint')} onClick={deletePrint} />
        )}>
            <PrintForm print={print} onSubmit={_ => navigate(-1)} />
        </PageLayout>
    )
}

export default Edit;
