import {IOrderDto} from "../../../Apis/Models/IOrderDto";
import {downloadInvoice, useInvoicesForOrder} from "../../../Apis/InvoicesApi";
import FlexRow from "../../../Components/FlexRow";
import styles from "./OrderInvoices.module.scss";
import {useTranslation} from "react-i18next";
import React from "react";
import FileIcon from "../../../Assets/SVG/FileIcon";
import ExternalLinkIcon from "../../../Assets/SVG/ExternalLinkIcon";
import DownloadIcon from "../../../Assets/SVG/DownloadIcon";
import {useInvoiceStatus} from "../Hooks/UseInvoiceStatus";

interface IProps {
    order: IOrderDto;
}
const OrderInvoices = (props: IProps) => {
    
    const { order } = props;
    
    const { t } = useTranslation();
    
    const { data: invoices } = useInvoicesForOrder(order.id);

    const { getInvoiceStatusLabel } = useInvoiceStatus();

    return (
        <div>
            <div className={styles.sectionTitle}>
                <FileIcon fill={"#6D4D4D"} />
                {t('invoices')}
            </div>
            {invoices?.map(x => (
                <FlexRow className={styles.invoiceLine} key={x.id}>
                    <div style={{width: 200}}>
                        {x.invoiceReference}
                    </div>
                    <div style={{width: 200}}>
                        {getInvoiceStatusLabel(x.invoiceStatus)}
                    </div>
                    <div style={{width: 150}}>
                        {x.bookedInvoiceNumber !== 0 && (
                            <>
                                {t('invoiceNumber')}: {x.bookedInvoiceNumber}
                            </>
                        )}
                    </div>
                    <div style={{flex: 1, justifyContent: 'flex-end', display: 'flex'}}>
                        {x.invoiceStatus === 'Draft' && (
                            <div className={"clickable"} onClick={() => window.open(x.economicUrl, '_blank')}><FlexRow><ExternalLinkIcon /><div style={{paddingLeft: 10}}>{t('openInEconomic')}</div></FlexRow></div>
                        )}
                        {x.invoiceStatus !== 'Draft' && (
                            <div className={"clickable"} onClick={() => downloadInvoice(x)}><FlexRow><DownloadIcon width={14} height={14} /><div style={{paddingLeft: 10}}>{t('downloadInvoice')}</div></FlexRow></div>
                        )}
                    </div>
                </FlexRow>
            ))}
        </div>
    )

}

export default OrderInvoices