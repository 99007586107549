import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import { IPaginatedList } from "Apis/Models/IPaginatedList";
import { IPrintPlacementDto } from "Apis/Models/IPrintDto";
import {IQueryParams} from "./Models/IQueryParams";

export const PRINT_PLACEMENTS_CACHE_KEY = 'PRINT_PLACEMENTS_CACHE_KEY '
export const usePrintPlacements = (queryable: Partial<IQueryParams> | null = null) => {
    return useQuery<IPaginatedList<IPrintPlacementDto>>([PRINT_PLACEMENTS_CACHE_KEY, queryable], async () => {
        return await request<IPaginatedList<IPrintPlacementDto>>({
            url: `/prints/placement`,
            method: 'GET',
            queryParams: queryable
        })
    });
}

export const usePrintPlacement = (id: number) => {
    return useQuery<IPrintPlacementDto>([PRINT_PLACEMENTS_CACHE_KEY, id], async () => {
        return await request<IPrintPlacementDto>({
            url: `/prints/placement/${id}`,
            method: 'GET',
        })
    });
}

export const useMutatePrintPlacement = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (printPlacement: IPrintPlacementDto): Promise<IPrintPlacementDto> => {
        if (!printPlacement.id) {
            return await request<IPrintPlacementDto>({
                url: `/prints/placement`,
                method: 'POST',
                data: printPlacement
            })
        } else {
            return await request<IPrintPlacementDto>({
                url: `/prints/placement/${printPlacement.id}`,
                method: 'PUT',
                data: printPlacement
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(PRINT_PLACEMENTS_CACHE_KEY)
        }
    })
}
