import OrdersIcon from "../../Assets/SVG/OrdersIcon";
import Space from "../../Components/Space";
import PageLayout from "../../Layouts/PageLayout";
import {useTranslation} from "react-i18next";
import OrderForm from "../../Features/Orders/Components/OrderForm";
import {useOrder} from "../../Apis/OrderApi";
import {useParams} from "react-router-dom";

const Edit = () => {

    const { t } = useTranslation();

    const { id } = useParams();

    const { data: order, isLoading: isLoadingOrder } = useOrder(parseInt(id!));

    return (
        <PageLayout title={`${t('order')} #${order?.orderNumber}`} icon={<OrdersIcon />} includeBox loading={isLoadingOrder}>
            <Space size={"large"} direction={"vertical"}>
                <OrderForm order={order!} />
            </Space>
        </PageLayout>
    )

}

export default Edit;