import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import List from "Components/List/List";
import FlexRow from "Components/FlexRow";
import DotsIcon from "Assets/SVG/DotsIcon";
import {IPrintingTypeDto} from "Apis/Models/IPrintDto";

interface IProps {
    printingTypes?: IPrintingTypeDto[];
    isLoading: boolean;
}
const PrintingTypeList = (props: IProps) => {
    
    const { t } = useTranslation();
    
    const { printingTypes, isLoading } = props;
    
    const navigate = useNavigate();
    
    return (
        <List<IPrintingTypeDto> data={printingTypes} loading={isLoading} columns={[
            {
                title: t('name'),
                textRender: (record) => record.name
            },
            {
                title: t('functions'),
                size: "min-content",
                customRender: (record) => <FlexRow className={"h-100 clickable"} justify={"center"} onClick={() => navigate(`/prints/printingType/edit/${record.id}`)} ><DotsIcon /></FlexRow>
            }
        ]} keyExtractor={(record) => record.id.toString()} />
    )
}

export default PrintingTypeList
