import { useForm } from "react-hook-form";
import ControlledInput from "Components/Input";
import { useTranslation } from "react-i18next";
import Button from "Components/Button";
import Space from "Components/Space";
import FlexRow from "Components/FlexRow";
import { useEffect, useState } from "react";
import { IPartnerDto } from "Apis/Models/IPartnerDto";
import { useMutatePartner } from "Apis/PartnerApi";
import styles from './PartnerForm.module.scss';
import FileUpload from "Components/FileUpload/FileUpload";
import { IFileInformationDto } from "Apis/Models/IFileDto";
import Loader from "../../../Assets/SVG/Loader";

interface IProps {
    partner?: IPartnerDto;
    onSubmit?: (data: IPartnerDto) => void;
}
const PartnerForm = (props: IProps) => {
    
    const { t } = useTranslation();
    
    const { control, handleSubmit, reset } = useForm<IPartnerDto>();
    const [file, setFile] = useState<IFileInformationDto>();
    const { mutateAsync, isLoading } = useMutatePartner();
    
    useEffect(() => {
        if (props.partner) {
            reset(props.partner);
            if (props.partner.image) {
                setFile(props.partner.image);
            }
        }
    }, [reset, props.partner])
    
    const onSubmit = async(data: IPartnerDto) => {
        await mutateAsync({ ...data, image: file });
        
        if (props.onSubmit) {
            props.onSubmit(data);
        }
        
        reset();
    }
    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Space direction={"vertical"} size={"large"}>
    
                <div className={styles.imageRow}>
                    <FileUpload callback={setFile} accept={['.jpeg', '.jpg', '.png']} render={loading => (
                        <div className={styles.fileUpload}>
                            {loading ? (<Loader color={"red"} height={50} width={50} />) : (<>
                                {!!file ? (
                                    <img height={150} width={150} src={file.url} alt={file.fileName} />
                                ) : (
                                    <>
                                        {t('upload')}
                                    </>
                                )}
                            </>)}
                        </div>
                    )} />
                    <ControlledInput rules={{required: true}} name={"name"} control={control} placeholder={t('name')} />
                </div>
                <ControlledInput name={"vatNumber"} control={control} placeholder={t('vatNumber')} />
                <ControlledInput name={"address"} control={control} placeholder={t('address')} />
                <ControlledInput name={"zip"} control={control} placeholder={t('zip')} />
                <ControlledInput name={"city"} control={control} placeholder={t('city')} />
                <ControlledInput name={"email"} control={control} placeholder={t('email')} />
                <ControlledInput name={"phone"} control={control} placeholder={t('phone')} />
                
                <FlexRow justify={"end"}>
                    <Button buttonType={"submit"} type={"primary"} text={t('save')} loading={isLoading} />
                </FlexRow>
            </Space>
        </form>
    )
}

export default PartnerForm
