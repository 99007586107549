import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import Label from "../../../Components/Label";
import Select from "../../../Components/Select";
import {IQueryParam} from "../../../Apis/Models/IQueryParams";
import {useProjects} from "../../../Apis/ProjectApi";
import useFilteredList from "../../../Hooks/UseFilteredList";
import {usePrintSizes} from "../../../Apis/PrintSizeApi";
import {usePrintPlacements} from "../../../Apis/PrintPlacementApi";
import {Input} from "../../../Components/Input";
import {usePrintType} from "../Hooks/UsePrintType";

interface Fields {
    [key: string]: IQueryParam;
}
interface IProps {
    filterUpdated: (params: Fields, resetParams: boolean) => void;
    currentValue: Fields;
}
const PrintListFilter = (props: IProps) => {

    const { filterUpdated, currentValue } = props;
    const { t } = useTranslation();

    const { data: projects } = useProjects({ includeAll: true });
    const { data: printPlacements } = usePrintPlacements({ includeAll: true });
    const { printTypes } = usePrintType();

    const [ query, setQuery ] = useState<Fields>(currentValue);

    useEffect(() => {
        setQuery(currentValue);
    }, [currentValue]);

    const [ filteredProjects, setProjectFilter, projectFilterValue ] = useFilteredList(projects?.items ?? [], x => [x.name]);
    const [ filteredPrintTypes, setPrintTypeFilter, printTypeFilterValue ] = useFilteredList(printTypes, x => [x.label]);
    const [ filteredPrintPlacements, setPrintPlacementFilter, printPlacementFilterValue ] = useFilteredList(printPlacements?.items ?? [], x => [x.name]);

    const setQueryField = (fieldName: 'search' | 'printPlacementId' | 'projectId' | 'printType', value: IQueryParam) => {
        const newValue = {...query, [fieldName]: value }
        setQuery(newValue);
        filterUpdated(newValue, true);
    }

    return (
        <div style={{display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 20}}>
            <div>
                <Input name={"search"} value={query['search'] ?? ''} onChange={value => setQueryField('search', value)} placeholder={t('search')} />
            </div>

            <div>
                <Label placeholder={t('project')} />
                <Select name={"projectId"} value={query['projectId']} onChange={value => setQueryField('projectId', value)} onFilter={setProjectFilter}>
                    {!projectFilterValue && (
                        <Select.Option value={undefined} label={t('all')} />
                    )}
                    {filteredProjects?.map(project => <Select.Option key={project.id} value={project.id} label={project.name} />)}
                </Select>
            </div>
            <div>
                <Label placeholder={t('printPlacement')} />
                <Select name={"printPlacementId"} value={query['printPlacementId']} onChange={value => setQueryField('printPlacementId', value)} onFilter={setPrintPlacementFilter}>
                    {!printPlacementFilterValue && (
                        <Select.Option value={undefined} label={t('all')} />
                    )}
                    {filteredPrintPlacements?.map(printPlacement => <Select.Option key={printPlacement.id} value={printPlacement.id} label={printPlacement.name} />)}
                </Select>
            </div>
            <div>
                <Label placeholder={t('printType.printType')} />
                <Select name={"printType"} value={query['printType']} onChange={value => setQueryField('printType', value)} onFilter={setPrintTypeFilter}>
                    {filteredPrintTypes?.map((printSize) => <Select.Option key={printSize.value} value={printSize.value} label={printSize.label} />)}
                </Select>
            </div>

        </div>
    )

}

export default PrintListFilter