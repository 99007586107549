import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {PrintType} from "../../../Apis/Models/IPrintDto";


interface PrintTypeWithLabel {
    value: string | undefined;
    label: string;
}
export const usePrintType = (): {printTypes: PrintTypeWithLabel[], getPrintTypeLabel: (printType: PrintType) => string} => {

    const { t } = useTranslation();

    const printTypes = useMemo(() => [
        { value: undefined, label: t('all')},
        { value: 'Unknown', label: t('notSelected')},
        { value: 'DTG', label: t('printType.DTG')},
        { value: 'DTF', label: t('printType.DTF')},
        { value: 'Plot', label: t('printType.Plot')},
        { value: 'Embroidery', label: t('printType.Embroidery')},
        { value: 'Printing', label: t('printType.Printing')},
        { value: 'Transfer', label: t('printType.Transfer')},
    ], [t]);
    
    const getPrintTypeLabel = (printType: PrintType): string => {
        return printTypes.find(x => x.value === printType)?.label ?? '';
    }
    
    return { printTypes: printTypes, getPrintTypeLabel };
    
}
