import {OrderStatus} from "Apis/Models/IOrderDto";
import styles from "./OrderStatusBullet.module.scss";
interface IProps {
    orderStatus: OrderStatus;
}

const OrderStatusBullet = (props: IProps) => {

    const {orderStatus} = props;

    const getBackgroundColor = () => {
        switch (orderStatus) {
            case "Received":
                return styles.grey;
            case "Accepted":
                return styles.blue;
            case "Produced":
                return styles.yellow;
            case "Delivered":
                return styles.green;
            case "Finished":
                return styles.green;
            case "Cancelled":
                return styles.red;
        }
    }

    return (
        <div className={`${styles.bullet} ${getBackgroundColor()}`}>

        </div>
    )

}

export default OrderStatusBullet