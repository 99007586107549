import PeopleIcon from "Assets/SVG/PeopleIcon";
import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ApiUserForm from "../../Features/Users/Components/ApiUserForm";

const NewApi = () => {
    
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={t('newApi')} icon={<PeopleIcon />} includeBox>
            <ApiUserForm onSubmit={_ => navigate(-1)} />
        </PageLayout>
    )
}

export default NewApi
