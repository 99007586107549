import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PrintsIcon from "Assets/SVG/PrintsIcon";
import PrintForm from "Features/Prints/Components/PrintForm";

const New = () => {
    
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={t('newPrint')} icon={<PrintsIcon />} includeBox>
            <PrintForm onSubmit={_ => navigate(-1)} />
        </PageLayout>
    )
}

export default New
