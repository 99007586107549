import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import { IPaginatedList } from "Apis/Models/IPaginatedList";
import { IPrintSizeDto } from "Apis/Models/IPrintDto";
import {IQueryParams} from "./Models/IQueryParams";

export const PRINT_SIZES_CACHE_KEY = 'PRINT_SIZES_CACHE_KEY '
export const usePrintSizes = (queryable: Partial<IQueryParams> | null = null) => {
    return useQuery<IPaginatedList<IPrintSizeDto>>([PRINT_SIZES_CACHE_KEY, queryable], async () => {
        return await request<IPaginatedList<IPrintSizeDto>>({
            url: `/prints/size`,
            method: 'GET',
            queryParams: queryable
        })
    });
}

export const usePrintSize = (id: number) => {
    return useQuery<IPrintSizeDto>([PRINT_SIZES_CACHE_KEY, id], async () => {
        return await request<IPrintSizeDto>({
            url: `/prints/size/${id}`,
            method: 'GET',
        })
    });
}

export const useMutatePrintSize = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (printSize: IPrintSizeDto): Promise<IPrintSizeDto> => {
        if (!printSize.id) {
            return await request<IPrintSizeDto>({
                url: `/prints/size`,
                method: 'POST',
                data: printSize
            })
        } else {
            return await request<IPrintSizeDto>({
                url: `/prints/size/${printSize.id}`,
                method: 'PUT',
                data: printSize
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(PRINT_SIZES_CACHE_KEY)
        }
    })
}
