import DotsIcon from "Assets/SVG/DotsIcon";
import List from "Components/List/List";
import { IUserDto } from "Apis/Models/IUserDto";
import { useNavigate } from "react-router-dom";
import FlexRow from "Components/FlexRow";
import { useTranslation } from "react-i18next";

interface IProps {
    users?: IUserDto[];
    isLoading: boolean;
}
const UsersList = (props: IProps) => {
    
    const { t } = useTranslation();
    
    const { users, isLoading } = props;
    
    const navigate = useNavigate();
    
    return (
        <List<IUserDto> data={users} loading={isLoading} columns={[
            {
                title: t('name'),
                textRender: (record) => record.name
            },
            {
                title: t('email'),
                textRender: (record) => record.email
            },
            {
                title: t('apiKey'),
                textRender: (record) => record.apiKey
            },
            {
                title: t('functions'),
                size: "min-content",
                customRender: (record) => <FlexRow className={"h-100 clickable"} justify={"center"} onClick={() => navigate(`/users/edit/${record.id}`)} ><DotsIcon /></FlexRow>
            }
        ]} keyExtractor={(record) => record.id.toString()} />
    )
}

export default UsersList;
