import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PrintSizeForm } from "Features/Prints";
import PrintIcon from "Assets/SVG/PrintIcon";

const New = () => {
    
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={t('newPrintSize')} icon={<PrintIcon />} includeBox>
            <PrintSizeForm onSubmit={_ => navigate(-1)} />
        </PageLayout>
    )
}

export default New
