import * as React from "react"
import { SVGProps } from "react"
const ExclamationMarkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    viewBox={"0 0 20 21"}
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#E91D25"
        d="M10 .5C4.478.5 0 4.978 0 10.5c0 5.523 4.478 10 10 10 5.523 0 10-4.477 10-10 0-5.522-4.477-10-10-10Zm0 16.383c-.782 0-1.317-.602-1.317-1.383 0-.803.558-1.383 1.317-1.383.803 0 1.317.58 1.317 1.383 0 .782-.514 1.383-1.317 1.383Zm.518-5.282c-.199.679-.826.69-1.036 0-.24-.797-1.096-3.821-1.096-5.785 0-2.592 3.242-2.604 3.242 0 0 1.976-.902 5.078-1.11 5.785Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .5h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default ExclamationMarkIcon
