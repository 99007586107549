import {Navigate, Route, Routes} from "react-router-dom";
import Index from "./Index";

const BIRoutes = () => {

    return (
        <Routes>
            <Route path={`/`} element={<Index />}/>

            <Route path={"*"} element={<Navigate to={"/bi"}/>}/>
        </Routes>
    )

}

export default BIRoutes