import styles from "./TotalComponent.module.scss";
import FlexRow from "./FlexRow";
import React from "react";

interface Props {
    firstHeader: string;
    firstTotal: string;
    firstIcon: React.ReactElement;
    secondHeader: string;
    secondTotal: string;
    secondIcon: React.ReactElement;
    thirdHeader: string;
    thirdTotal: string;
    thirdIcon: React.ReactElement;
    fourthHeader: string;
    fourthTotal: string;
    fourthIcon: React.ReactElement;
}

const TotalComponent = (props: Props) => {

    return (
        <FlexRow justify={"end"} style={{paddingTop: 10}}>
            <div className={styles.totalContainer}>
                <div className={`${styles.content} ${styles.first}`}>
                    {React.cloneElement(props.firstIcon, {fill: 'red', height: 30, width: 30})}
                    <div>
                        <span className={styles.header}>
                            {props.firstHeader}
                        </span>
                        <span className={styles.total}>
                            {props.firstTotal}
                        </span>
                    </div>
                </div>
                <div className={`${styles.content} ${styles.first}`}>
                    {React.cloneElement(props.secondIcon, {fill: 'red', height: 30, width: 30})}
                    <div>
                        <span className={styles.header}>
                            {props.secondHeader}
                        </span>
                        <span className={styles.total}>
                            {props.secondTotal}
                        </span>
                    </div>
                </div>
                <div className={`${styles.content} ${styles.first}`}>
                    {React.cloneElement(props.secondIcon, {fill: 'red', height: 30, width: 30})}
                    <div>
                        <span className={styles.header}>
                            {props.thirdHeader}
                        </span>
                        <span className={styles.total}>
                            {props.thirdTotal}
                        </span>
                    </div>
                </div>
                <div className={`${styles.content} ${styles.last}`}>
                    {React.cloneElement(props.fourthIcon, {fill: 'white', height: 30, width: 30})}
                    <div>
                        <span className={styles.header}>
                            {props.fourthHeader}
                        </span>
                        <span className={styles.total}>
                            {props.fourthTotal}
                        </span>
                    </div>
                </div>

            </div>
        </FlexRow>
    )

}

export default TotalComponent