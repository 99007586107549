import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import { IPaginatedList } from "Apis/Models/IPaginatedList";
import { IProjectDto } from "Apis/Models/IProjectDto";
import {IQueryParams} from "./Models/IQueryParams";

export const PROJECTS_CACHE_KEY = 'PROJECTS_CACHE_KEY '
export const useProjects = (queryable: Partial<IQueryParams> | null = null) => {
    return useQuery<IPaginatedList<IProjectDto>>([PROJECTS_CACHE_KEY, queryable], async () => {
        return await request<IPaginatedList<IProjectDto>>({
            url: `/projects`,
            method: 'GET',
            queryParams: queryable
        })
    });
}

export const useProject = (id: number) => {
    return useQuery<IProjectDto>([PROJECTS_CACHE_KEY, id], async () => {
        return await request<IProjectDto>({
            url: `/projects/${id}`,
            method: 'GET',
        })
    });
}

export const useMutateProject = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (project: IProjectDto): Promise<IProjectDto> => {
        if (!project.id) {
            return await request<IProjectDto>({
                url: `/projects`,
                method: 'POST',
                data: project
            })
        } else {
            return await request<IProjectDto>({
                url: `/projects/${project.id}`,
                method: 'PUT',
                data: project
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(PROJECTS_CACHE_KEY)
        }
    })
}

export const useDeleteProject = () => {

    const queryClient = useQueryClient();

    return useMutation(async (projects: IProjectDto): Promise<IProjectDto> => {
        return await request<IProjectDto>({
            url: `/projects/${projects.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(PROJECTS_CACHE_KEY)
        }
    })
}
