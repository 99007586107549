import { useForm } from "react-hook-form";
import Space from "Components/Space";
import FlexRow from "Components/FlexRow";
import Button from "Components/Button";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ControlledInput from "Components/Input";
import { auth } from "FirebaseSetup/auth";
import useToasts from "../../../Hooks/UseToasts";
import {useNavigate} from "react-router-dom";

interface ResetPasswordFormFields {
    password: string;
}

interface IProps {
    oobCode: string
}

const NewPasswordForm = (props: IProps) => {
    
    const { oobCode } = props;
    
    const { t } = useTranslation();

    const { showSuccessToast, showErrorToast } = useToasts();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    
    const { control, handleSubmit, formState: { errors } } = useForm<ResetPasswordFormFields>();
    
    const resetPassword = async (data: ResetPasswordFormFields) => {
        setLoading(true);
    
        try {
            await auth.confirmPasswordReset(oobCode, data.password);

            showSuccessToast(t('passwordResetSuccess'));

            navigate('/signin');
        }
        catch (e) {
            showErrorToast(t('anErrorOccurred'));
        }
        finally {
            setLoading(false);
        }
    }
    
    return (
        <form onSubmit={handleSubmit(resetPassword)}>
            <Space direction={"vertical"} size={"large"}>
                <ControlledInput name="password" control={control} placeholder={t('auth:newPassword')} type="password" errors={errors} rules={{required: true, minLength: 6}} />
                
                <FlexRow justify={"end"}>
                    <Button loading={loading} buttonType={"submit"} text={t('auth:resetpassword')} type={"primary"} />
                </FlexRow>
            </Space>
        </form>
    )
}

export default NewPasswordForm
