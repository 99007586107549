import {IMutateProductDto, IProductDto} from "Apis/Models/IProductDto";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import Space from "Components/Space";
import styles from "./ProductForm.module.scss";
import ControlledInput, {Input} from "Components/Input";
import ControlledSelect from "Components/Select/ControlledSelect";
import Select from "Components/Select";
import FlexRow from "Components/FlexRow";
import Button from "Components/Button";
import {useInventoryItems} from "Apis/InventoryItemsApi";
import {useMutateProduct} from "Apis/ProductApi";
import {useProjects} from "Apis/ProjectApi";
import useFilteredList from "../../../Hooks/UseFilteredList";
import FileUpload from "../../../Components/FileUpload/FileUpload";
import Loader from "../../../Assets/SVG/Loader";
import {IFileInformationDto} from "../../../Apis/Models/IFileDto";
import {useDesigns} from "../../../Apis/DesignApi";
import XIcon from "../../../Assets/SVG/XIcon";
import Label from "../../../Components/Label";
import ControlledTextArea from "../../../Components/TextArea";

interface IProps {
    product?: Partial<IProductDto>;
    onSubmit?: (data: IMutateProductDto) => void;
}

const ProductForm = (props: IProps) => {

    const { product, onSubmit } = props;

    const { t } = useTranslation();

    const { setValue, control, handleSubmit, reset, watch } = useForm<IMutateProductDto>();

    const projectId = watch('projectId');

    const [productImages, setProductImages] = useState<IFileInformationDto[]>([]);
    
    const addProductImage = (file: IFileInformationDto) => {
        setProductImages(current => [...current, file]);
    }

    const removeProductImage = (index: number) => {
        setProductImages(current => {
            let copy = [...current]
            copy.splice(index, 1)
            return copy;
        });
    }

    const { data: projects } = useProjects({includeAll: true});
    const { data: designs } = useDesigns({includeAll: true, projectId: projectId});
    const { data: inventoryItems } = useInventoryItems({
        includeAll: true,
    });

    const [ filteredInventoryItems, setInventoryFilter ] = useFilteredList(inventoryItems?.items ?? [], x => [x.name, x.sku]);
    const [ filteredProjects, setProjectFilter ] = useFilteredList(projects?.items ?? [], x => [x.name]);
    const [ filteredDesigns, setDesignFilter ] = useFilteredList(designs?.items ?? [], x => [x.name]);

    const { mutateAsync, isLoading } = useMutateProduct();

    useEffect(() => {
        if (product) {
            reset(product);
            setProductImages(product.productImages ?? []);
        }
    }, [reset, product])

    const save = async(data: IMutateProductDto) => {
        await mutateAsync({ ...data, productImages: productImages });

        if (onSubmit) {
            onSubmit(data);
        }

        reset();
    }

    const onDesignChange = () => {
        generateProductName(false);
        setDesignUnitPrices(false);
    }

    const setDesignUnitPrices = (force: boolean = true) => {
        let currentCostPrice = watch('designUnitCostPrice');
        let currentSalesPrice = watch('designUnitSalesPrice');

        if (!force && ((!!currentCostPrice && currentCostPrice > 0) || (!!currentSalesPrice && currentSalesPrice > 0))) {
            return;
        }

        const design = designs?.items.find(x => x.id === watch('designId'));

        setValue('designUnitCostPrice', design?.unitCostPrice);
        setValue('designUnitSalesPrice', design?.unitSalesPrice);
    }

    const setInventoryUnitPrices = (force: boolean = true) => {
        let currentCostPrice = watch('inventoryItemUnitCostPrice');
        let currentSalesPrice = watch('inventoryItemUnitSalesPrice');

        if (!force && ((!!currentCostPrice && currentCostPrice > 0) || (!!currentSalesPrice && currentSalesPrice > 0))) {
            return;
        }

        const inventoryItem = inventoryItems?.items.find(x => x.id === watch('inventoryItemId'));

        setValue('inventoryItemUnitCostPrice', inventoryItem?.unitCostPrice);
        //setValue('inventoryItemUnitSalesPrice', inventoryItem?.unitSalesPrice);
    }

    const generateProductName = (force: boolean = true) => {

        if (!force && watch('name')?.length > 0) {
            return;
        }

        const inventoryItem = inventoryItems?.items.find(x => x.id === watch('inventoryItemId'));
        const design = designs?.items.find(x => x.id === watch('designId'));

        const indexOfBracket = design?.name.indexOf("[") ?? 0;

        const name = `${design?.name.substring(0, indexOfBracket > 0 ? indexOfBracket : design.name.length).trim() ?? ''}, ${inventoryItem?.name ?? ''}`;

        setValue('name', name);
    }

    const shouldDisableFields = !!props.product?.id;

    const getTotalCostPrice = () => {
        let designCostPrice = watch('designUnitCostPrice');
        let inventoryCostPrice =  watch('inventoryItemUnitCostPrice');

        if (!!designCostPrice) {
            designCostPrice = parseFloat(designCostPrice.toString() ?? 0);
        }

        if (!!inventoryCostPrice) {
            inventoryCostPrice = parseFloat(inventoryCostPrice.toString() ?? 0);
        }

        return (designCostPrice ?? 0) + (inventoryCostPrice ?? 0);
    }

    const getTotalSalesPrice = () => {
        let designSalesPrice = watch('designUnitSalesPrice');
        let inventorySalesPrice =  watch('inventoryItemUnitSalesPrice');

        if (!!designSalesPrice) {
            designSalesPrice = parseFloat(designSalesPrice.toString() ?? 0);
        }

        if (!!inventorySalesPrice) {
            inventorySalesPrice = parseFloat(inventorySalesPrice.toString() ?? 0);
        }

        return (designSalesPrice ?? 0) + (inventorySalesPrice ?? 0);
    }

    return (
        <form onSubmit={handleSubmit(save)}>
            <Space direction={"vertical"} size={"large"}>

                <ControlledInput rules={{required: true}} name={"name"} control={control} placeholder={t('name')} label={(
                    <div>
                        <Label placeholder={"name"} /> - <span style={{fontSize: 12, cursor: 'pointer'}} onClick={() => generateProductName(true)}>{t('generateProductName')}</span>
                    </div>
                )} />

                {product?.productType === 'Service' && (
                    <ControlledInput rules={{required: true}} name={"sku"} control={control} placeholder={t('sku')} />
                )}

                {product?.productType === 'Product' && (
                    <>
                        <ControlledSelect disabled={shouldDisableFields} rules={{required: true}} onFilter={setProjectFilter} control={control} placeholder={t('project')} name={"projectId"}>
                            {filteredProjects.map(project => <Select.Option key={project.id} value={project.id} label={project.name} />)}
                        </ControlledSelect>

                        <ControlledSelect disabled={!!props.product?.id && !!props.product?.inventoryItemId} rules={{required: true}} onFilter={setInventoryFilter} control={control} placeholder={t('inventoryItem')} name={"inventoryItemId"} onChange={() => setInventoryUnitPrices(false)} label={(
                            <div>
                                <Label placeholder={"product"} /> - <span style={{fontSize: 12, cursor: 'pointer'}} onClick={() => setInventoryUnitPrices(true)}>{t('updatePriceFromInventory')}</span>
                            </div>
                        )}>
                            {filteredInventoryItems?.map(inventoryItem => <Select.Option key={inventoryItem.id} value={inventoryItem.id} label={inventoryItem.name} />)}
                        </ControlledSelect>

                        <ControlledSelect disabled={!!props.product?.id && !!props.product?.designId} onFilter={setDesignFilter} control={control} placeholder={t('design')} name={"designId"} onChange={onDesignChange} label={(
                            <div>
                                <Label placeholder={"design"} /> - <span style={{fontSize: 12, cursor: 'pointer'}} onClick={() => setDesignUnitPrices(true)}>{t('updatePriceFromDesign')}</span>
                            </div>
                        )} >
                            {filteredDesigns?.map(design => <Select.Option key={design.id} value={design.id} label={design.name} />)}
                        </ControlledSelect>

                        <ControlledInput name={"designUnitCostPrice"} number defaultValue={undefined} control={control} placeholder={t('designUnitCostPrice')} />
                        <ControlledInput name={"designUnitSalesPrice"} number defaultValue={undefined} control={control} placeholder={t('designUnitSalesPrice')} />
                    </>
                )}

                {product?.productType !== 'Discount' && (
                    <>
                    <ControlledInput name={"inventoryItemUnitCostPrice"} number defaultValue={undefined} control={control} placeholder={t('inventoryItemUnitCostPrice')}/>
                    <ControlledInput name={"inventoryItemUnitSalesPrice"} number defaultValue={undefined} control={control} placeholder={t('inventoryItemUnitSalesPrice')}/>

                        <div>
                            <Label placeholder={t('priceComments')}/>
                            <ControlledTextArea name={"priceComments"} control={control} rows={4} />
                        </div>

                        <ControlledInput name={"beatdownSKU"} number defaultValue={undefined} control={control} placeholder={t('beatdownSKU')}/>
                    </>
                )}

                <Input value={getTotalCostPrice()} name={"totalCostPrice"} disabled placeholder={t('totalCostPrice')} onChange={() => {}}/>

                <Input value={getTotalSalesPrice()} name={"totalSalesPrice"} disabled placeholder={t('totalSalesPrice')} onChange={() => {}}/>

                <div className={styles.imageRow}>
                    <FileUpload callback={addProductImage} accept={['.jpeg', '.jpg', '.png']} render={(loading, isDragging) => (
                        <div className={`${styles.mockupFileContainer} ${isDragging && styles.dragging}`}>
                            {loading ? (<Loader color={"red"} height={50} width={50} />) : (<>
                                <>
                                    {t('upload')}
                                </>
                            </>)}
                        </div>
                    )} />

                    {productImages?.map((image, index) => (
                        <div className={styles.relative} key={image.fileGuid}>
                            <div className={styles.delete} onClick={() => removeProductImage(index)}>
                                <XIcon />
                            </div>
                            <img height={150} width={150} src={image.url} alt={image.fileName} />
                        </div>
                    ))}
                </div>


                    <FlexRow justify={"end"}>
                        <Button buttonType={"submit"} type={"primary"} text={t('save')} loading={isLoading}/>
                    </FlexRow>
                    </Space>
                    </form>
                    )
                }

                export default ProductForm