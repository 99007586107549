import List from "Components/List/List";
import { useTranslation } from "react-i18next";
import {IInvoiceDto} from "../../../Apis/Models/IInvoiceDto";
import FlexRow from "../../../Components/FlexRow";
import InvoiceStatusBullet from "./InvoiceStatusBullet";
import React from "react";
import ExternalLinkIcon from "../../../Assets/SVG/ExternalLinkIcon";
import {downloadInvoice} from "../../../Apis/InvoicesApi";
import DownloadIcon from "../../../Assets/SVG/DownloadIcon";
import {useInvoiceStatus} from "../../Orders/Hooks/UseInvoiceStatus";
import { Link } from "react-router-dom";

interface IProps {
    invoices?: IInvoiceDto[];
    isLoading: boolean;
}
const BiProductOrderLineList = (props: IProps) => {

    const { invoices, isLoading } = props;
    
    const { t } = useTranslation();

    const { invoiceStatuses } = useInvoiceStatus();
    
    const getFunctionsContent = (record: IInvoiceDto) => {
        if (record.invoiceStatus === 'Draft') {
            return (
                <FlexRow 
                    style={{gap: 10}}
                    className={"h-100 clickable"}
                    justify={"center"} 
                    onClick={() => window.open(record.economicUrl, '_blank')}
                >
                    <ExternalLinkIcon />
                    {t('openInEconomic')}
                </FlexRow>
            )
        } else {
            return (
                <FlexRow
                    style={{gap: 10}}
                    className={"h-100 clickable"}
                    justify={"center"} 
                    onClick={() => downloadInvoice(record)}
                >
                    <DownloadIcon width={14} height={14} />
                    {t('downloadInvoice')}
                </FlexRow>
            )
        }
    }
    
    return (
        <>
            <List<IInvoiceDto> data={invoices} loading={isLoading} columns={[
                {
                    title: t('status'),
                    customRender: (record) => <FlexRow><InvoiceStatusBullet invoiceStatus={record.invoiceStatus} />{invoiceStatuses.find(x => x.value === record.invoiceStatus)?.label}</FlexRow>,
                    size: "min-content"
                },
                {
                    title: t('invoiceNumber'),
                    size: 'min-content',
                    textRender: (record) => `${record.invoiceStatus === 'Draft' ? record.draftInvoiceNumber : record.bookedInvoiceNumber}`,
                },
                {
                    title: t('reference'),
                    textRender: (record) => record.invoiceReference,
                },
                {
                    title: t('customer'),
                    textRender: (record) => record.orders[0].customer.name,
                },
                {
                    title: t('deliveryAddress'),
                    textRender: (record) => `${record.orders[0].deliveryAddress.street ?? ''}, ${record.orders[0].deliveryAddress.zip ?? ''} ${record.orders[0].deliveryAddress.city ?? ''}`,
                },
                {
                    title: t('orders'),
                    size: 'auto',
                    customRender: (record) => <div style={{display: 'flex', gap: 5}}>
                        {record.orders.map((x, index) => (
                            <div key={x.id}>
                                <Link style={{all: 'unset', cursor: 'pointer'}} to={`/orders/${x.id}`}>{x.orderNumber}{(index + 1) < record.orders.length ? ', ' : ''}</Link>
                            </div>
                        ))}
                    </div>,
                },
                {
                    title: t('functions'),
                    customRender: (record) => getFunctionsContent(record)
                }
            ]} keyExtractor={(record) => record.id.toString()} />
        </>
    )

}

export default BiProductOrderLineList