import {IMutateOrderDto, IOrderDto, OrderStatus} from "Apis/Models/IOrderDto";
import ControlledSelect from "../../../Components/Select/ControlledSelect";
import Select from "Components/Select";
import {useTranslation} from "react-i18next";
import {Controller, FormProvider, useFieldArray, useForm} from "react-hook-form";
import {useEffect, useState} from "react";
import {useProjects} from "../../../Apis/ProjectApi";
import Space from "Components/Space";
import ControlledTextArea from "../../../Components/TextArea";
import Label from "Components/Label/Label";
import ControlledInput from "Components/Input";
import styles from './OrderForm.module.scss'
import Separator from "../../../Components/Separator";
import {useMutateOrder} from "../../../Apis/OrderApi";
import Button from "../../../Components/Button";
import FlexRow from "../../../Components/FlexRow";
import { useProducts } from "../../../Apis/ProductApi";
import {usePartners} from "../../../Apis/PartnerApi";
import {useNavigate} from "react-router-dom";
import DatePicker from "../../../Components/DatePicker";
import moment from "moment";
import {useCustomers} from "../../../Apis/CustomerApi";
import {ICustomerDto} from "../../../Apis/Models/ICustomerDto";
import useFilteredList from "../../../Hooks/UseFilteredList";
import {IProjectDto} from "../../../Apis/Models/IProjectDto";
import OrderFormLine from "./OrderFormLine";
import { useTags } from "Apis/TagApi";

interface IProps {
    order?: IOrderDto;
}
const OrderForm = (props: IProps) => {

    const { order } = props;

    const { t } = useTranslation();

    const navigate = useNavigate();

    const formMethods = useForm<IMutateOrderDto>();

    const { fields, append, remove } = useFieldArray({
        control: formMethods.control,
        name: "orderLines",
        keyName: 'fieldId'
    });
    const [isUsingCustomerAddress, setIsUsingCustomerAddress] = useState<boolean>(false)

    const projectId = formMethods.watch('projectId');
    const customerId = formMethods.watch('customerId');

    const { data: projects } = useProjects({includeAll: true});
    const { data: tags } = useTags({includeAll: true});
    const { data: customers } = useCustomers({includeAll: true, barred: false});
    const { data: products } = useProducts({includeAll: true, readyForUse: true, projectId: projectId}, !!projectId);
    const { data: partners } = usePartners({includeAll: true});

    const { mutateAsync: mutateOrderMutation, isLoading } = useMutateOrder();

    const allProducts = [...products?.items ?? []]
    const [ filteredTags, setTagFilter ] = useFilteredList(tags?.items ?? [], x => [x.name]);
    const [ filteredProjects, setProjectFilter ] = useFilteredList(projects?.items ?? [], x => [x.name]);
    const [ filteredCustomers, setCustomerFilter ] = useFilteredList(customers?.items ?? [], x => [x.name, x.vatNumber]);

    useEffect(() => {
        if (order) {
            formMethods.reset({...order, tags: order.tags.map(x => x.id) ?? []});
        } else {
            append({quantity: 1});
        }
    }, [order, append, formMethods])

    const save = async (data: IMutateOrderDto) => {
        const response = await mutateOrderMutation(data);

        if (order?.id) {
            navigate(-1);
        } else {
            navigate(`/orders/${response.id}`);
        }
    }

    const getProject = (projectId?: number) => {
        if (projectId) {
            return projects!.items.find(x => x.id === projectId);
        }
    }

    const onProjectChanged = (projectId: number) => {
        if (projectId) {
            const project = getProject(projectId);
            const customer = customers!.items.find(x => x.id === project!.customerId);
            formMethods.setValue('customerId', customer!.id);
            autofillAddress(customer!);
        }
    }

    const onCustomerChanged = (customerId: number) => {
        const customer = customers!.items.find(x => x.id === customerId);
        autofillAddress(customer!);
    }

    const autofillAddress = (entity?: ICustomerDto | IProjectDto) => {
        if (entity) {
            formMethods.setValue('deliveryAddress.name', entity.name);
            formMethods.setValue('deliveryAddress.city', entity.city);
            formMethods.setValue('deliveryAddress.street', entity.address);
            formMethods.setValue('deliveryAddress.email', entity.email);
            formMethods.setValue('deliveryAddress.phone', entity.phone);
            formMethods.setValue('deliveryAddress.zip', entity.zip);

            if (!!Object.keys(entity!).find(x => x === 'customerId')) {
                setIsUsingCustomerAddress(false)
            } else {
                setIsUsingCustomerAddress(true)
            }
        }
    }

    const flipDeliveryAddress = () => {
        if (isUsingCustomerAddress) {
            autofillAddress(getProject(projectId))
        } else {
            autofillAddress(customers!.items.find(x => x.id === customerId))
        }
    }

    const shouldDisableFields = !!props.order && props.order?.currentOrderStatus !== OrderStatus.Received && props.order?.currentOrderStatus !== OrderStatus.Accepted;

    return (
        <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(save)}>
                <Space direction={"vertical"} size={"large"}>

                    <ControlledSelect disabled={shouldDisableFields} control={formMethods.control} placeholder={t('project')} name={"projectId"} onChange={onProjectChanged} onFilter={setProjectFilter}>
                        {filteredProjects?.map(x => <Select.Option key={x.id} label={x.name} value={x.id} />)}
                    </ControlledSelect>

                    <ControlledSelect disabled={shouldDisableFields} rules={{required: true}} control={formMethods.control} placeholder={t('customer')} name={"customerId"} onChange={onCustomerChanged} onFilter={setCustomerFilter}>
                        {filteredCustomers?.map(x => <Select.Option key={x.id} label={x.name} value={x.id} />)}
                    </ControlledSelect>

                    <ControlledInput name={"orderTitle"} control={formMethods.control} placeholder={t('orderTitle')} />

                    <div>
                        <Label placeholder={t('orderComments')} />
                        <ControlledTextArea rows={4} control={formMethods.control} name={"comments"} defaultValue={order?.comments} />
                    </div>

                    <Controller control={formMethods.control} name={"deadline"} defaultValue={moment().format('YYYY-MM-DD')} render={({ field }) => (
                        <DatePicker name={field.name} onChange={field.onChange} value={field.value} allowClear={false} placeholder={t('deadline') ?? ''} />
                    )} />

                    <Separator />

                    <div>
                        <Label placeholder={t('deliveryAddress')} /> - <span style={{fontSize: 12, cursor: 'pointer'}} onClick={() => flipDeliveryAddress()}>{isUsingCustomerAddress ? t('useProjectAddress') : t('useCustomerAddress')}</span>
                    </div>
                    <div className={styles.threeColumns}>
                        <ControlledInput name={"deliveryAddress.name"} control={formMethods.control} placeholder={t('name')} />
                        <ControlledInput name={"deliveryAddress.att"} control={formMethods.control} placeholder={t('att')} />
                        <div />
                        <ControlledInput name={"deliveryAddress.email"} control={formMethods.control} placeholder={t('email')} />
                        <ControlledInput name={"deliveryAddress.phone"} control={formMethods.control} placeholder={t('phone')} />
                        <div />
                        <ControlledInput name={"deliveryAddress.street"} control={formMethods.control} placeholder={t('street')} />
                        <ControlledInput name={"deliveryAddress.zip"} control={formMethods.control} placeholder={t('zip')} />
                        <ControlledInput name={"deliveryAddress.city"} control={formMethods.control} placeholder={t('city')} />
                    </div>

                    <ControlledSelect multiple control={formMethods.control} placeholder={t('tags')} name={"tags"} onFilter={setTagFilter}>
                        {filteredTags?.map(x => <Select.Option key={x.id} label={x.name} value={x.id} />)}
                    </ControlledSelect>

                    <Separator />

                    <Label placeholder={t('orderLines')} />

                    {fields.map((field, index) => (
                        <OrderFormLine key={field.fieldId} field={field} remove={remove} products={allProducts} partners={partners?.items ?? []} index={index} shouldDisableFields={shouldDisableFields} />
                    ))}

                    <FlexRow justify={"end"}>
                        <Button buttonType={"button"} type={"tertiary"} text={t('addProduct')} onClick={() => append({quantity: 1})} />
                    </FlexRow>

                    <FlexRow justify={"end"}>
                        <Button buttonType={"submit"} type={"primary"} text={t('save')} loading={isLoading} />
                    </FlexRow>
                </Space>
            </form>
        </FormProvider>
    )
}

export default OrderForm;