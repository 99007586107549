import { Navigate, Route, Routes } from "react-router-dom";
import Index from "./Index";
import PrintSizeIndex from "./Size/Index";
import PrintSizeNew from "./Size/New";
import PrintSizeEdit from "./Size/Edit";
import PrintPlacementIndex from "./Placement/Index";
import PrintPlacementNew from "./Placement/New";
import PrintPlacementEdit from "./Placement/Edit";
import PrintingTypeIndex from "./PrintingType/Index";
import PrintingTypeNew from "./PrintingType/New";
import PrintingTypeEdit from "./PrintingType/Edit";
import TransferTypeIndex from "./TransferType/Index";
import TransferTypeNew from "./TransferType/New";
import TransferTypeEdit from "./TransferType/Edit";
import FoilTypeIndex from "./FoilType/Index";
import FoilTypeNew from "./FoilType/New";
import FoilTypeEdit from "./FoilType/Edit";
import Edit from "./Edit";
import New from "./New";
import Clone from "./Clone";

const PrintRoutes = () => {
    
    return (
        <Routes>
            <Route path={`printingType`} element={<PrintingTypeIndex />}/>
            <Route path={`printingType/new`} element={<PrintingTypeNew />}/>
            <Route path={`printingType/edit/:id`} element={<PrintingTypeEdit />}/>

            <Route path={`transferType`} element={<TransferTypeIndex />}/>
            <Route path={`transferType/new`} element={<TransferTypeNew />}/>
            <Route path={`transferType/edit/:id`} element={<TransferTypeEdit />}/>

            <Route path={`foilType`} element={<FoilTypeIndex />}/>
            <Route path={`foilType/new`} element={<FoilTypeNew />}/>
            <Route path={`foilType/edit/:id`} element={<FoilTypeEdit />}/>

            <Route path={`placement`} element={<PrintPlacementIndex />}/>
            <Route path={`placement/new`} element={<PrintPlacementNew />}/>
            <Route path={`placement/edit/:id`} element={<PrintPlacementEdit />}/>

            <Route path={`size`} element={<PrintSizeIndex />}/>
            <Route path={`size/new`} element={<PrintSizeNew />}/>
            <Route path={`size/edit/:id`} element={<PrintSizeEdit />}/>

            <Route path={`/`} element={<Index />}/>
            <Route path={`clone/:id`} element={<Clone />}/>
            <Route path={`/edit/:id`} element={<Edit />}/>
            <Route path={`/new`} element={<New />}/>

            <Route path={"*"} element={<Navigate to={"/prints"}/>}/>
        </Routes>
    )
}

export default PrintRoutes
