import {useTranslation} from "react-i18next";
import { useDesigns} from "../../Apis/DesignApi";
import useQueryParams from "../../Hooks/UseQueryParams";
import {useNavigate} from "react-router-dom";
import OrdersIcon from "../../Assets/SVG/OrdersIcon";
import Button from "../../Components/Button";
import Pagination from "../../Components/Pagination";
import PageLayout from "../../Layouts/PageLayout";
import DesignList from "../../Features/Designs/Components/DesignList";
import PrintIcon from "../../Assets/SVG/PrintIcon";
import DesignListFilter from "../../Features/Designs/Components/DesignListFilter";
import Separator from "../../Components/Separator";

const Index = () => {

    const { t } = useTranslation();

    const [ filter, setFilter ] = useQueryParams();

    const { data: designs, isLoading } = useDesigns(filter);

    const navigate = useNavigate();

    return (
        <PageLayout title={t('designs')} icon={<PrintIcon />} header={(
            <Button prefix={<OrdersIcon fill={"#FFF"} />} text={t('createNewDesign')} type={"primary"} onClick={() => navigate("new")} />
        )}>
            <>
                <DesignListFilter filterUpdated={setFilter} currentValue={filter} />

                <Separator style={{margin: '30px 0'}} />

                <DesignList designs={designs?.items} isLoading={isLoading} />

                <Pagination paginatedList={designs} onPageChange={newPage => setFilter({page: newPage})} />
            </>
        </PageLayout>
    )

}

export default Index