import {SVGProps} from "react";

const DownloadIcon = (props: SVGProps<SVGSVGElement>) => {

    return (
        <svg data-name="Group 1509" xmlns="http://www.w3.org/2000/svg" width={props.width ?? "21.838"} height={props.height ?? "20.158"} viewBox="0 0 21.838 20.158">
            <path data-name="Path 1394" d="M21.471,311a1.215,1.215,0,0,0-.892-.367h-6.09L12.7,312.418a2.535,2.535,0,0,1-3.57,0l-1.772-1.785H1.26A1.216,1.216,0,0,0,.367,311a1.215,1.215,0,0,0-.367.893v4.2a1.215,1.215,0,0,0,.367.892,1.215,1.215,0,0,0,.892.367H20.579a1.254,1.254,0,0,0,1.26-1.26v-4.2A1.214,1.214,0,0,0,21.471,311Zm-4.921,4.423a.824.824,0,1,1,.249-.59A.808.808,0,0,1,16.549,315.423Zm3.36,0a.824.824,0,1,1,.249-.59A.807.807,0,0,1,19.909,315.423Z" transform="translate(0 -297.194)" fill={props.fill ?? "#6d4d4d"} />
            <path data-name="Path 1395" d="M97.209,32.3a.824.824,0,0,0,1.181,0l5.88-5.88a.758.758,0,0,0,.184-.919.785.785,0,0,0-.774-.512h-3.36V19.11a.851.851,0,0,0-.84-.84H96.12a.852.852,0,0,0-.84.84v5.88H91.92a.785.785,0,0,0-.774.512.758.758,0,0,0,.184.919Z" transform="translate(-86.881 -18.27)" fill={props.fill ?? "#6d4d4d"} />
        </svg>

    )

}

export default DownloadIcon