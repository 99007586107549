import styles from './Button.module.scss';
import { ReactNode } from "react";
import Loader from "Assets/SVG/Loader";

interface IProps {
    text: string;
    prefix?: ReactNode;
    type: 'primary' | 'secondary' | 'tertiary';
    buttonType: 'button' | 'submit';
    loading?: boolean;
    onClick?: () => any;
}
const Button = (props: IProps) => {
    
    const { text, prefix, buttonType, type, loading, onClick } = props;
    
    const getStyles = () => {
        switch (type) {
            case 'primary':
                return styles.primary;
            case 'secondary':
                return styles.secondary;
            case 'tertiary':
                return styles.tertiary;
        }
    }
    
    const getLoaderColor = () => {
        switch (type) {
            case 'primary':
                return "#FFF";
            case 'secondary':
            case 'tertiary':
                return "#E91D25";
        }
    }
    
    return (
        <button type={buttonType} disabled={loading} className={`${styles.button} ${getStyles()}`} onClick={_ => onClick && onClick()}>
            <span className={"h-100 flex align-center"} style={{paddingRight: loading || prefix ? 10 : 0}}>
                {loading ? (<Loader fill={getLoaderColor()} />) : prefix}
            </span>
            <span>
                {text}
            </span>
        </button>
    )
}

Button.defaultProps = {
    type: 'secondary',
    buttonType: 'button'
}

export default Button
