import { useTranslation } from "react-i18next";
import List from "Components/List/List";
import { IInventoryItemDto } from "Apis/Models/IInventoryItemDto";
import {formatNumber} from "../../../Utils/NumberUtils";

interface IProps {
    inventoryItems?: IInventoryItemDto[];
    isLoading: boolean;
}
const InventoryItemsList = (props: IProps) => {
    
    const { t } = useTranslation();
    
    const { inventoryItems, isLoading } = props;
    
    return (
        <List<IInventoryItemDto> data={inventoryItems} loading={isLoading} columns={[
            {
                title: t('SKU'),
                textRender: (record) => record.sku
            },
            {
                title: t('name'),
                textRender: (record) => record.name
            },
            {
                title: t('unitPurchasePrice'),
                textRender: (record) => `${formatNumber(record.unitCostPrice)} DKK`
            },
        ]} keyExtractor={(record) => record.id.toString()} />
    )
}

export default InventoryItemsList
