import {useEffect, useState} from "react";
import {IQueryParams} from "../Apis/Models/IQueryParams";
import {useLocation, useNavigate} from "react-router-dom";
import {isNumber} from "../Utils/NumberUtils";

const standardDefaultParams: IQueryParams = {
    page: 0,
}

const useQueryParams = (defaultParams: any = null): [IQueryParams, (params: IQueryParams, resetParams?: boolean ) => void] => {

    const navigate = useNavigate();
    const location = useLocation();

    const [ internalFilter, setInternalFilter ] = useState<IQueryParams>({...standardDefaultParams, ...defaultParams});


    useEffect(() => {
        const currentParams = new URLSearchParams(location.search);

        const newParams: IQueryParams = {};

        currentParams.forEach((value, key) => {

            if (currentParams.getAll(key).length > 1) {
                let values = currentParams.getAll(key)

                newParams[key] = values.map(value => isNumber(value) ? parseInt(value) : value);
            } else {
                newParams[key] = isNumber(value) ? parseInt(value) : value;
            }
        });

        setInternalFilter(newParams)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setFilter = (newValues: IQueryParams, resetParams: boolean = false) => {

        if (resetParams) {
            newValues = {...newValues, ...{...standardDefaultParams, ...defaultParams}}
        }

        setInternalFilter(current => { return {...current, ...newValues}})

        const params = new URLSearchParams();

        Object.keys(newValues).forEach(key => {
            if (Array.isArray((newValues[key]))) {
                let valueArray = newValues[key] as any[]
                valueArray.forEach((value: any) => {
                    params.append(key, value!.toString());
                })
            } else {
                if (newValues[key] !== undefined && newValues[key] !== null) {
                    params.append(key, newValues[key]!.toString());
                }
            }

        });

        navigate({
            pathname: location.pathname,
            search: `?${params.toString()}`,
        });
    }

    return [ internalFilter, setFilter ]

}

export default useQueryParams