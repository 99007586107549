import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import { IPaginatedList } from "Apis/Models/IPaginatedList";
import {IFoilTypeDto} from "Apis/Models/IPrintDto";
import {IQueryParams} from "./Models/IQueryParams";

export const FOIL_TYPE_CACHE_KEY = 'FOIL_TYPE_CACHE_KEY '
export const useFoilTypes = (queryable: Partial<IQueryParams> | null = null) => {
    return useQuery<IPaginatedList<IFoilTypeDto>>([FOIL_TYPE_CACHE_KEY, queryable], async () => {
        return await request<IPaginatedList<IFoilTypeDto>>({
            url: `/prints/foilType`,
            method: 'GET',
            queryParams: queryable
        })
    });
}

export const useFoilType = (id: number) => {
    return useQuery<IFoilTypeDto>([FOIL_TYPE_CACHE_KEY, id], async () => {
        return await request<IFoilTypeDto>({
            url: `/prints/foilType/${id}`,
            method: 'GET',
        })
    });
}

export const useMutateFoilType = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (foilType: IFoilTypeDto): Promise<IFoilTypeDto> => {
        if (!foilType.id) {
            return await request<IFoilTypeDto>({
                url: `/prints/foilType`,
                method: 'POST',
                data: foilType
            })
        } else {
            return await request<IFoilTypeDto>({
                url: `/prints/foilType/${foilType.id}`,
                method: 'PUT',
                data: foilType
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(FOIL_TYPE_CACHE_KEY)
        }
    })
}
