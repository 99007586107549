import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { auth } from "FirebaseSetup/auth";
import PageLoader from "Components/PageLoader";
import { NewPasswordForm } from "Features/Authentication";
import Box from "Components/Box";
import FlexRow from "Components/FlexRow";


const AuthActions = () => {
    
    let query = new URLSearchParams(useLocation().search);
    let mode = query.get('mode');
    let oobCode = query.get('oobCode') ?? '';
    
    const [codeValidated, setCodeValidated] = useState<boolean>(false);
    
    
    useEffect(() => {
        if (mode === 'resetPassword' && !codeValidated) {
            auth.verifyPasswordResetCode(oobCode).then(() => {
                setCodeValidated(true);
            })
        }
    }, [mode, oobCode, codeValidated]);
    
    if (!codeValidated) {
        return <PageLoader shown={true}><div></div></PageLoader>
    }
    
    return (
        <FlexRow className={"h-100"} justify={"center"} align={"center"}>
            <div style={{width: 400}}>
                <Box>
                    <img alt={""} style={{paddingBottom: 20}} src={"/images/AnotherSystemLogo.svg"} />
                    <NewPasswordForm oobCode={oobCode} />
                </Box>
            </div>
        </FlexRow>
    )
}

export default AuthActions
