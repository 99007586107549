import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {useProject, useDeleteProject} from "Apis/ProjectApi";
import { ProjectForm } from "Features/Projects";
import GuitarIcon from "Assets/SVG/GuitarIcon";
import Button from "../../Components/Button";
import XIcon from "../../Assets/SVG/XIcon";

const Edit = () => {
    
    const { t } = useTranslation();
    
    const { id } = useParams();

    const navigate = useNavigate();

    const { data: project } = useProject(parseInt(id!));
    const { mutateAsync: deleteProjectMutation, isLoading: isDeletingProject } = useDeleteProject();

    const deleteProject = () => {
        deleteProjectMutation(project!);

        navigate(-1)
    }
    
    return (
        <PageLayout title={t('updateProject')} icon={<GuitarIcon />} includeBox header={(
            <Button type={"secondary"} loading={isDeletingProject} prefix={<XIcon fill={"#FFF"} />} text={t('deleteProject')} onClick={deleteProject} />
        )}>
            <ProjectForm project={project} onSubmit={_ => navigate(-1)} />
        </PageLayout>
    )
}

export default Edit;
