import { useForm } from "react-hook-form";
import ControlledInput from "Components/Input";
import { useTranslation } from "react-i18next";
import Button from "Components/Button";
import Space from "Components/Space";
import FlexRow from "Components/FlexRow";
import { useEffect } from "react";
import {IPrintingTypeDto, IPrintPlacementDto} from "Apis/Models/IPrintDto";
import {useMutatePrintingType} from "../../../Apis/PrintingTypeApi";

interface IProps {
    printingType?: IPrintingTypeDto;
    onSubmit?: (data: IPrintPlacementDto) => void;
}
const PrintingTypeForm = (props: IProps) => {
    
    const { t } = useTranslation();
    
    const { control, handleSubmit, reset } = useForm<IPrintingTypeDto>()
    const { mutateAsync, isLoading } = useMutatePrintingType();
    
    useEffect(() => {
        if (props.printingType) {
            reset(props.printingType);
        }
    }, [reset, props.printingType])
    
    const onSubmit = async(data: IPrintingTypeDto) => {
        await mutateAsync(data);
        
        if (props.onSubmit) {
            props.onSubmit(data);
        }
        
        reset();
    }
    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Space direction={"vertical"} size={"large"}>
                <ControlledInput rules={{required: true}} name={"name"} control={control} placeholder={t('name')} />
                
                <FlexRow justify={"end"}>
                    <Button buttonType={"submit"} type={"primary"} text={t('save')} loading={isLoading} />
                </FlexRow>
            </Space>
        </form>
    )
}

export default PrintingTypeForm
