import { useForm } from "react-hook-form";
import Input from "Components/Input";
import { useTranslation } from "react-i18next";
import Space from "Components/Space";
import Button from "Components/Button";
import FlexRow from "Components/FlexRow";
import { useAuthProvider } from "Providers/AuthProvider";

interface ILoginDetails {
    email: string;
    password: string;
}

const LoginForm = () => {
    
    const { t } = useTranslation();
    
    const { signIn } = useAuthProvider();
    
    const { control, handleSubmit } = useForm<ILoginDetails>()
    
    const login = async (data: ILoginDetails) => {
        await signIn(data);
    }
    
    return (
        <form onSubmit={handleSubmit(login)}>
            <Space direction={"vertical"} size={"large"}>
                <Input placeholder={t('email')} name={"email"} control={control} />
                <Input placeholder={t('password')} name={"password"} control={control} type={"password"} />
                <FlexRow justify={"end"}>
                    <Button buttonType={"submit"} text={t('auth:login')} type={"primary"} />
                </FlexRow>
            </Space>
        </form>
    )
}

export default LoginForm
