import styles from "./InvoiceStatusBullet.module.scss";
import {InvoiceStatus} from "../../../Apis/Models/IInvoiceDto";
interface IProps {
    invoiceStatus: InvoiceStatus;
}

const InvoiceStatusBullet = (props: IProps) => {

    const {invoiceStatus} = props;

    const getBackgroundColor = () => {
        switch (invoiceStatus) {
            case "Draft":
                return styles.red;
            case "Booked":
                return styles.yellow;
            case "Paid":
                return styles.green;
        }
    }

    return (
        <div className={`${styles.bullet} ${getBackgroundColor()}`}>

        </div>
    )

}

export default InvoiceStatusBullet