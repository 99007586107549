import { IFileInformationDto } from "Apis/Models/IFileDto";
import { useBase64File, useUploadFile } from "Apis/FileApi";
import React, {useRef, useState} from "react";

type Accept = '.doc' | '.pdf' | '.png' | '.jpg' | '.jpeg' | '.svg' | '.gcr';

interface IProps {
    callback: (file: IFileInformationDto) => void;
    multiple?: boolean;
    accept?: Accept[];
    render: (loading: boolean, isDragging: boolean) => React.ReactElement
}
const FileUpload = (props: IProps) => {

    const fileInputRef = useRef<HTMLInputElement>(null);

    const { callback, multiple, accept, render } = props;

    const convert = useBase64File();

    const { mutateAsync: uploadFileMutation, isLoading } = useUploadFile();
    
    const [isDragging, setDragging] = useState<boolean>(false);

    const uploadFile = async (e: any) => {
        e.preventDefault();
        
        for (let i = 0; i < e.target.files.length; i++) {
            upload(e.target.files[i]);
        }

        setDragging(false);
    }
    
    const onDrop = async (e: any) => {
        e.preventDefault();
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
            upload(e.dataTransfer.files[i]);
        }
        
        setDragging(false);
    }
    
    const upload = async (file: any) => {
        const base64 = await convert(file);

        let uploadedFile = await uploadFileMutation({
            fileContent: base64,
            contentType:file.type,
            fileName: file.name
        });

        callback(uploadedFile);
    }

    const getAccept = (): string => {
        if (accept) {
            return accept.join(',');
        } else {
            return ['.png', '.jpg', '.jpeg', '.svg', ".pdf", ".doc"].join(',');
        }
    }
    
    const setIsDragging = (e: any, newState: boolean) => {
        e.preventDefault();
        e.stopPropagation();
        setDragging(newState)
    }

    return (
        <div style={{cursor: 'pointer'}} onClick={() => fileInputRef?.current!.click()} onDragOver={(e) => setIsDragging(e, true)} onDragLeave={(e) => setIsDragging(e,  false)} onDrop={onDrop}>
            {!!render && render(isLoading, isDragging)}
            <input ref={fileInputRef} accept={getAccept()} multiple={multiple} type={"file"} onChange={uploadFile} style={{display: 'none'}} />
        </div>
    )
}

export default FileUpload;
