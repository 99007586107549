import styles from './PageLoader.module.scss';
import Loader from "Assets/SVG/Loader";

interface Props {
    shown: boolean;
    children: any;
}
const PageLoader = ({shown, children}: Props) => {
    
    return (
        <>
            <div className={`${styles.pageLoader} ${!shown ? styles.hidden :''}`}>
                <Loader fill={"#E91D25"} height={100} width={100} />
            </div>
            <div className={`${styles.content} ${shown ? styles.hidden :''}`}>
                {!shown && (
                    <>
                        {children}
                    </>
                )}
            </div>
        </>
    )
}

export default PageLoader
