import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import Button from "Components/Button";
import { useNavigate } from "react-router-dom";
import { usePartners } from "Apis/PartnerApi";
import { PartnersList } from "Features/Partners";
import HandsIcon from "Assets/SVG/HandsIcon";
import Pagination from "../../Components/Pagination";
import useQueryParams from "../../Hooks/UseQueryParams";
import Separator from "../../Components/Separator";
import PartnerListFilter from "../../Features/Partners/Components/PartnerListFilter";

const Index = () => {
    
    const { t } = useTranslation();

    const [ filter, setFilter ] = useQueryParams();

    const { data: partners, isLoading } = usePartners(filter);
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={t('partners')} icon={<HandsIcon />} header={(
            <Button prefix={<HandsIcon fill={"#FFF"} />} text={t('createNewPartner')} type={"primary"} onClick={() => navigate("new")} />
        )}>
            <>
                <PartnerListFilter filterUpdated={setFilter} currentValue={filter} />

                <Separator style={{margin: '30px 0'}} />

                <PartnersList partners={partners?.items} isLoading={isLoading} />

                <Pagination paginatedList={partners} onPageChange={newPage => setFilter({page: newPage})} />
            </>
        </PageLayout>
    )
}

export default Index
