import * as React from "react"
import { SVGProps } from "react"

const DotsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={30} height={8} viewBox={"0 0 30 8"} {...props}>
        <g
            data-name="Group 1315"
            transform="rotate(-90 -753.396 4055.597)"
            fill="#6d4d4d"
        >
            <circle
                data-name="Ellipse 135"
                cx={4}
                cy={4}
                r={4}
                transform="translate(3294.2 4808.993)"
            />
            <circle
                data-name="Ellipse 136"
                cx={4}
                cy={4}
                r={4}
                transform="translate(3294.2 4819.993)"
            />
            <circle
                data-name="Ellipse 137"
                cx={4}
                cy={4}
                r={4}
                transform="translate(3294.2 4830.993)"
            />
        </g>
    </svg>
)

export default DotsIcon
