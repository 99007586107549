import OrdersIcon from "../../Assets/SVG/OrdersIcon";
import Space from "../../Components/Space";
import PageLayout from "../../Layouts/PageLayout";
import {useTranslation} from "react-i18next";
import OrderForm from "../../Features/Orders/Components/OrderForm";

const New = () => {

    const { t } = useTranslation();

    return (
        <PageLayout title={`${t('newOrder')}`} icon={<OrdersIcon />} includeBox>
            <Space size={"large"} direction={"vertical"}>
                <OrderForm />
            </Space>
        </PageLayout>
    )

}

export default New;