import { SVGProps } from "react";

const AnotherSolutionIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="181.369" height="38.687" viewBox="0 0 181.369 38.687" {...props}>
        <defs>
            <clipPath id="a">
                <path data-name="Rectangle 761" fill="none" d="M0 0h44.124v38.687H0z" />
            </clipPath>
            <clipPath id="b">
                <path
                    data-name="Rectangle 762"
                    fill="none"
                    d="M0 0h122.355v15.067H0z"
                />
            </clipPath>
            <clipPath id="c">
                <path
                    data-name="Rectangle 763"
                    fill="none"
                    d="M0 0h122.971v15.045H0z"
                />
            </clipPath>
        </defs>
        <g data-name="Group 1507">
            <g data-name="Group 1502">
                <g data-name="Group 1501" clipPath="url(#a)">
                    <path
                        data-name="Path 1376"
                        d="M41.308 21.295c-1.942-1.986-4.362-2.954-8.368-4.636-4.417-1.837-6.137-2.757-7.454-4.225a5.735 5.735 0 0 1-1.553-3.84c0-3.5 2.857-6 7.311-6 2.909 0 5.794.819 6.928 1.861 1.53 1.279 1.917 3.288 2.446 5.123.064.237.091.3.257.244a3.94 3.94 0 0 0 1.325-.495.592.592 0 0 0 .179-.66A34.554 34.554 0 0 1 41.86.129a2.632 2.632 0 0 0-1.24-.108c-.453.66-.964 1.157-1.33 1.15a7.611 7.611 0 0 1-1.48-.179 37.8 37.8 0 0 0-5.452-.448 22.261 22.261 0 0 0-8.18 1.841A8.413 8.413 0 0 0 21.3 4.728c-.4-1.181-.81-2.453-1.257-3.917C19.9.278 19.787 0 19.4 0h-.06a5.107 5.107 0 0 0-1.623.446.547.547 0 0 0-.325.454c-.3.925-.624 1.822-1.264 3.4C12.672 12.335 6.2 28.624 4.514 31.9 2.995 34.858 2.385 35.928.03 36.6a3.164 3.164 0 0 0 .252 1.607c.067.074.126.134.364.134s1.236-.06 2.2-.06c1.131 0 4.518 0 7.108.06a3.127 3.127 0 0 0 .469-1.538c-2.836-.91-3.3-1.348-3.3-2.613 0-1.471 2.156-6.829 2.7-7.958.462-.908.648-1.01 1.286-1.069 3.057-.114 6.878-.24 10.763-.274h.094a.438.438 0 0 0 .364-.555l-.006-.021-.123-.339-.377-1.041-.065-.179a.273.273 0 0 0-.255-.176h-.107c-1.206.034-2.6.059-4.567.059-2.828 0-4.05-.06-4.338-.06h-.06c-.721 0-.943-.462-.876-.655 2.112-5.9 2.925-7.755 5.788-14.251.224-.5.522-.6.6-.4 2.009 5.01 3.147 8.383 5.388 14.44v.008l2.068 5.657c.819 2.2 1.813 5.091 2.056 5.9.839 2.5.535 2.852-2.326 3.724a3.842 3.842 0 0 0 .273 1.5.369.369 0 0 0 .372.186c.491 0 1.837-.231 3.736-.231 1.965 0 3.1.007 7.575.067a3.155 3.155 0 0 0 .481-1.323 9.862 9.862 0 0 0 1.925-.783c2.839-1.509 4.634-4.588 4.634-8.635a8.1 8.1 0 0 0-2.816-6.488M34.582 35.8a6.256 6.256 0 0 1-2.118-3.174c-.548-1.315-3.83-8.63-6.092-14.157a80.87 80.87 0 0 0 3.681 1.632c4.561 1.88 5.985 2.54 7.115 3.484a6.317 6.317 0 0 1 2.559 4.915 7.488 7.488 0 0 1-5.145 7.3"
                        fill="#db0014"
                    />
                </g>
            </g>
            <g data-name="Group 1504">
                <g
                    data-name="Group 1503"
                    transform="translate(58.399)"
                    clipPath="url(#b)"
                    fill="#db0014"
                >
                    <path
                        data-name="Path 1377"
                        d="M8.012.617c.337 0 .483.04.582.335 1.442 3.846 2.881 7.653 4.6 11.257a2.222 2.222 0 0 0 2.114 1.477c.134.03.134.073.126.322-.068.641-.143.674-.372.674-1.407-.033-2.436-.063-3.123-.063-1.006 0-2.043.1-2.164.1a3.284 3.284 0 0 1-.324-.856c.868-.275 1.266-.484 1.266-.763a11.182 11.182 0 0 0-.8-2.367c-.137-.279-.294-.475-.622-.475-1.474-.01-2.818-.013-4.217.02a.936.936 0 0 0-.788.527 7.551 7.551 0 0 0-.606 2.025c0 .315.208.584 1.261.816.2.033.231.073.231.243a2.762 2.762 0 0 1-.2.72c-1.576-.033-2.834-.033-3.445-.033-.351 0-1.048.033-1.179.033-.108 0-.35-.441-.35-.727 0-.129.04-.236.075-.236 1.258-.308 1.522-.5 2.273-2.021 1.21-2.5 2.889-6.405 4.165-9.319a11.845 11.845 0 0 0 .459-1.239.306.306 0 0 1 .216-.232 2.039 2.039 0 0 1 .787-.219Zm-2.501 8.35c1.075 0 2.232 0 3.5-.03a.246.246 0 0 0 .189-.355c-.827-2.34-1.346-3.615-1.877-5.068a173.834 173.834 0 0 0-2.069 5.111.3.3 0 0 0 .222.345Z"
                    />
                    <path
                        data-name="Path 1378"
                        d="M23.786 14.469c0 .076-.078.183-.146.183-1.042 0-2.1-.073-3.121-.073-.727 0-1.524.07-1.995.07l-.221-.817c0-.04.038-.086.143-.119 1.193-.279 1.4-.528 1.5-1.278a35.687 35.687 0 0 0 .277-4.1V4.523a4.27 4.27 0 0 0-.246-1.988c-.217-.362-.4-.5-1.825-.674a.259.259 0 0 1-.224-.269v-.6c0-.106.039-.143.176-.106 1.188.073 1.649.106 3.523.106.321 0 .493.072.589.252a8.063 8.063 0 0 0 1.3 1.646c1.165 1.264 3.86 4.208 7.845 7.982.068-1.759.065-6.077-.013-7.819-.047-.826-.144-.89-1.754-1.068-.169 0-.234-.063-.234-.206l-.035-.786h2.559c.795 0 1.938-.106 2.823-.106.1 0 .173.03.176.1l.148.724c-1.768.614-1.976.657-2.1 3.037-.139 2.187-.142 4.142-.142 6.342 0 1.756.043 2.937.043 3.11.008.441-.533.677-.894.816-.169.063-.277 0-.4-.159a17.792 17.792 0 0 0-2.163-2.613c-1.414-1.427-5.368-5.7-7.369-7.683-.2-.176-.315-.09-.35.1-.035.548-.1 1.656-.108 3.116a28.746 28.746 0 0 0 .237 5.088c.212.637.991.77 1.893.856Z"
                    />
                    <path
                        data-name="Path 1379"
                        d="M45.256.64c3.939 0 7.377 3.053 7.377 6.853a7.428 7.428 0 0 1-2.581 5.632 7.323 7.323 0 0 1-4.484 1.8 8.372 8.372 0 0 1-6.2-2.536 6.96 6.96 0 0 1-1.779-4.72 6.521 6.521 0 0 1 2.309-5.058A7.752 7.752 0 0 1 45.221.64Zm-.6 1.1a3.948 3.948 0 0 0-2.877 1.362 6.429 6.429 0 0 0-1.33 4.225 6.684 6.684 0 0 0 1.736 4.653 5.4 5.4 0 0 0 3.737 1.779c1.837 0 3.953-1.928 3.953-5.443a6.7 6.7 0 0 0-1.766-5.051 4.608 4.608 0 0 0-3.418-1.523Z"
                    />
                    <path
                        data-name="Path 1380"
                        d="M58.091 13.756c1.73-.249 2-.475 2.077-.873a29.175 29.175 0 0 0 .243-4.45V6.366c0-1.653 0-2.821-.07-3.73 0-.183-.164-.256-.514-.256a14.62 14.62 0 0 0-3.916.262c-.237.073-.453.717-.717 1.656-.065.262-.207.325-.35.259-.558-.269-.8-.478-.733-.614a1.884 1.884 0 0 1 .149-.464 18.553 18.553 0 0 0 .9-2.9c.113-.458.156-.574.224-.574l.347.033c.286.037.408.07.44.172.061.538.143.737.31.8a42.257 42.257 0 0 0 5.285.086 36.182 36.182 0 0 0 5.59-.146c.315-.07.431-.541.587-.84a2.538 2.538 0 0 1 .776.136c.116.04.213.113.056.488a10.234 10.234 0 0 0-.528 3.545c0 .136-.126.206-.268.206a2.825 2.825 0 0 1-.8-.056c0-.133.021-1.281.021-1.55 0-.146-.137-.252-.318-.325a18.625 18.625 0 0 0-3.256-.186c-.579 0-.778.133-.778.3-.04.445-.048 1.543-.048 3.846V8.7a22.7 22.7 0 0 0 .143 3.916c.139.6.531.906 2.3 1.039.14.06.151.11.117.308l-.047.544c-.091.1-.1.14-.216.14-.184 0-2.24-.07-3.3-.07-1.416 0-3.214.073-3.387.073-.2 0-.269-.033-.3-.146Z"
                    />
                    <path
                        data-name="Path 1381"
                        d="M82.782 13.988c0-.256.033-.342.1-.342.566-.113 1.34-.222 1.5-.557a10.373 10.373 0 0 0 .181-2.592V8.645c0-.106-.038-.232-.475-.232h-5.014a14.954 14.954 0 0 0-2.848.079c-.358.1-.367.481-.367.8 0 .94 0 2.011.038 2.795.043 1.214.173 1.261 1.963 1.567.1.033.043.836.034.916s-.013.122-.086.122c-1.231 0-2.846-.11-3.8-.11-1.131.033-1.767.073-1.873.073a.264.264 0 0 1-.183-.04 2.348 2.348 0 0 1-.146-.919c1.366-.352 1.544-.508 1.582-.883a21.273 21.273 0 0 0 .142-3.107V5.874a24.871 24.871 0 0 0-.1-2.97c-.073-.677-.281-.863-1.606-1-.067 0-.169-.172-.048-.557.067-.315.1-.385.3-.385.916 0 1.593.033 2.328.033a39.02 39.02 0 0 0 2.985-.106 5.432 5.432 0 0 1 .148 1.026c-1.613.279-1.686.352-1.678 3.528 0 1.434.108 1.665.407 1.665 1.107.037 2.266.033 3.531.033 2.918 0 4.3-.037 4.494-.076.23-.03.268-.176.268-.869 0-.667.035-2.619-.038-3.249-.075-.717-.38-.846-1.868-.976a7.171 7.171 0 0 1 .1-.876.1.1 0 0 1 .116-.1h2.917c.6 0 2.227-.106 2.716-.14.1 0 .2.412.251.866-1.862.445-1.862.482-1.862 3.388v4.512a24.129 24.129 0 0 0 .113 2.9c.068.647.307.89 1.55 1.052.169.03.2.073.2.146a7.3 7.3 0 0 1-.207.856h-3.281c-1.676.033-2.081.07-2.358.07-.1 0-.133 0-.133-.07Z"
                    />
                    <path
                        data-name="Path 1382"
                        d="M94.057 4.958a13.061 13.061 0 0 0-.121-2.529c-.041-.143-.128-.209-1.711-.445l.017-.92c.035-.072.035-.106.134-.106h.918c.657 0 1.593.037 3.158.037 2.343 0 4.314-.106 6.352-.106.278 0 .447 0 .549.11.073.079.1.143.038.521a15.472 15.472 0 0 0-.146 2.662c0 .133-.1.136-.312.136-.647 0-.655-.129-.7-.289-.328-1.672-.489-1.9-3.133-1.9a11.9 11.9 0 0 0-2.306.133c-.278.033-.377.143-.377.478 0 1.368.03 2.748.03 4.046a.324.324 0 0 0 .277.309h1.633c.922 0 1.356 0 2.24-.04.774-.066.951-.206 1.183-1.271.033-.106.171-.14.763-.14.146 0 .207.04.207.117a14.533 14.533 0 0 0-.2 2.28 18.182 18.182 0 0 0 .1 1.885c0 .07-.1.11-.906.173-.07-.243-.224-.721-.3-.986-.1-.455-.242-.671-.914-.74-.87-.069-1.429-.069-2.588-.069a3.514 3.514 0 0 0-1.3.11c-.14.046-.234.179-.234 3.1a2.734 2.734 0 0 0 .2 1.4c.2.329.581.514 2.483.514 2.487 0 3.662-.229 4.293-2.237.035-.1.081-.143.184-.143.206 0 .557.063.657.066.068 0 .1.04.1.243a11.614 11.614 0 0 1-.582 3.143.419.419 0 0 1-.334.143c-.711 0-3.249-.07-5.275-.07-1.72 0-3.7.11-5.817.11a2.275 2.275 0 0 1-.065-.86c.03-.1.07-.11.146-.11 1.3-.262 1.465-.338 1.5-.77a33.029 33.029 0 0 0 .14-3.355Z"
                    />
                    <path
                        data-name="Path 1383"
                        d="M113.768 13.849c-.095.69-.138.73-.367.73-.332 0-2.234-.033-3.618-.033-.752 0-1.414.067-1.807.067-.193 0-.228-.067-.3-.468-.094-.385-.03-.448.009-.481 1.206-.183 1.526-.4 1.565-1.129.047-.786.113-1.875.113-3.71V6.276c0-1.168-.005-2.459-.067-3.233-.03-.375-.073-.74-.479-.783a9.9 9.9 0 0 1-1-.213c-.181-.037-.2-.106-.232-.269l-.061-.71c1.464-.06 2.461-.043 3.361-.136.765-.07 1.919-.149 2.7-.149a7.05 7.05 0 0 1 3.962 1 3.356 3.356 0 0 1 1.406 2.7 3.846 3.846 0 0 1-2.095 3.041c-.1.07-.207.216-.172.318a12.291 12.291 0 0 0 3.766 5.33 6.531 6.531 0 0 0 1.806 1.019c.09.04.093.113.093.468 0 .375-.065.409-.143.409a7.652 7.652 0 0 1-4.769-1.676 11.929 11.929 0 0 1-2.681-3.658c-.362-.648-.8-.923-1.976-.923-1.083 0-1.124.183-1.124.322 0 2.7.032 3.893.3 4.075a13.279 13.279 0 0 0 1.845.432Zm2.642-9.09a2.793 2.793 0 0 0-3.048-2.9 3.327 3.327 0 0 0-1.288.256.481.481 0 0 0-.25.332c-.135 1.526-.138 2.691-.138 4.965 0 .312.094.4.961.4a6.483 6.483 0 0 0 2.379-.368 2.823 2.823 0 0 0 1.384-2.642Z"
                    />
                </g>
            </g>
            <g data-name="Group 1506">
                <g
                    data-name="Group 1505"
                    transform="translate(58.399 23.642)"
                    clipPath="url(#c)"
                    fill="#db0014"
                >
                    <path
                        data-name="Path 1384"
                        d="M9.147 11.353a3.247 3.247 0 0 1-1.577 2.629 5.691 5.691 0 0 1-3.232.91 23.006 23.006 0 0 1-2.828-.315 1.479 1.479 0 0 0-1.15.046.468.468 0 0 1-.285-.069.366.366 0 0 1-.055-.355 16.9 16.9 0 0 0 .021-3.62.289.289 0 0 1 .131-.179 2.383 2.383 0 0 1 .747-.219 7.009 7.009 0 0 0 1.459 2.575 3.012 3.012 0 0 0 2.182 1.139 2.169 2.169 0 0 0 2.155-1.918c0-1.892-1.98-2.6-2.952-3.156C2.934 8.363.54 7.315.535 5.181a3.465 3.465 0 0 1 1.483-2.755 4.536 4.536 0 0 1 2.693-.992c.674 0 1.374.1 2.129.1a1.493 1.493 0 0 0 .569-.07.223.223 0 0 1 .242.037.211.211 0 0 1 .108.219 13.485 13.485 0 0 0 .6 3.3c0 .093-.208.275-.266.275-.511 0-.617-.02-.924-.468-.69-.9-1.414-2.346-3.053-2.346a1.567 1.567 0 0 0-1.457 1.73c0 1.587 1.327 2.283 2.756 3.017.823.411 3.732 1.473 3.732 4.089Z"
                    />
                    <path
                        data-name="Path 1385"
                        d="M19.337.641c3.939 0 7.377 3.053 7.377 6.853a7.427 7.427 0 0 1-2.581 5.632 7.322 7.322 0 0 1-4.484 1.8 8.37 8.37 0 0 1-6.2-2.536 6.957 6.957 0 0 1-1.782-4.72 6.52 6.52 0 0 1 2.308-5.058A7.752 7.752 0 0 1 19.298.641Zm-.6 1.1a3.949 3.949 0 0 0-2.872 1.361 6.429 6.429 0 0 0-1.339 4.226 6.684 6.684 0 0 0 1.736 4.653 5.4 5.4 0 0 0 3.737 1.779c1.837 0 3.953-1.928 3.953-5.443a6.7 6.7 0 0 0-1.766-5.051 4.6 4.6 0 0 0-3.418-1.523Z"
                    />
                    <path
                        data-name="Path 1386"
                        d="M31.899 5.748a25.93 25.93 0 0 0-.076-2.827c-.07-.607-.28-.906-2.089-.9-.1 0-.239-.14-.2-.249l.256-.776h2.515c.942 0 3.04-.113 3.437-.143.2 0 .273 0 .373.106a3.3 3.3 0 0 1 .108.826c-1.263.116-1.779.262-1.882 1.038-.075.707-.075 2.061-.075 3.508V9.59c0 3.265.1 3.547.5 3.693a6.752 6.752 0 0 0 1.827.173c1.877-.013 3.012-.222 3.764-2.43.066-.163.078-.176.151-.176a1.87 1.87 0 0 1 .679.209.218.218 0 0 1 .1.259c-.294 1.158-.531 2.048-.7 2.957a.4.4 0 0 1-.35.341 11.512 11.512 0 0 1-1.319.03c-.868 0-1.9-.07-5.617-.07-1.274 0-2.392.209-3.08.242-.1 0-.177 0-.216-.09-.069-.183-.239-.726-.169-.783 1.328-.332 1.737-.558 1.937-1.215a16.15 16.15 0 0 0 .129-2.827Z"
                    />
                    <path
                        data-name="Path 1387"
                        d="M50.235 14.954a6.811 6.811 0 0 1-4.431-1.391 4.57 4.57 0 0 1-1.361-3.773c0-1.258 0-2.675.064-4.431a14.326 14.326 0 0 0-.055-2.9c-.04-.362-.576-.441-1.362-.548-.043-.03-.046-.076-.068-.8 0-.123.133-.156.45-.156.624 0 1.913.033 2.381.033.9-.006 1.545-.1 3.317-.1.14 0 .178.043.143.362-.038.478-.129.561-.272.568-1.211.2-1.807.342-1.941 1.059a46.9 46.9 0 0 0-.229 6.734 3.41 3.41 0 0 0 1.322 2.91 4.711 4.711 0 0 0 2.9.794 3.785 3.785 0 0 0 3.031-1.514c.666-.879.873-2.556.873-4.52a25.342 25.342 0 0 0-.342-4.557c-.1-.494-.262-.594-1.908-.6-.078 0-.207-.136-.178-.146a2.045 2.045 0 0 1 .307-.8.394.394 0 0 1 .307-.152h2.559c.688 0 1.812-.03 2.469-.033.1 0 .143.159.135.282-.161.624-.2.76-.277.774a5.428 5.428 0 0 0-1.112.2c-.146.063-.251.183-.319.475a45.7 45.7 0 0 0-.277 4.776c-.069 2.4-.325 3.946-1.249 5.194a6.669 6.669 0 0 1-4.842 2.264Z"
                    />
                    <path
                        data-name="Path 1388"
                        d="M64.654 13.756c1.73-.249 2-.475 2.077-.873a29.175 29.175 0 0 0 .243-4.45V6.366c0-1.653 0-2.821-.07-3.73 0-.183-.164-.256-.514-.256a14.62 14.62 0 0 0-3.916.262c-.237.073-.452.717-.717 1.656-.065.262-.207.325-.35.259-.558-.269-.8-.478-.733-.614a1.883 1.883 0 0 1 .149-.464 18.551 18.551 0 0 0 .9-2.9c.113-.458.156-.574.224-.574l.347.033c.286.037.408.07.44.172.061.538.143.737.31.8a42.257 42.257 0 0 0 5.285.086 36.182 36.182 0 0 0 5.59-.146c.315-.07.431-.541.587-.84a2.538 2.538 0 0 1 .776.136c.116.04.213.113.055.488a10.282 10.282 0 0 0-.526 3.545c0 .136-.126.206-.268.206a2.826 2.826 0 0 1-.8-.056c0-.133.021-1.281.021-1.55 0-.146-.137-.252-.32-.325a18.6 18.6 0 0 0-3.254-.186c-.579 0-.778.133-.778.3-.04.445-.047 1.543-.047 3.846V8.7a22.813 22.813 0 0 0 .141 3.916c.139.6.531.906 2.3 1.039.14.06.151.11.117.308l-.047.544c-.091.1-.1.14-.215.14-.185 0-2.241-.07-3.3-.07-1.416 0-3.214.073-3.387.073-.2 0-.269-.033-.3-.146Z"
                    />
                    <path
                        data-name="Path 1389"
                        d="M79.152 13.478c.576 0 .939-.169 1.05-.664a25.33 25.33 0 0 0 .151-3.627V5.782c0-1.307 0-2.1-.043-2.685-.073-.892-.4-1.068-2.164-1.068A.157.157 0 0 1 78 1.886a2.314 2.314 0 0 1 .178-.723c.068-.169.119-.2.42-.2 2.234 0 2.756.033 3.184.033 1.181 0 2.719-.033 2.932-.033.192 0 .365.03.4.169l.052.783c-2.057.252-2.2.455-2.275.757a15.727 15.727 0 0 0-.172 3.292v3.033a23.37 23.37 0 0 0 .239 3.943c.073.365.214.438 1.828.607a4.389 4.389 0 0 1 .091.889c0 .143-.1.176-.333.176-1.668-.033-2.627-.063-3.468-.033-1.453 0-2.5.07-2.753.07-.138 0-.246-.1-.275-.329l-.07-.677c0-.043 0-.136.07-.136Z"
                    />
                    <path
                        data-name="Path 1390"
                        d="M95.588.641c3.941 0 7.379 3.053 7.379 6.853a7.427 7.427 0 0 1-2.581 5.632 7.323 7.323 0 0 1-4.484 1.8 8.37 8.37 0 0 1-6.2-2.536 6.957 6.957 0 0 1-1.781-4.719 6.52 6.52 0 0 1 2.308-5.058A7.752 7.752 0 0 1 95.552.642Zm-.6 1.1a3.949 3.949 0 0 0-2.872 1.361 6.429 6.429 0 0 0-1.334 4.225 6.684 6.684 0 0 0 1.736 4.653 5.4 5.4 0 0 0 3.737 1.779c1.837 0 3.953-1.928 3.953-5.443a6.7 6.7 0 0 0-1.766-5.051 4.6 4.6 0 0 0-3.418-1.523Z"
                    />
                    <path
                        data-name="Path 1391"
                        d="M111.726 14.47c0 .076-.078.183-.146.183-1.042 0-2.1-.073-3.121-.073-.727 0-1.523.07-1.995.07l-.221-.817c0-.04.038-.086.143-.119 1.193-.279 1.4-.528 1.5-1.278a35.694 35.694 0 0 0 .277-4.1V4.524a4.27 4.27 0 0 0-.246-1.988c-.217-.362-.4-.5-1.825-.674a.259.259 0 0 1-.224-.269v-.6c0-.106.039-.143.176-.106 1.188.073 1.649.106 3.523.106.321 0 .493.072.59.252a8.03 8.03 0 0 0 1.3 1.646c1.165 1.264 3.86 4.208 7.845 7.982.068-1.759.065-6.077-.013-7.819-.047-.826-.144-.89-1.754-1.068-.169 0-.234-.063-.234-.206l-.035-.786h2.559c.795 0 1.938-.106 2.823-.106.1 0 .173.03.176.1l.148.724c-1.768.614-1.976.657-2.1 3.037-.139 2.187-.142 4.142-.142 6.342 0 1.756.043 2.937.043 3.11.008.441-.533.677-.894.816-.169.063-.277 0-.4-.159a17.791 17.791 0 0 0-2.164-2.613c-1.414-1.427-5.368-5.7-7.369-7.683-.2-.176-.315-.09-.35.1-.035.548-.1 1.656-.108 3.116a28.747 28.747 0 0 0 .237 5.088c.212.637.991.77 1.893.856Z"
                    />
                </g>
            </g>
        </g>
    </svg>
)

export default AnotherSolutionIcon
