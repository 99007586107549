import List from "Components/List/List";
import {IBiProductOrderLine} from "Apis/Models/IBiDto";
import { useTranslation } from "react-i18next";
import {formatNumber} from "Utils/NumberUtils";
import InventoryIcon from "Assets/SVG/InventoryIcon";
import MoneyIcon from "Assets/SVG/MoneyIcon";
import TotalComponent from "Components/TotalComponent";

interface IProps {
    lines?: IBiProductOrderLine[];
    isLoading: boolean;
}
const BiProductOrderLineList = (props: IProps) => {

    const { lines, isLoading } = props;
    
    const { t } = useTranslation();
    
    return (
        <>
            <List<IBiProductOrderLine> data={lines} loading={isLoading} columns={[
                {
                    title: t('product'),
                    textRender: (record) => record.product.name ?? '',
                },
                {
                    title: t('sku'),
                    textRender: (record) => record.product.sku ?? '', 
                },
                {
                    title: t('project'),
                    textRender: (record) => record.product.project?.name ?? '',
                },
                {
                    title: t('quantity'),
                    textRender: (record) => record.quantity.toString(),
                    size: "min-content"
                },
                {
                    title: t('unitCostPrice'),
                    textRender: (record) => `${formatNumber(record.product.totalCostPrice)} DKK`,
                    size: 'auto'
                },
                {
                    title: t('unitSalesPrice'),
                    textRender: (record) => `${formatNumber(record.product.totalSalesPrice)} DKK`,
                    size: 'auto'
                },
                {
                    title: t('totalCost'),
                    textRender: (record) => `${formatNumber(record.totalCost)} DKK`,
                    size: 'auto'
                },
                {
                    title: t('totalSalesExclVat'),
                    textRender: (record) => `${formatNumber(record.total)} DKK`,
                    size: 'auto'
                },
            ]} keyExtractor={(record) => record.productId?.toString()} />
            
            <TotalComponent 
                firstHeader={t('quantity')}
                firstTotal={lines?.reduce((acc, curr) => acc + curr.quantity, 0).toString() ?? ''}
                firstIcon={<InventoryIcon />}
                secondHeader={t('totalCost')}
                secondTotal={`${formatNumber(lines?.reduce((acc, curr) => acc + curr.totalCost, 0) ?? 0)} DKK`}
                secondIcon={<MoneyIcon />}
                thirdHeader={t('totalSalesExclVat')}
                thirdTotal={`${formatNumber(lines?.reduce((acc, curr) => acc + curr.total, 0) ?? 0)} DKK`}
                thirdIcon={<MoneyIcon />}
                fourthHeader={t('totalInclVat')}
                fourthTotal={`${formatNumber((lines?.reduce((acc, curr) => acc + curr.total, 0) ?? 0) * 1.25)} DKK`}
                fourthIcon={<MoneyIcon />}
            />
        </>
    )

}

export default BiProductOrderLineList