import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import useQueryParams from "../../Hooks/UseQueryParams";
import Separator from "../../Components/Separator";
import { BIFilter, BiProductOrderLineList } from "Features/BI";
import CalculatorIcon from "../../Assets/SVG/CalculatorIcon";
import {useBI} from "../../Apis/BiApi";
import {OrdersList} from "../../Features/Orders";
import InventoryIcon from "../../Assets/SVG/InventoryIcon";
import {formatNumber} from "../../Utils/NumberUtils";
import MoneyIcon from "../../Assets/SVG/MoneyIcon";
import TotalComponent from "../../Components/TotalComponent";
import BIInvoiceList from "../../Features/BI/Components/BIInvoiceList";
import {getOrderLineTotal, getOrderLineTotalCost} from "../../Features/Orders/Utils/OrderLineUtils";

const Index = () => {
    
    const { t } = useTranslation();

    const [ filter, setFilter ] = useQueryParams();
    
    const { data: bi, isLoading } = useBI(filter);
    
    return (
        <PageLayout title={t('bi.bi')} icon={<CalculatorIcon />}>
            <>
                <BIFilter filterUpdated={(fields, reset) => setFilter(fields, reset)} currentValue={filter} />

                <Separator style={{margin: '30px 0'}} />

                {filter.display === 'Order' && (
                    <>
                        <OrdersList isLoading={isLoading} orders={bi?.orders} />
                        {!!bi?.orders?.length && (
                            <TotalComponent
                                firstHeader={t('quantity')}
                                firstTotal={bi?.orders.flatMap(x => x.orderLines).reduce((acc, curr) => acc + curr.quantity, 0).toString() ?? ''}
                                firstIcon={<InventoryIcon />}
                                secondHeader={t('totalCost')}
                                secondTotal={`${formatNumber(bi?.orders.flatMap(x => x.orderLines).reduce((acc, curr) => acc + getOrderLineTotalCost(curr), 0) ?? 0)} DKK`}
                                secondIcon={<MoneyIcon />}
                                thirdHeader={t('total')}
                                thirdTotal={`${formatNumber(bi?.orders.flatMap(x => x.orderLines).reduce((acc, curr) => acc + getOrderLineTotal(curr), 0) ?? 0)} DKK`}
                                thirdIcon={<MoneyIcon />}
                                fourthHeader={t('totalInclVat')}
                                fourthTotal={`${formatNumber((bi?.orders.flatMap(x => x.orderLines).reduce((acc, curr) => acc + getOrderLineTotal(curr), 0) ?? 0) * 1.25)} DKK`}
                                fourthIcon={<MoneyIcon />} />
                        )}
                    </>
                )}

                {filter.display === 'Product' && (
                    <BiProductOrderLineList isLoading={isLoading} lines={bi?.productLines} />
                )}

                {filter.display === 'Invoice' && (
                    <BIInvoiceList isLoading={isLoading} invoices={bi?.invoices} />
                )}
            </>
        </PageLayout>
    )
}

export default Index
