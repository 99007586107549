import * as React from "react"
import { SVGProps } from "react"

const CaretIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={22.627}
        height={22.627}
        {...props}
        viewBox={"0 0 22.627 22.627"}
    >
        <path
            data-name="Path 546"
            d="m11.314 21.214 9.9-9.9-9.9-9.9"
            fill="none"
            stroke="#d2c8c8"
            strokeLinecap="round"
            strokeWidth={2}
        />
    </svg>
)

export default CaretIcon
