import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {useDeleteProduct, useProduct, useUnlistProduct} from "Apis/ProductApi";
import {ProductForm} from "Features/Products";
import InventoryIcon from "../../Assets/SVG/InventoryIcon";
import Button from "../../Components/Button";
import XIcon from "../../Assets/SVG/XIcon";
import Space from "Components/Space";

const Edit = () => {
    
    const { t } = useTranslation();

    const { id } = useParams();

    const navigate = useNavigate();

    const { data: product, isLoading: isLoadingProduct } = useProduct(parseInt(id!));
    const { mutateAsync: deleteProductMutation, isLoading: isDeletingProduct } = useDeleteProduct();
    const { mutateAsync: unlistProductMutation, isLoading: isUnlistingProduct } = useUnlistProduct();

    const deleteProduct = async () => {
        await deleteProductMutation(product!);

        navigate(-1)
    }

    const unlistProduct = async () => {
        await unlistProductMutation(product!);

        navigate(-1)
    }
    
    return (
        <PageLayout title={t('updateProduct')} icon={<InventoryIcon />} includeBox loading={isLoadingProduct} header={(
            <Space direction={"horizontal"}>
                <Button type={"secondary"} loading={isUnlistingProduct} prefix={<XIcon fill={"#FFF"} />} text={product?.unlisted ? t('relistProduct') : t('unlistProduct')} onClick={unlistProduct} />
                <Button type={"secondary"} loading={isDeletingProduct} prefix={<XIcon fill={"#FFF"} />} text={t('deleteProduct')} onClick={deleteProduct} />
            </Space>
        )}>
            <ProductForm product={product} onSubmit={_ => navigate(-1)} />
        </PageLayout>
    )
}

export default Edit;
