import {toast, ToastOptions} from "react-toastify";

const useToasts = (): { showSuccessToast: Function, showSuccessToastWithContent: Function, showErrorToast: Function} => {

    const showSuccessToast = (message: string) => {
        toast.success(message, {
            position: "bottom-center",
            autoClose: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "light",
        });
    }

    const showSuccessToastWithContent = (content: React.ReactElement, options: ToastOptions<{}> | undefined) => {
        toast.success(content, {
            position: "bottom-center",
            autoClose: 2000,
            pauseOnHover: true,
            draggable: false,
            theme: "light",
            ...options
        });
    }
    
    const showErrorToast = (message: string) => {
        toast.error(message, {
            position: "bottom-center",
            autoClose: 2000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            theme: "light",
        });
    }
    
    return { showSuccessToast, showSuccessToastWithContent, showErrorToast }
}

export default useToasts
