import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import { IPaginatedList } from "Apis/Models/IPaginatedList";
import { ICustomerDto } from "Apis/Models/ICustomerDto";
import {IQueryParams} from "./Models/IQueryParams";

export const CUSTOMERS_CACHE_KEY = 'CUSTOMERS_CACHE_KEY '
export const useCustomers = (queryable: Partial<IQueryParams> | null = null) => {
    return useQuery<IPaginatedList<ICustomerDto>>([CUSTOMERS_CACHE_KEY, queryable], async () => {
        return await request<IPaginatedList<ICustomerDto>>({
            url: `/customers`,
            method: 'GET',
            queryParams: queryable
        })
    });
}

export const useCustomer = (id: number) => {
    return useQuery<ICustomerDto>([CUSTOMERS_CACHE_KEY, id], async () => {
        return await request<ICustomerDto>({
            url: `/customers/${id}`,
            method: 'GET',
        })
    });
}

export const useMutateCustomer = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (customer: ICustomerDto): Promise<ICustomerDto> => {
        if (!customer.id) {
            return await request<ICustomerDto>({
                url: `/customers`,
                method: 'POST',
                data: customer
            })
        } else {
            return await request<ICustomerDto>({
                url: `/customers/${customer.id}`,
                method: 'PUT',
                data: customer
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(CUSTOMERS_CACHE_KEY)
        }
    })
}
