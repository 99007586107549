import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import Button from "Components/Button";
import { useNavigate } from "react-router-dom";
import { ProjectsList } from "Features/Projects";
import { useProjects } from "Apis/ProjectApi";
import GuitarIcon from "Assets/SVG/GuitarIcon";
import Pagination from "../../Components/Pagination";
import useQueryParams from "../../Hooks/UseQueryParams";
import ProjectListFilter from "../../Features/Projects/Components/ProjectListFilter";
import Separator from "Components/Separator";

const Index = () => {
    
    const { t } = useTranslation();

    const [ filter, setFilter ] = useQueryParams();

    const { data: projects, isLoading } = useProjects(filter);
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={t('projects')} icon={<GuitarIcon />} header={(
            <Button prefix={<GuitarIcon fill={"#FFF"} />} text={t('createNewProject')} type={"primary"} onClick={() => navigate("new")} />
        )}>
            <>
                <ProjectListFilter filterUpdated={setFilter} currentValue={filter} />

                <Separator style={{margin: '30px 0'}} />

                <ProjectsList projects={projects?.items} isLoading={isLoading} />

                <Pagination paginatedList={projects} onPageChange={newPage => setFilter({page: newPage})} />
            </>
        </PageLayout>
    )
}

export default Index
