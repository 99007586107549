import PageLayout from "../../Layouts/PageLayout";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import DesignForm from "../../Features/Designs/Components/DesignForm";
import {useDeleteDesign, useDesign} from "../../Apis/DesignApi";
import PrintIcon from "../../Assets/SVG/PrintIcon";
import Button from "../../Components/Button";
import XIcon from "../../Assets/SVG/XIcon";

const Edit = () => {

    const { t } = useTranslation();

    const { id } = useParams();

    const navigate = useNavigate();

    const { data: design, isLoading } = useDesign(parseInt(id!));
    const { mutateAsync: deleteDesignMutation, isLoading: isDeletingDesign } = useDeleteDesign();

    const deleteDesign = async () => {
        await deleteDesignMutation(design!);

        navigate(-1)
    }

    return (
        <PageLayout title={`${t('updateDesign')}`} icon={<PrintIcon />} includeBox loading={isLoading} header={(
            <Button type={"secondary"} loading={isDeletingDesign} prefix={<XIcon fill={"#FFF"} />} text={t('deleteDesign')} onClick={deleteDesign} />
        )}>
            <DesignForm design={design!} onSubmit={_ => navigate(-1)} />
        </PageLayout>
    )

}

export default Edit;