import environment from "Config";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

firebase.initializeApp(environment.firebaseConfig);

let analytics;

if (environment.firebaseConfig.appId) {
    // eslint-disable-next-line no-unused-vars
    analytics = firebase.analytics();
}

export const auth = firebase.auth();
