import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import PeopleIcon from "Assets/SVG/PeopleIcon";
import { useUsers } from "Apis/UsersApi";
import { UsersList } from "Features/Users";
import Button from "Components/Button";
import { useNavigate } from "react-router-dom";
import Pagination from "../../Components/Pagination";
import useQueryParams from "../../Hooks/UseQueryParams";
import UsersListFilter from "../../Features/Users/Components/UsersListFilter";
import Separator from "../../Components/Separator";
import Space from "Components/Space";

const Index = () => {
    
    const { t } = useTranslation();

    const [ filter, setFilter ] = useQueryParams();

    const { data: users, isLoading } = useUsers(filter);
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={t('users')} icon={<PeopleIcon />} header={(
            <Space direction={"horizontal"}>
                <Button prefix={<PeopleIcon fill={"#FFF"} />} text={t('createNewApi')} type={"secondary"} onClick={() => navigate("new/api")} />
                <Button prefix={<PeopleIcon fill={"#FFF"} />} text={t('createNewUser')} type={"secondary"} onClick={() => navigate("new/user")} />
            </Space>
        )}>
            <>
                <UsersListFilter currentValue={filter} filterUpdated={setFilter} />

                <Separator style={{margin: '30px 0'}} />

                <UsersList users={users?.items} isLoading={isLoading} />

                <Pagination paginatedList={users} onPageChange={newPage => setFilter({page: newPage})} />
            </>
        </PageLayout>
    )
}

export default Index
