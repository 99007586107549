import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import { IPaginatedList } from "Apis/Models/IPaginatedList";
import {IMutateOrderDto, IOrderDto, IOverviewOrderDto} from "Apis/Models/IOrderDto";
import {IQueryParams} from "./Models/IQueryParams";
import { saveAs } from 'file-saver';
import {ORDER_INVOICES_CACHE_KEY} from "./InvoicesApi";
import {IInvoiceDto} from "./Models/IInvoiceDto";

export const ORDERS_CACHE_KEY = 'ORDERS_CACHE_KEY '
export const useOrders = (queryable: Partial<IQueryParams> | null = null) => {
    return useQuery<IPaginatedList<IOverviewOrderDto>>([ORDERS_CACHE_KEY, queryable], async () => {
        return await request<IPaginatedList<IOverviewOrderDto>>({
            url: `/orders`,
            method: 'GET',
            queryParams: queryable
        })
    });
}

export const useOrder = (id: number) => {
    return useQuery<IOrderDto>([ORDERS_CACHE_KEY, id], async () => {
        return await request<IOrderDto>({
            url: `/orders/${id}`,
            method: 'GET',
        })
    });
}

export const useMutateOrder = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (order: IMutateOrderDto): Promise<IOrderDto> => {
        if (!order.id) {
            return await request<IOrderDto>({
                url: `/orders`,
                method: 'POST',
                data: order
            })
        } else {
            return await request<IOrderDto>({
                url: `/orders/${order.id}`,
                method: 'PUT',
                data: order
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(ORDERS_CACHE_KEY)
        }
    })
}

export const useCreateInvoiceDraft = () => {
    const queryClient = useQueryClient();

    return useMutation(async (order: IOrderDto) => {
        return await request<IInvoiceDto>({
            url: `/orders/${order.id}/invoice`,
            method: 'POST',
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(ORDER_INVOICES_CACHE_KEY)
        }
    })
}

export const getOrderConfirmation = async (order: IOrderDto, fileName: string): Promise<any> => {
    
    const responseBlob = await request<Blob>({
        responseType: 'blob',
        url: `/orders/${order.id}/orderConfirmation`,
        method: 'GET',
    });

    const blob = new Blob([responseBlob], {type: 'application/pdf' });

    saveAs(blob, fileName);
}

export const getOrderProductionNote = async (order: IOrderDto, fileName: string, expanded: boolean = false): Promise<any> => {

    const responseBlob = await request<Blob>({
        responseType: 'blob',
        url: `/orders/${order.id}/productionNote?expanded=${expanded}`,
        method: 'GET',
    });

    const blob = new Blob([responseBlob], {type: 'application/pdf' });

    saveAs(blob, fileName);
}

export const getOrderDeliveryNote = async (order: IOrderDto, fileName: string): Promise<any> => {

    const responseBlob = await request<Blob>({
        responseType: 'blob',
        url: `/orders/${order.id}/deliverynote`,
        method: 'GET',
    });

    const blob = new Blob([responseBlob], {type: 'application/pdf' });

    saveAs(blob, fileName);
}