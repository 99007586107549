import Button from "Components/Button";
import FlexRow from "Components/FlexRow";
import Modal from "Components/Modal";
import Space from "Components/Space";
import {useTranslation} from "react-i18next";
import {useChangeOrderStatusForMultipleOrders} from "../../../Apis/InvoicesApi";
import {IOverviewOrderDto, OrderStatus} from "../../../Apis/Models/IOrderDto";
import useToasts from "../../../Hooks/UseToasts";
import React from "react";
import {useForm} from "react-hook-form";
import ControlledSelect from "../../../Components/Select/ControlledSelect";
import Select from "Components/Select";
import {useOrderStatus} from "../Hooks/UseOrderStatus";
import useFilteredList from "../../../Hooks/UseFilteredList";

type FormData = {
    orderStatus: OrderStatus;
}

interface Props {
    visible: boolean;
    toggleVisible: () => void;
    orders: IOverviewOrderDto[];
}
const ChangeOrderStatusModal = (props: Props) => {

    const { t } = useTranslation();

    const { showSuccessToast } = useToasts();
    const { control, handleSubmit } = useForm<FormData>();

    const { mutateAsync, isLoading } = useChangeOrderStatusForMultipleOrders();

    const { actualtOrderStatus } = useOrderStatus();

    const [ filteredStatuses, setFilteredStatuses ] = useFilteredList(actualtOrderStatus, x => [x.label]);

    const changeOrderStatus = async (data: FormData) => {
        await mutateAsync({
            orderIds: props.orders.map(x => x.id),
            orderStatus: data.orderStatus
        });

        showSuccessToast(t('statusChanged'));
        
        props.toggleVisible();
    }

    return (
        <Modal title={t('createInvoice')} visible={props.visible} onCancel={() => props.toggleVisible()} modalStyle={{overflowY: 'visible'}}>
            <form onSubmit={handleSubmit(changeOrderStatus)}>
                <Space size={"large"}>
                    <ControlledSelect control={control} placeholder={t('status')} name={`orderStatus`} onFilter={setFilteredStatuses}>
                        {filteredStatuses?.map((status, index) => <Select.Option key={index} value={status.value} label={status.label}/>)}
                    </ControlledSelect>

                    <FlexRow justify={"end"}>
                        <Button type={"secondary"} buttonType={"submit"} loading={isLoading} text={t('changeOrderStatus')}/>
                    </FlexRow>
                </Space>
            </form>
        </Modal>
    )
}

export default ChangeOrderStatusModal