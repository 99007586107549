import { Navigate, Route, Routes } from "react-router-dom";
import SignIn from "Pages/SignIn";
import RequireAuthentication from "Components/RequireAuthentication";
import RedirectIfLoggedIn from "Components/RedirectIfLoggedIn";
import UserRoutes from "Pages/Users/UserRoutes";
import ResetPassword from "Pages/ResetPassword";
import AuthActions from "Pages/AuthActions";
import CustomerRoutes from "Pages/Customers/CustomerRoutes";
import ProjectRoutes from "Pages/Projects/ProjectRoutes";
import PartnerRoutes from "Pages/Partners/PartnerRoutes";
import OrderRoutes from "Pages/Orders/OrderRoutes";
import PrintRoutes from "Pages/Prints/PrintRoutes";
import ProductRoutes from "Pages/Products/ProductRoutes";
import DesignRoutes from "./Designs/DesignRoutes";
import BIRoutes from "./BI/BIRoutes";
import SideMenuLayout from "Layouts/SideMenuLayout";
import TagRoutes from "./Tags/TagRoutes";

const ApplicationRoutes = () => {
    
    return (
        <Routes>
    
            <Route path={"/signin"} element={
                <RedirectIfLoggedIn>
                    <SignIn />
                </RedirectIfLoggedIn>
            } />
    
            <Route path={"/resetpassword"} element={
                <RedirectIfLoggedIn>
                    <ResetPassword />
                </RedirectIfLoggedIn>
            } />
    
            <Route path={"/auth/actions"} element={
                <RedirectIfLoggedIn>
                    <AuthActions />
                </RedirectIfLoggedIn>
            } />
    
            <Route path={"*"} element={
                <RequireAuthentication>
                    <SideMenuLayout>
                        <Routes>
                            <Route path={`/orders/*`} element={
                                <OrderRoutes />
                            } />

                            <Route path={`/users/*`} element={
                                <UserRoutes />
                            } />

                            <Route path={`/bi/*`} element={
                                <BIRoutes />
                            } />

                            <Route path={`/customers/*`} element={
                                <CustomerRoutes />
                            } />

                            <Route path={`/projects/*`} element={
                                <ProjectRoutes />
                            } />

                            <Route path={`/products/*`} element={
                                <ProductRoutes />
                            } />

                            <Route path={`/designs/*`} element={
                                <DesignRoutes />
                            } />

                            <Route path={`/prints/*`} element={
                                <PrintRoutes />
                            } />

                            <Route path={`/tags/*`} element={
                                <TagRoutes />
                            } />

                            <Route path={`/partners/*`} element={
                                <PartnerRoutes />
                            } />

                            <Route path={"*"} element={<Navigate to={"/orders?page=0&status=Open&reverseTagSearch=false"}/>}/>
                        </Routes>
                    </SideMenuLayout>
                </RequireAuthentication>
            }>
            </Route>
        </Routes>
    )
}

export default ApplicationRoutes
