import { Controller } from "react-hook-form";
import React, { CSSProperties, Ref } from "react";
import styles from './ControlledTextArea.module.scss';

interface IProps {
    errors?: any,
    name: string,
    rules?: any,
    control: any,
    inputStyle?: CSSProperties;
    inputRef?: Ref<HTMLTextAreaElement>;
    placeholder?: string;
    defaultValue?: string;
    onChange?: (value: any) => void;
    rows?: number
}
const ControlledTextArea = (props: IProps) => {
    
    return (
        <Controller
            name={props.name}
            rules={props.rules}
            control={props.control}
            defaultValue={props.defaultValue ?? ''}
            render={(
                { field: { onChange, value } },
            ) => (
                <textarea
                    rows={props.rows}
                    placeholder={props.placeholder}
                    className={`${styles.input}`}
                    onChange={(value) => {
                        onChange(value.currentTarget.value);
    
                        if (props.onChange) {
                            props.onChange(value)
                        }
                    }}
                    value={value}
                    style={props.inputStyle}
                    ref={props.inputRef}
                />
            )}
        />
    )
}

export default ControlledTextArea
