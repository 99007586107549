import { IOrderDto } from "Apis/Models/IOrderDto";
import styles from "./OrderProgressBar.module.scss";
import {useEffect, useMemo, useState} from "react";
import { useTranslation } from "react-i18next";

const green = "#21BF2D";
const red = "#E91D25";
const yellow = "#FFBA00";

const orderStatusCount = 5;

interface IProps {
    order: IOrderDto
}
const OrderProgressBar = (props: IProps) => {
    
    const { t } = useTranslation();
    
    const [loaded, setLoaded] = useState<boolean>(false);
    
    const { order} = props;
    
    const orderStatus = useMemo(() => [
        {
            status: "Received",
            text: t('received'),
            color: yellow,
            fillPercent: 1 / orderStatusCount
        },
        {
            status: "Accepted",
            text: t('accepted'),
            color: green,
            fillPercent: 2 / orderStatusCount
        },
        {
            status: "Produced",
            text: t('accepted'),
            color: green,
            fillPercent: 3 / orderStatusCount
        },
        {
            status: "Delivered",
            text: t('accepted'),
            color: green,
            fillPercent: 4 / orderStatusCount
        },
        {
            status: "Finished",
            text: t('finished'),
            color: green,
            fillPercent: 5 / orderStatusCount
        },
        {
            status: "Cancelled",
            text: t('cancelled'),
            color: red,
            fillPercent: 5 / orderStatusCount
        },
    ], [t]);

    useEffect(() => {
        setLoaded(true); // Used for width animation
    }, []);
    
    let currentStatus = orderStatus.find(x => x.status === order.currentOrderStatus);

    return (
        <div className={styles.container}>
            <div className={styles.progressMarker}>
                {Array.from(Array(orderStatusCount)).map((_, index) => (
                    <div key={index} style={{width: `${100 / orderStatusCount}%`}}>
                        
                    </div>
                ))}
            </div>
            <div className={styles.progress} style={{backgroundColor: currentStatus?.color, width: !loaded ? 0 : `${((currentStatus?.fillPercent ?? 0) * 100)}%`}} />
            
        </div>
    )

}

export default OrderProgressBar