import PeopleIcon from "Assets/SVG/PeopleIcon";
import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "Apis/UsersApi";
import { UsersForm } from "Features/Users";

const Edit = () => {
    
    const { t } = useTranslation();
    
    const { id } = useParams();
    
    const { data: user } = useUser(parseInt(id!));
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={t('updateUser')} icon={<PeopleIcon />} includeBox>
            <UsersForm user={user} onSubmit={_ => navigate(-1)} />
        </PageLayout>
    )
}

export default Edit;
