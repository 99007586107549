import {IQueryParam} from "../../../Apis/Models/IQueryParams";
import Label from "../../../Components/Label";
import Select from "../../../Components/Select";
import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useCustomers} from "../../../Apis/CustomerApi";
import {useProjects} from "../../../Apis/ProjectApi";
import useFilteredList from "../../../Hooks/UseFilteredList";
import Separator from "Components/Separator";
import moment from "moment/moment";
import DatePicker from "../../../Components/DatePicker";
import {useProducts} from "../../../Apis/ProductApi";
import {useInvoiceStatus} from "../../Orders/Hooks/UseInvoiceStatus";
import {usePartners} from "../../../Apis/PartnerApi";

interface Fields {
    [key: string]: IQueryParam;
}
interface IProps {
    filterUpdated: (params: Fields, resetParams: boolean) => void;
    currentValue: Fields;
}
const BIFilter = (props: IProps) => {

    const { filterUpdated, currentValue } = props;
    const { t } = useTranslation();

    const { data: customers } = useCustomers({ includeAll: true });
    const { data: projects } = useProjects({ includeAll: true });
    const { data: products } = useProducts({ includeAll: true, projectId: currentValue.projectId ?? null });
    const { data: partners } = usePartners({ includeAll: true });
    
    const orderStatuses = useMemo(() => [
        { value: undefined,label: t('orderStatus.all')},
        { value: 'Received', label: t('orderStatus.received')},
        { value: 'Accepted', label: t('orderStatus.accepted')},
        { value: 'Produced', label: t('orderStatus.produced')},
        { value: 'Delivered', label: t('orderStatus.delivered')},
        { value: 'Finished', label: t('orderStatus.finished')},
        { value: 'Cancelled', label: t('orderStatus.cancelled')}
    ], [t]);

    const { invoiceStatuses } = useInvoiceStatus();

    const displays = useMemo(() => [
        { value: 'Order', label: t('bi.display.order')},
        { value: 'Product', label: t('bi.display.product')},
        { value: 'Invoice', label: t('bi.display.invoice')},
    ], [t]);

    const [ query, setQuery ] = useState<Fields>(currentValue);

    useEffect(() => {
        setQuery(currentValue);
    }, [currentValue]);

    const [ filteredProjects, setProjectFilter, projectFilterValue ] = useFilteredList(projects?.items ?? [], x => [x.name]);
    const [ filteredCustomers, setCustomerFilter, customerFilterValue ] = useFilteredList(customers?.items ?? [], x => [x.name, x.vatNumber]);
    const [ filteredProducts, setProductFilter, productFilterValue ] = useFilteredList(products?.items ?? [], x => [x.name]);
    const [ filteredOrderStatus, setFilteredStatuses ] = useFilteredList(orderStatuses, x => [x.label]);
    const [ filteredInvoiceStatus, setFilteredInvoicestatus ] = useFilteredList(invoiceStatuses, x => [x.label]);
    const [ filteredDateStatus, setFilteredDateStatus ] = useFilteredList(orderStatuses, x => [x.label]);
    const [ filteredDisplays, setFilteredDisplays ] = useFilteredList(displays, x => [x.label]);
    const [ filteredPartners, setPartnerFilter, referralFilterValue ] = useFilteredList(partners?.items ?? [], x => [x.name]);

    const setQueryField = (fieldName: 'display' | 'productId' | 'statusDate' | 'from' | 'to' | 'customerId' | 'projectId' | 'status' | 'invoiceStatus' | 'referralId', value: IQueryParam) => {
        const newValue = {...query, [fieldName]: value }
        setQuery(newValue);
        filterUpdated(newValue, true);
    }
    
    return (
        <>
            <div style={{display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 20}}>
                <div>
                    <Label placeholder={t('display')} />
                    <Select name={"display"} value={query['display']} onChange={value => setQueryField('display', value)} onFilter={setFilteredDisplays}>
                        {filteredDisplays?.map(display => <Select.Option key={display.value} value={display.value} label={display.label} />)}
                    </Select>
                </div>

                {(currentValue.display === 'Order' || currentValue.display === 'Product') && (
                    <>
                        <div>
                            <Label placeholder={t('dateTypeFilter')} />
                            <Select name={"statusDate"} value={query['statusDate']} onChange={value => setQueryField('statusDate', value)} onFilter={setFilteredDateStatus}>
                                {filteredDateStatus?.map(dateStatus => <Select.Option key={dateStatus.value} value={dateStatus.value} label={dateStatus.label} />)}
                            </Select>
                        </div>
                        
                        <div>
                            <Label placeholder={t('from')} />
                            <DatePicker name={'from'} onChange={value => setQueryField('from', value ? moment(value).format('YYYY-MM-DD') : '')} value={!!query['from'] ? query['from'].toString() : undefined} allowClear={true} />
                        </div>
        
                        <div>
                            <Label placeholder={t('to')} />
                            <DatePicker name={'from'} onChange={value => setQueryField('to', value)} value={!!query['to'] ? query['to'].toString() : undefined} allowClear={true} />
                        </div>
                    </>
                )}
            </div>
            
            <Separator style={{margin: '20px 0'}} />
            
            <div style={{display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 20}}>
                {(currentValue.display === 'Order' || currentValue.display === 'Invoice') && (
                    <div>
                        <Label placeholder={t('customer')} />
                        <Select name={"customerId"} value={query['customerId']} onChange={value => setQueryField('customerId', value)} onFilter={setCustomerFilter}>
                            {!customerFilterValue && (
                                <Select.Option value={undefined} label={t('all')} />
                            )}
                            {filteredCustomers?.map(customer => <Select.Option key={customer.id} value={customer.id} label={customer.name} />)}
                        </Select>
                    </div>
                )}
                {(currentValue.display === 'Order' || currentValue.display === 'Product' || currentValue.display === 'Invoice') && (
                    <div>
                        <Label placeholder={t('project')} />
                        <Select name={"projectId"} value={query['projectId']} onChange={value => setQueryField('projectId', value)} onFilter={setProjectFilter}>
                            {!projectFilterValue && (
                                <Select.Option value={undefined} label={t('all')} />
                            )}
                            {filteredProjects?.map(project => <Select.Option key={project.id} value={project.id} label={project.name} />)}
                        </Select>
                    </div>
                )}

                {(currentValue.display === 'Order' || currentValue.display === 'Product' || currentValue.display === 'Invoice') && (
                    <div>
                        <Label placeholder={t('referral')} />
                        <Select name={"referralId"} value={query['referralId']} onChange={value => setQueryField('referralId', value)} onFilter={setPartnerFilter}>
                            {!referralFilterValue && (
                                <Select.Option value={undefined} label={t('all')} />
                            )}
                            {filteredPartners?.map(partner => <Select.Option key={partner.id} value={partner.id} label={partner.name} />)}
                        </Select>
                    </div>
                )}

                {(currentValue.display === 'Product') && (
                    <div>
                        <Label placeholder={t('product')} />
                        <Select name={"productId"} value={query['productId']} onChange={value => setQueryField('productId', value)} onFilter={setProductFilter}>
                            {!productFilterValue && (
                                <Select.Option value={undefined} label={t('all')} />
                            )}
                            {filteredProducts?.map(project => <Select.Option key={project.id} value={project.id} label={project.name} />)}
                        </Select>
                    </div>
                )}

                {(currentValue.display === 'Order' || currentValue.display === 'Product') && (
                    <div>
                        <Label placeholder={t('status')} />
                        <Select name={"status"} value={query['status']} onChange={value => setQueryField('status', value)} onFilter={setFilteredStatuses}>
                            {filteredOrderStatus?.map((status, index) => <Select.Option key={index} value={status.value} label={status.label} />)}
                        </Select>
                    </div>
                )}

                {(currentValue.display === 'Invoice') && (
                    <div>
                        <Label placeholder={t('status')} />
                        <Select name={"status"} value={query['invoiceStatus']} onChange={value => setQueryField('invoiceStatus', value)} onFilter={setFilteredInvoicestatus}>
                            {filteredInvoiceStatus?.map((status, index) => <Select.Option key={index} value={status.value} label={status.label} />)}
                        </Select>
                    </div>
                )}
            </div>
        </>
    )

}

export default BIFilter