import { Navigate, Route, Routes } from "react-router-dom";
import Index from "./Index";
import NewUser from "./NewUser";
import Edit from "./Edit";
import NewApi from "./NewApi";

const UserRoutes = () => {
    
    return (
        <Routes>

            <Route path={`new/api`} element={<NewApi />}/>
            <Route path={`new/user`} element={<NewUser />}/>

            <Route path={`edit/:id`} element={<Edit />}/>

            <Route path={`/`} element={<Index />}/>

            <Route path={"*"} element={<Navigate to={"/users"}/>}/>
        </Routes>
    )
}

export default UserRoutes
