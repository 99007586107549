import { useForm } from "react-hook-form";
import ControlledInput from "Components/Input";
import { useTranslation } from "react-i18next";
import Button from "Components/Button";
import Space from "Components/Space";
import FlexRow from "Components/FlexRow";
import { useEffect } from "react";
import { IPrintPlacementDto, IPrintSizeDto } from "Apis/Models/IPrintDto";
import { useMutatePrintSize } from "Apis/PrintSizeApi";

interface IProps {
    printSize?: IPrintSizeDto;
    onSubmit?: (data: IPrintPlacementDto) => void;
}
const PrintSizeForm = (props: IProps) => {
    
    const { t } = useTranslation();
    
    const { control, handleSubmit, reset } = useForm<IPrintSizeDto>()
    const { mutateAsync, isLoading } = useMutatePrintSize();
    
    useEffect(() => {
        if (props.printSize) {
            reset(props.printSize);
        }
    }, [reset, props.printSize])
    
    const onSubmit = async(data: IPrintSizeDto) => {
        await mutateAsync(data);
        
        if (props.onSubmit) {
            props.onSubmit(data);
        }
        
        reset();
    }
    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Space direction={"vertical"} size={"large"}>
                <ControlledInput rules={{required: true}} name={"name"} control={control} placeholder={t('name')} />
                
                <FlexRow justify={"end"}>
                    <Button buttonType={"submit"} type={"primary"} text={t('save')} loading={isLoading} />
                </FlexRow>
            </Space>
        </form>
    )
}

export default PrintSizeForm
