import DotsIcon from "Assets/SVG/DotsIcon";
import List from "Components/List/List";
import { useNavigate } from "react-router-dom";
import FlexRow from "Components/FlexRow";
import { IPartnerDto } from "Apis/Models/IPartnerDto";
import { useTranslation } from "react-i18next";

interface IProps {
    partners?: IPartnerDto[];
    isLoading: boolean;
}
const PartnersList = (props: IProps) => {
    
    const { t } = useTranslation();
    
    const { partners, isLoading } = props;
    
    const navigate = useNavigate();
    
    return (
        <List<IPartnerDto> data={partners} loading={isLoading} columns={[
            {
                title: t('name'),
                textRender: (record) => record.name
            },
            {
                title: t('vatNumber'),
                size: "auto",
                textRender: (record) => record.vatNumber
            },
            {
                title: t('address'),
                textRender: (record) => record.address ? `${record.address} - ${record.zip} - ${record.city}` : ''
            },
            {
                title: t('phone'),
                textRender: (record) => record.phone
            },
            {
                title: t('email'),
                textRender: (record) => record.email
            },
            {
                title: t('functions'),
                size: "min-content",
                customRender: (record) => <FlexRow className={"h-100 clickable"} justify={"center"} onClick={() => navigate(`/partners/edit/${record.id}`)} ><DotsIcon /></FlexRow>
            }
        ]} keyExtractor={(record) => record.id.toString()} imageExtractor={record => record.image ? [record.image] : []} />
    )
}

export default PartnersList;
