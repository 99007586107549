import * as React from "react"
import { SVGProps } from "react"
const MoneyIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={"0 0 16 16"} width={16} height={16.011} {...props}>
        <g data-name="001-price-tag">
            <path
                fill={props.fill ?? '#cbc1c1'}
                d="M15.999 3.071a8.88 8.88 0 0 1-1.108 4.273.475.475 0 0 1-.812-.494 8.052 8.052 0 0 0 .967-3.78A1.953 1.953 0 0 0 13.149.95a1.9 1.9 0 0 0-1.9 1.9 15.029 15.029 0 0 0 .3 1.981.475.475 0 0 1-.93.194 15.621 15.621 0 0 1-.32-2.175A2.855 2.855 0 0 1 13.149 0a2.9 2.9 0 0 1 2.85 3.071Zm-2.77.748c.189 1.579.463 4.656.148 4.969l-6.9 6.9a1.087 1.087 0 0 1-1.539 0l-4.62-4.612a1.088 1.088 0 0 1 0-1.539l6.9-6.9a10.289 10.289 0 0 1 2.647-.045c-.007.089-.027.172-.027.263A9.4 9.4 0 0 0 9.999 4.2a1.307 1.307 0 1 0 1.827-.341 7.964 7.964 0 0 1-.125-1.007c0-.042.009-.081.012-.123.165.017.328.035.483.055.913.116.922.109 1.033 1.035Zm-3.68 5.167a2.976 2.976 0 0 0-.673-1.067l.524-.525-.583-.583-.566.566a1.545 1.545 0 0 0-2.067.053c-.577.577-.441 1.31-.017 2.162.291.59.344.929.118 1.155s-.607.131-.947-.208a2.972 2.972 0 0 1-.726-1.251l-.876.519a3.413 3.413 0 0 0 .762 1.262l-.571.572.583.583.614-.613a1.6 1.6 0 0 0 2.156-.035c.542-.543.59-1.15.125-2.115-.322-.691-.4-1.031-.209-1.221.167-.167.465-.215.852.172a2.619 2.619 0 0 1 .649 1.068Z"
                data-name="Path 508"
            />
        </g>
    </svg>
)
export default MoneyIcon
