import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PageLayout from "Layouts/PageLayout";
import Button from "Components/Button";
import { TagList } from "../../Features/Tags";
import { useTags } from "Apis/TagApi";
import useQueryParams from "../../Hooks/UseQueryParams";
import Pagination from "Components/Pagination";
import TagIcon from "Assets/SVG/TagIcon";

const Index = () => {
    
    const { t } = useTranslation();

    const [ filter, setFilter ] = useQueryParams();

    const { data: tags, isLoading } = useTags(filter);
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={t('tags')} icon={<TagIcon />} header={(
            <Button prefix={<TagIcon fill={"#FFF"} />} text={t('createNewTag')} type={"primary"} onClick={() => navigate("new")} />
        )}>
            <>
                <TagList tags={tags?.items} isLoading={isLoading} />

                <Pagination paginatedList={tags} onPageChange={newPage => setFilter({page: newPage})} />
            </>
        </PageLayout>
    )
}

export default Index
