import styles from './Box.module.scss';

interface IProps {
    children: React.ReactNode;
}
const Box = (props: IProps) => {
    
    const { children } = props;
    
    return (
        <div className={styles.box}>
            {children}
        </div>
    )
}

export default Box
