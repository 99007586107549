import XIcon from "../../../Assets/SVG/XIcon";
import ControlledSelect from "../../../Components/Select/ControlledSelect";
import Select from "../../../Components/Select";
import ControlledInput from "../../../Components/Input";
import {FieldArrayWithId, useFormContext} from "react-hook-form";
import {IMutateOrderDto} from "../../../Apis/Models/IOrderDto";
import useFilteredList from "../../../Hooks/UseFilteredList";
import {IProductDto} from "../../../Apis/Models/IProductDto";
import {IPartnerDto} from "../../../Apis/Models/IPartnerDto";
import {Trans, useTranslation} from "react-i18next";
import { useCheckProductStock } from "Apis/ProductApi";
import React, {useState} from "react";
import styles from './OrderForm.module.scss'

type Props = {
    field:  FieldArrayWithId<IMutateOrderDto, "orderLines", "fieldId">,
    remove: Function,
    index: number;
    shouldDisableFields: boolean;
    products: IProductDto[];
    partners: IPartnerDto[];
}
const OrderFormLine = ({field, remove, index, shouldDisableFields, products, partners}: Props) => {

    const { t } = useTranslation();

    const { register, control, watch} = useFormContext()

    const [ filteredProducts, setProductFilter ] = useFilteredList(products, x => [x.name]);
    const [ filteredPartners, setPartnerFilter ] = useFilteredList(partners ?? [], x => [x.name, x.vatNumber]);

    const { mutateAsync: checkStock } = useCheckProductStock();

    const [productStock, setProductStock] = useState<number>();

    const checkProductStock = async () => {
        setProductStock(undefined)

        const productId = watch(`orderLines.${index}.productId`);

        if (!!productId) {
            const response = await checkStock(watch(`orderLines.${index}.productId`));
            setProductStock(response.qtyInStock)
        }
    }

    return (
        <div key={field.fieldId} className={styles.orderLineContainer}>
            <div className={styles.delete} onClick={() => remove(index)}>
                <XIcon/>
            </div>
            <div className={styles.twoColumns}>
                <input style={{display: 'none'}} {...register(`orderLines.${index}.lineIndex`)} value={index}/>

                <div>
                    <ControlledSelect disabled={shouldDisableFields} rules={{required: true}} control={control} placeholder={t('product')} name={`orderLines.${index}.productId`} onFilter={setProductFilter} onChange={checkProductStock}>
                        {filteredProducts?.filter(x => x.id === field.productId || !x.unlisted).map(x => <Select.Option key={x.id} label={x.name} value={x.id}/>)}
                    </ControlledSelect>
                </div>

                <div>
                    <ControlledInput disabled={shouldDisableFields} rules={{min: 1}} type={"number"} name={`orderLines.${index}.quantity`} control={control} placeholder={t('quantity')} />
                    {!!productStock && (
                        <span className={watch(`orderLines.${index}.quantity`) > productStock ? styles.lowStock : styles.inStock}>
                            <Trans i18nKey={`inStockQuantity`} values={{ qty: productStock }} />
                        </span>
                    )}
                </div>
            </div>

            {products.find(x => x.id === watch(`orderLines.${index}.productId`)) && (
                <>
                    {products.find(x => x.id === watch(`orderLines.${index}.productId`))?.productType === 'Service' && (
                        <div>
                            <ControlledInput type={"number"} name={`orderLines.${index}.customUnitPrice`} control={control} placeholder={t('customUnitPrice')}/>
                        </div>
                    )}

                    {products.find(x => x.id === watch(`orderLines.${index}.productId`))?.productType !== 'Discount' && (
                        <div>
                            <ControlledInput rules={{min: 0, max: 99}} type={"number"} name={`orderLines.${index}.discountInPercent`} control={control} placeholder={t('discountAsPercent')}/>
                        </div>
                    )}

                    {products.find(x => x.id === watch(`orderLines.${index}.productId`))?.productType === 'Discount' && (
                        <div>
                            <ControlledInput type={"number"} name={`orderLines.${index}.discountAsAmount`} control={control} placeholder={t('discountAsAmount')}/>
                        </div>
                    )}
                </>
            )}

            <div>
                <ControlledInput disabled={shouldDisableFields} name={`orderLines.${index}.comment`} control={control} placeholder={t('comment')}/>
            </div>

            <div>
                <ControlledSelect disabled={shouldDisableFields} control={control} placeholder={t('outsourcing')} name={`orderLines.${index}.outsourcingPartnerId`} onFilter={setPartnerFilter}>
                    <Select.Option value={null} label={t('inHouse')}></Select.Option>
                    {filteredPartners?.map(x => <Select.Option key={x.id} label={x.name} value={x.id}/>)}
                </ControlledSelect>
            </div>
        </div>
    )

}

export default OrderFormLine