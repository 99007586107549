import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PrintsIcon from "Assets/SVG/PrintsIcon";
import {useDesign} from "../../Apis/DesignApi";
import React from "react";
import DesignForm from "Features/Designs/Components/DesignForm";

const Clone = () => {
    
    const { t } = useTranslation();
    
    const { id } = useParams();
    
    const { data: design } = useDesign(parseInt(id!));
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={`${t('cloneDesign')} - ${design?.name}`} icon={<PrintsIcon />} includeBox>
            <DesignForm design={{...design, id: undefined}} onSubmit={_ => navigate(-1)} />
        </PageLayout>
    )
}

export default Clone;
