import Button from "Components/Button";
import FlexRow from "Components/FlexRow";
import Modal from "Components/Modal";
import Space from "Components/Space";
import {Trans, useTranslation} from "react-i18next";
import useToasts from "../../../Hooks/UseToasts";
import React from "react";
import {IProductDto} from "../../../Apis/Models/IProductDto";
import {useBulkUpdateProducts} from "../../../Apis/ProductApi";
import {useForm} from "react-hook-form";
import ControlledInput from "../../../Components/Input";

type FormData = {
    find?: string | undefined;
    replace?: string | undefined;
    designUnitCostPrice?: number | undefined;
    designUnitSalesPrice?: number | undefined;
    inventoryItemUnitCostPrice?: number | undefined;
    inventoryItemUnitSalesPrice?: number | undefined;
}

interface Props {
    visible: boolean;
    toggleVisible: () => void;
    products: IProductDto[];
}
const ProductSearchAndReplace = (props: Props) => {

    const { t } = useTranslation();

    const { showSuccessToast } = useToasts();

    const { control, handleSubmit, reset } = useForm<FormData>();

    const { mutateAsync, isLoading } = useBulkUpdateProducts();

    const bulkUpdate = async (data: FormData) => {
        await mutateAsync({
            productIds: props.products.map(x => x.id),
            ...data
        });

        showSuccessToast(t('bulkUpdatedProducts'));

        reset({});

        props.toggleVisible();
    }

    return (
        <Modal title={t('searchAndReplace')} visible={props.visible} onCancel={() => props.toggleVisible()} modalStyle={{overflowY: 'visible'}}>
            <Space size={"large"}>
                <Trans i18nKey={`youAreAboutToReplaceTheNameAndPriceForXProducts`} values={{count: props.products.length}}/>

                <form onSubmit={handleSubmit(bulkUpdate)}>
                    <Space size={"large"}>
                        <ControlledInput name={"find"} control={control} placeholder={t('find')} />
                        <ControlledInput name={"replace"} control={control} placeholder={t('replace')} />

                        <hr/>

                        <ControlledInput name={"designUnitCostPrice"} control={control} placeholder={t('designUnitCostPrice')} type={"number"}/>
                        <ControlledInput name={"designUnitSalesPrice"} control={control} placeholder={t('designUnitSalesPrice')} type={"number"}/>

                        <hr/>

                        <ControlledInput name={"inventoryItemUnitCostPrice"} control={control} placeholder={t('inventoryItemUnitCostPrice')} type={"number"}/>
                        <ControlledInput name={"inventoryItemUnitSalesPrice"} control={control} placeholder={t('inventoryItemUnitSalesPrice')} type={"number"} />

                        <FlexRow justify={"end"}>
                            <Button type={"secondary"} buttonType={"submit"} loading={isLoading} text={t('bulkUpdate')}/>
                        </FlexRow>
                    </Space>
                </form>
            </Space>
        </Modal>
    )
}

export default ProductSearchAndReplace