import { Navigate, Route, Routes } from "react-router-dom";
import InventoryItemsIndex from "./InventoryItems/Index";
import Index from "./Index";
import NewProduct from "./NewProduct";
import Edit from "./Edit";
import Clone from "./Clone";
import NewService from "./NewService";

const ProductRoutes = () => {
    
    return (
        <Routes>
            <Route path={`inventoryItems`} element={<InventoryItemsIndex />}/>

            <Route path={`/edit/:id`} element={<Edit />}/>
            <Route path={`clone/:id`} element={<Clone />}/>
            <Route path={`/new/product`} element={<NewProduct />}/>
            <Route path={`/new/service`} element={<NewService />}/>
            <Route path={`/`} element={<Index />}/>

            <Route path={"*"} element={<Navigate to={"/products"}/>}/>
        </Routes>
    )
}

export default ProductRoutes
