import DotsIcon from "Assets/SVG/DotsIcon";
import List from "Components/List/List";
import { useNavigate } from "react-router-dom";
import FlexRow from "Components/FlexRow";
import { ICustomerDto } from "Apis/Models/ICustomerDto";
import { useTranslation } from "react-i18next";

interface IProps {
    customers?: ICustomerDto[];
    isLoading: boolean;
}
const CustomersList = (props: IProps) => {
    
    const { t } = useTranslation();
    
    const { customers, isLoading } = props;
    
    const navigate = useNavigate();
    
    return (
        <List<ICustomerDto> data={customers} loading={isLoading} columns={[
            {
                title: t('name'),
                textRender: (record) => record.name
            },
            {
                title: t('vatNumber'),
                textRender: (record) => record.vatNumber
            },
            {
                title: t('address'),
                textRender: (record) => record.address ? `${record.address} - ${record.zip} - ${record.city}` : ''
            },
            {
                title: t('phone'),
                textRender: (record) => record.phone
            },
            {
                title: t('email'),
                textRender: (record) => record.email
            },
            {
                title: t('contacts'),
                textRender: (record) => record.contacts.length.toString()
            },
            {
                title: t('functions'),
                size: "min-content",
                customRender: (record) => <FlexRow className={"h-100 clickable"} justify={"center"} onClick={() => navigate(`/customers/edit/${record.id}`)} ><DotsIcon /></FlexRow>
            }
        ]} keyExtractor={(record) => record.id.toString()} imageExtractor={record => record.image ? [record.image] : []} />
    )
}

export default CustomersList;
