import { useForm } from "react-hook-form";
import { IUserDto } from "Apis/Models/IUserDto";
import ControlledInput from "Components/Input";
import { useTranslation } from "react-i18next";
import Button from "Components/Button";
import { useMutateUser } from "Apis/UsersApi";
import Space from "Components/Space";
import FlexRow from "Components/FlexRow";
import { useEffect } from "react";
import {useAuthProvider} from "Providers/AuthProvider";

interface IProps {
    user?: IUserDto;
    onSubmit?: (data: IUserDto) => void;
}
const UsersForm = (props: IProps) => {
    
    const { t } = useTranslation();

    const { resetPassword } = useAuthProvider();
    
    const { control, handleSubmit, reset } = useForm<IUserDto>()
    const { mutateAsync, isLoading } = useMutateUser();
    
    useEffect(() => {
        if (props.user) {
            reset(props.user);
        }
    }, [reset, props.user])
    
    const onSubmit = async(data: IUserDto) => {
        const isNewUser = !data.id;

        await mutateAsync(data);

        if (isNewUser) {
            await resetPassword(data)
        }
        
        if (props.onSubmit) {
            props.onSubmit(data);
        }

        
        reset();
    }
    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Space direction={"vertical"} size={"large"}>
                <ControlledInput rules={{required: true}} name={"name"} control={control} placeholder={t('name')} />
                <ControlledInput rules={{required: true}} name={"email"} control={control} placeholder={t('email')} />
                
                <FlexRow justify={"end"}>
                    <Button buttonType={"submit"} type={"primary"} text={t('save')} loading={isLoading} />
                </FlexRow>
            </Space>
        </form>
    )
}

export default UsersForm
