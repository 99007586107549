import * as React from "react"
import { SVGProps } from "react"

const BuildingIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} {...props} viewBox="0 0 14 14">
        <g data-name="010-city-building" fill={props.fill ?? "#ccc3c3"}>
            <path
                data-name="Path 1355"
                d="M0 3.645v9.943a.351.351 0 0 0 .35.35h3.377a.351.351 0 0 0 .35-.35V3.015a.352.352 0 0 0-.42-.35l-3.377.63a.39.39 0 0 0-.28.35Zm2.573 9.118h-1.05a.351.351 0 0 1-.35-.35v-.472a.351.351 0 0 1 .35-.35h1.05a.351.351 0 0 1 .35.35v.472a.351.351 0 0 1-.35.35Zm0-2.327h-1.05a.351.351 0 0 1-.35-.35v-.472a.351.351 0 0 1 .35-.35h1.05a.351.351 0 0 1 .35.35v.474a.351.351 0 0 1-.35.347Zm0-2.327h-1.05a.351.351 0 0 1-.35-.35v-.471a.351.351 0 0 1 .35-.35h1.05a.351.351 0 0 1 .35.35v.473a.351.351 0 0 1-.35.347Zm0-2.345h-1.05a.351.351 0 0 1-.35-.35v-.472a.351.351 0 0 1 .35-.35h1.05a.351.351 0 0 1 .35.35v.472a.351.351 0 0 1-.35.349Z"
            />
            <path
                data-name="Path 1356"
                d="M5.25.355v13.23a.351.351 0 0 0 .35.35h8.05a.351.351 0 0 0 .35-.35V1.86a.358.358 0 0 0-.28-.35L5.67.005a.361.361 0 0 0-.42.35ZM8.4 12.763H7.35a.351.351 0 0 1-.35-.35v-.472a.351.351 0 0 1 .35-.35H8.4a.351.351 0 0 1 .35.35v.472a.351.351 0 0 1-.35.349Zm0-2.328H7.35a.351.351 0 0 1-.35-.35v-.472a.351.351 0 0 1 .35-.35H8.4a.351.351 0 0 1 .35.35v.472a.351.351 0 0 1-.35.35Zm0-2.328H7.35a.351.351 0 0 1-.35-.35v-.472a.351.351 0 0 1 .35-.35H8.4a.351.351 0 0 1 .35.35v.472a.351.351 0 0 1-.35.35Zm0-2.345H7.35a.351.351 0 0 1-.35-.35v-.473a.351.351 0 0 1 .35-.35H8.4a.351.351 0 0 1 .35.35v.473a.351.351 0 0 1-.35.35Zm0-2.328H7.35a.351.351 0 0 1-.35-.35v-.473a.351.351 0 0 1 .35-.35H8.4a.351.351 0 0 1 .35.35v.473a.351.351 0 0 1-.35.351Zm3.5 9.328h-1.05a.351.351 0 0 1-.35-.35v-.472a.351.351 0 0 1 .35-.35h1.05a.351.351 0 0 1 .35.35v.472a.351.351 0 0 1-.35.35Zm0-2.328h-1.05a.351.351 0 0 1-.35-.35v-.472a.351.351 0 0 1 .35-.35h1.05a.351.351 0 0 1 .35.35v.472a.351.351 0 0 1-.35.351Zm0-2.328h-1.05a.351.351 0 0 1-.35-.35v-.472a.351.351 0 0 1 .35-.35h1.05a.351.351 0 0 1 .35.35v.472a.351.351 0 0 1-.35.351Zm0-2.345h-1.05a.351.351 0 0 1-.35-.35v-.473a.351.351 0 0 1 .35-.35h1.05a.351.351 0 0 1 .35.35v.473a.351.351 0 0 1-.35.351Z"
            />
        </g>
    </svg>
)

export default BuildingIcon
