import { useMutation, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import {IOrderDto} from "Apis/Models/IOrderDto";
import {ORDERS_CACHE_KEY} from "./OrderApi";

export const useOrderAccepted = () => {
    const queryClient = useQueryClient();

    return useMutation(async (order: IOrderDto) => {
        return await request({
            url: `/orders/${order.id}/status/accepted`,
            method: 'PUT',
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(ORDERS_CACHE_KEY)
        }
    })
}

export const useOrderProduced = () => {
    const queryClient = useQueryClient();

    return useMutation(async (order: IOrderDto) => {
        return await request({
            url: `/orders/${order.id}/status/produced`,
            method: 'PUT',
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(ORDERS_CACHE_KEY)
        }
    })
}

export const useOrderDelivered = () => {
    const queryClient = useQueryClient();

    return useMutation(async (order: IOrderDto) => {
        return await request({
            url: `/orders/${order.id}/status/delivered`,
            method: 'PUT',
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(ORDERS_CACHE_KEY)
        }
    })
}

export const useOrderFinished = () => {
    const queryClient = useQueryClient();

    return useMutation(async (order: IOrderDto) => {
        return await request({
            url: `/orders/${order.id}/status/finished`,
            method: 'PUT',
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(ORDERS_CACHE_KEY)
        }
    })
}


export const useOrderCancelled = () => {
    const queryClient = useQueryClient();

    return useMutation(async (order: IOrderDto) => {
        return await request({
            url: `/orders/${order.id}/status/cancelled`,
            method: 'PUT',
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(ORDERS_CACHE_KEY)
        }
    })
}