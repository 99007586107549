import styles from './Option.module.scss';
import { useContext } from "react";
import { SelectContext, SelectContextType } from "./Select";
import CheckmarkIcon from "Assets/SVG/CheckmarkIcon";

interface Props {
    value: any;
    label: string;
}

const Option = ({label, value}: Props) => {
    
    const context = useContext<SelectContextType>(SelectContext);
    
    const isSelected = context.isValueSelected(value);
    
    const onChange = (e: any) => {
        e.stopPropagation();
        context.onChange(value, !isSelected);
    }
    
    return (
        <div className={`${styles.label} ${(isSelected || context.focusedKey === value) && styles.selected}`} onClick={onChange}>
            {label}
            {isSelected && (
                <CheckmarkIcon />
            )}
        </div>
    )
}

Option.displayName = 'Option'

export default Option
