import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PageLayout from "Layouts/PageLayout";
import Button from "Components/Button";
import { usePrintPlacements } from "Apis/PrintPlacementApi";
import { PrintPlacementList } from "Features/Prints";
import PrintIcon from "Assets/SVG/PrintIcon";
import Pagination from "../../../Components/Pagination";
import useQueryParams from "../../../Hooks/UseQueryParams";

const Index = () => {
    
    const { t } = useTranslation();

    const [ filter, setFilter ] = useQueryParams();

    const { data: printPlacements, isLoading } = usePrintPlacements(filter);
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={t('printPlacements')} icon={<PrintIcon />} header={(
            <Button prefix={<PrintIcon fill={"#FFF"} />} text={t('createNewPrintPlacement')} type={"primary"} onClick={() => navigate("new")} />
        )}>
            <>
                <PrintPlacementList printPlacements={printPlacements?.items} isLoading={isLoading} />

                <Pagination paginatedList={printPlacements} onPageChange={newPage => setFilter({page: newPage})} />
            </>
        </PageLayout>
    )
}

export default Index
