import {IPaginatedList} from "../../Apis/Models/IPaginatedList";
import styles from './Pagination.module.scss';
import CaretIcon from "../../Assets/SVG/CaretIcon";
import React from "react";
import FlexRow from "../FlexRow";
interface IProps {
    paginatedList?: IPaginatedList<any>;
    onPageChange: (newPage: number) => void
}
const Pagination = (props: IProps) => {

    const { paginatedList, onPageChange } = props;

    if (!paginatedList) {
        return (
            <></>
        )
    }

    const { page, totalPages } = paginatedList;

    const pageItem = (pageNumber: number) => {
        return (
            <div className={`${styles.pageItem} ${page === pageNumber && styles.active}`} onClick={() => onPageChange(pageNumber)}>
                {pageNumber + 1}
            </div>
        )
    }

    const pages = Array.from(Array(totalPages).keys());

    if (totalPages === 1) {
        return (
            <div className={styles.container}>
                {pageItem(0)}
            </div>
        )
    }

    return (
        <FlexRow justify={"center"}>
            <div className={styles.container}>
                <CaretIcon style={{transform: 'rotate(180deg)'}} />
                {pages.map((pageIndex, index) => (
                    <React.Fragment key={pageIndex}>
                        {pageItem(pageIndex)}
                    </React.Fragment>
                ))}
                <CaretIcon />
            </div>
        </FlexRow>
    )
}

export default Pagination