import { IOrderDto } from "Apis/Models/IOrderDto";
import { useTranslation } from "react-i18next";
import styles from './OrderProjectDetails.module.scss';
import EmailIcon from "Assets/SVG/EmailIcon";
import PhoneIcon from "Assets/SVG/PhoneIcon";
import { cloneElement, ReactElement } from "react";

interface IProps {
    order: IOrderDto
}
const OrderProjectDetails = (props: IProps) => {
    
    const { order } = props;
    
    const { t } = useTranslation();
    
    const fadedIcon = (icon: ReactElement) => cloneElement(icon, {fill: "#ccc3c3", height: 20, width: 20})

    if (order.project == null) {
        return <></>
    }
    
    return (
        <div className={styles.container}>
            <div className={styles.projectImage} >
                <img height={100} width={100} src={order.project.image?.url} alt={order.project.image?.fileName}/>
            </div>
            <div>
                <div className={styles.header}>
                    {t('project')}
                </div>
                <div className={styles.projectName}>
                    {order.project.name}
                </div>
            </div>
            <div>
                <div className={styles.header}>
                    {t('project')} {t('contact')}
                </div>
                <div className={styles.centerHorizontal}>
                    {fadedIcon(<EmailIcon />)}
                    {order.project.email}
                </div>
                <div className={styles.centerHorizontal}>
                    {fadedIcon(<PhoneIcon />)}
                    {order.project.phone}
                </div>
            </div>
            <div>

            </div>
        </div>
    )
}

export default OrderProjectDetails
