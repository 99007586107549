import {auth} from "FirebaseSetup/auth";
import axios from 'axios';
import config from "Config";
import {IQueryParams} from "./Models/IQueryParams";

export const request = async <T>(options: any): Promise<T> => {

    const token = await auth.currentUser?.getIdToken() ?? '';

    let headers = {
        Authorization: `Bearer ${token}`
    };

    const client = axios.create({
        baseURL: config.baseUrl,
        headers
    });

    if (options['queryParams']) {
        options.url = addQueryParams(options.url, options['queryParams'])
    }


    const onSuccess = (response: any) => {
        return response.data;
    };
    
    const onError = (error: any) => {
        console.log('Request Failed:', error.config);
        
        if (error.response) {
            // Request was made but server responded with something
            // other than 2xx
            console.log('Status:', error.response.status);
            console.log('Data:', error.response.data);
            console.log('Headers:', error.response.headers);
            
        } else {
            // Something else happened while setting up the request
            // triggered the error
            console.log('Error Message:', error.message);
        }
        
        const ignoreStatusCodes = [400, 402, 404, 413] //BadRequest, Payment requried, Not found, Content Too Large
        const ignoredMessages = ["Request aborted", "Network Error", "timeout exceeded"];
        
        if (!ignoreStatusCodes.includes(error?.response?.status) && !ignoredMessages.includes(error.message)) {
            log(error.message, error.stack, {
                method: error.config.method,
                url: error.config.url
            }).then();
        }
        
        return Promise.reject(error.response || error.message);
    };


    return client(options)
        .then(onSuccess)
        .catch(onError);
}


export const log = async (errorMessage: string, errorStack: string, properties: any = null, level: string = 'Error') => {
    const token = await auth.currentUser?.getIdToken() ?? '';
    
    if (window.location.host.includes("localhost")) {
        return;
    }
    
    let headers = {
        Authorization: `Bearer ${token}`
    };
    
    const client = axios.create({
        baseURL: config.baseUrl + '/logging',
        headers
    });
    
    const logEvent = {
        serviceName: 'AnotherSystem.Frontend',
        messageTemplate: errorMessage || 'No error message',
        timestamp: new Date(),
        properties: properties,
        level: level,
        error: {
            message: errorMessage || 'No error message',
            stacktrace: errorStack || 'No error stack',
        },
    };
    
    const options: any = {
        method: 'POST',
        data: {
            ...logEvent
        }
    }
    
    await client(options).then();
}

const addQueryParams = (url: string, queryParams: Partial<IQueryParams> | null) => {
    if (!queryParams) {
        return url;
    }

    const params = new URLSearchParams();

    Object.keys(queryParams).forEach(key => {
        if (queryParams[key] !== undefined && queryParams[key] !== null) {

            if (Array.isArray((queryParams[key]))) {
                let valueArray = queryParams[key] as any[]
                valueArray.forEach((value: any) => {
                    params.append(key, value!.toString());
                })
            } else {
                params.append(key, queryParams[key]!.toString());
            }
        }
    });

    return `${url}?${params.toString()}`
}
