import { useTranslation } from "react-i18next";
import List from "Components/List/List";
import {IProductDto} from "Apis/Models/IProductDto";
import FlexRow from "Components/FlexRow";
import DotsIcon from "Assets/SVG/DotsIcon";
import {useNavigate} from "react-router-dom";
import {formatNumber} from "../../../Utils/NumberUtils";
import CopyIcon from "../../../Assets/SVG/CopyIcon";
import CheckmarkIcon from "../../../Assets/SVG/CheckmarkIcon";
import XIcon from "../../../Assets/SVG/XIcon";
import ExclamationMarkIcon from "../../../Assets/SVG/ExclamationMarkIcon";

interface IProps {
    products?: IProductDto[];
    isLoading: boolean;
    allowSelect?: boolean;
    onSelectChange?: (selectedRows: IProductDto[]) => void;
}
const ProductsList = (props: IProps) => {
    
    const { t } = useTranslation();
    
    const { products, isLoading } = props;

    const navigate = useNavigate();
    
    return (
        <List<IProductDto> allowSelect={props.allowSelect} data={products} loading={isLoading} onSelectChange={props.onSelectChange} columns={[
            {
                title: t('name'),
                textRender: (record) => record.name
            },
            {
                title: t('project'),
                textRender: (record) => record.project?.name ?? ''
            },
            {
                title: t('SKU'),
                textRender: (record) => record.sku
            },
            {
                title: t('inventoryItem'),
                textRender: (record) => record.inventoryItem?.name ?? ''
            },
            {
                title: t('designName'),
                textRender: (record) => record.design?.name ?? ''
            },
            {
                title: t('unitSalesPrice'),
                textRender: (record) => `${formatNumber(record.totalSalesPrice)} DKK`
            },
            {
                title: t('unitCostPrice'),
                textRender: (record) => `${formatNumber(record.totalCostPrice)} DKK`
            },
            {
                title: t('status'),
                size: "min-content",
                customRender: (record) => (
                    <FlexRow align={"center"} style={{gap: 5}}>
                        {record.designHasBeenUpdated && <ExclamationMarkIcon />}
                        {record.readyForUse && !record.unlisted ? <CheckmarkIcon /> : <XIcon />}
                        {record.unlisted ? t('unlisted') : record.readyForUse ? t('filled') : t('draft')}
                    </FlexRow>
                )
            },
            {
                title: t('functions'),
                size: "min-content",
                customRender: (record) => (
                    <FlexRow justify={"center"} align={"center"}>
                        <FlexRow className={"clickable"} justify={"center"} align={"center"} onClick={() => navigate(`/products/clone/${record.id}`)} style={{paddingRight:15}}>
                            <CopyIcon width={30} height={30} />
                        </FlexRow>
                        <FlexRow className={"clickable"} justify={"center"} align={"center"} onClick={() => navigate(`/products/edit/${record.id}`)}>
                            <DotsIcon />
                        </FlexRow>
                    </FlexRow>
                )
            }
        ]} keyExtractor={(record) => record.id.toString()} imageExtractor={record => record.productImages?.slice(0, 4)} />
    )
}

export default ProductsList
