import { ReactNode } from "react";
import styles from './BoxLayout.module.scss';


interface IProps {
    children: ReactNode;
}
const BoxLayout = (props: IProps) => {
    
    const { children } = props;
    
    return (
        <div className={styles.container}>
            {children}
        </div>
    )
}

export default BoxLayout
