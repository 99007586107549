import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { CustomersList } from "Features/Customers";
import Button from "Components/Button";
import { useNavigate } from "react-router-dom";
import { useCustomers } from "Apis/CustomerApi";
import HandsIcon from "Assets/SVG/HandsIcon";
import Pagination from "../../Components/Pagination";
import useQueryParams from "../../Hooks/UseQueryParams";
import CustomerListFilter from "../../Features/Customers/Components/CustomerListFilter";
import Separator from "../../Components/Separator";

const Index = () => {
    
    const { t } = useTranslation();

    const [ filter, setFilter ] = useQueryParams();

    const { data: customers, isLoading } = useCustomers(filter);
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={t('customers')} icon={<HandsIcon />} header={(
            <Button prefix={<HandsIcon fill={"#FFF"} />} text={t('createNewCustomer')} type={"primary"} onClick={() => navigate("new")} />
        )}>
            <>
                <CustomerListFilter filterUpdated={(fields, reset) => setFilter(fields, reset)} currentValue={filter} />

                <Separator style={{margin: '30px 0'}} />

                <CustomersList customers={customers?.items} isLoading={isLoading} />

                <Pagination paginatedList={customers} onPageChange={newPage => setFilter({page: newPage})} />
            </>
        </PageLayout>
    )
}

export default Index
