import * as React from "react"
import { SVGProps } from "react"

const CommentIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={18.667} {...props} viewBox={"0 0 20 18.667"}>
        <path
            data-name="Path 1351"
            d="M18 0H2a2 2 0 0 0-2 2v10.667a2 2 0 0 0 2 2h2V18a.667.667 0 0 0 1.083.521L9.9 14.667H18a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-8 9.333H4.667a.667.667 0 1 1 0-1.333H10a.667.667 0 1 1 0 1.333Zm5.333-2.667H4.667a.667.667 0 0 1 0-1.333h10.666a.667.667 0 1 1 0 1.333Z"
            fill={props.fill ?? "#6d4d4d"}
        />
    </svg>
)

export default CommentIcon
