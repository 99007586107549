import {IOrderLineDto} from "../../../Apis/Models/IOrderDto";

export function getOrderLineUnitSalesPrice(orderLine: IOrderLineDto) {
    return (orderLine.product.productType === 'Discount' ? -orderLine.discountAsAmount : orderLine.unitSalesPriceInclDiscount) ?? 0;
}

export function getOrderLineTotal(orderLine: IOrderLineDto) {
    return orderLine.quantity * (getOrderLineUnitSalesPrice(orderLine));
}

export function getOrderLineTotalCost(orderLine: IOrderLineDto) {
    return orderLine.quantity * (orderLine.product.totalCostPrice ?? 0);
}

export function getOrderLinesTotal(orderLines: IOrderLineDto[]) {
    return orderLines.reduce((a, b) => a + (getOrderLineTotal(b)), 0)
}

export function getOrderLinesTotalInclVat(orderLines: IOrderLineDto[]) {
    return getOrderLinesTotal(orderLines) + getOrderLinesTotalVat(orderLines);
}

export function getOrderLinesTotalVat(orderLines: IOrderLineDto[]) {
    return orderLines.reduce((a, b) => a + (b.quantity * (getOrderLineUnitSalesPrice(b) ?? 0) * 0.25), 0)
}