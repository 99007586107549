import DotsIcon from "Assets/SVG/DotsIcon";
import List from "Components/List/List";
import { useNavigate } from "react-router-dom";
import FlexRow from "Components/FlexRow";
import { IProjectDto } from "Apis/Models/IProjectDto";
import { useTranslation } from "react-i18next";

interface IProps {
    projects?: IProjectDto[];
    isLoading: boolean;
}
const ProjectsList = (props: IProps) => {
    
    const { t } = useTranslation();
    
    const { projects, isLoading } = props;
    
    const navigate = useNavigate();
    
    return (
        <List<IProjectDto> data={projects} loading={isLoading} columns={[
            {
                title: t('name'),
                textRender: (record) => record.name
            },
            {
                title: t('address'),
                textRender: (record) => record.address ? `${record.address} - ${record.zip} - ${record.city}` : ''
            },
            {
                title: t('phone'),
                textRender: (record) => record.phone
            },
            {
                title: t('email'),
                textRender: (record) => record.email
            },
            {
                title: t('referral'),
                textRender: (record) => record.referral?.name
            },
            {
                title: t('functions'),
                size: "min-content",
                customRender: (record) => <FlexRow className={"h-100 clickable"} justify={"center"} onClick={() => navigate(`/projects/edit/${record.id}`)} ><DotsIcon /></FlexRow>
            }
        ]} keyExtractor={(record) => record.id.toString()} imageExtractor={record =>  record.image ? [record.image] : []} />
    )
}

export default ProjectsList;
