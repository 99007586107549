import { Navigate } from "react-router-dom";
import { useAuthProvider } from "Providers/AuthProvider";
import { ReactElement } from "react";

interface IProps {
    children: ReactElement;
}
const RedirectIfLoggedIn = ({children}: IProps) => {
    
    const user = useAuthProvider();
    
    return user.firebaseUser === null ? children : <Navigate to={"/"} />;
}

export default RedirectIfLoggedIn;
