import { useForm } from "react-hook-form";
import ControlledInput from "Components/Input";
import { useTranslation } from "react-i18next";
import Button from "Components/Button";
import Space from "Components/Space";
import FlexRow from "Components/FlexRow";
import { useEffect, useState } from "react";
import { useMutateProject } from "Apis/ProjectApi";
import { IProjectDto } from "Apis/Models/IProjectDto";
import FileUpload from "../../../Components/FileUpload/FileUpload";
import styles from './ProjectForm.module.scss';
import { IFileInformationDto } from "Apis/Models/IFileDto";
import Loader from "../../../Assets/SVG/Loader";
import Select from "../../../Components/Select";
import ControlledSelect from "../../../Components/Select/ControlledSelect";
import {useCustomers} from "../../../Apis/CustomerApi";
import useFilteredList from "../../../Hooks/UseFilteredList";
import {usePartners} from "../../../Apis/PartnerApi";

interface IProps {
    project?: IProjectDto;
    onSubmit?: (data: IProjectDto) => void;
}
const ProjectForm = (props: IProps) => {
    
    const { t } = useTranslation();

    const { data: customers } = useCustomers({includeAll: true});
    const { data: partners } = usePartners({includeAll: true});

    const { control, handleSubmit, reset } = useForm<IProjectDto>();
    const [file, setFile] = useState<IFileInformationDto>();
    const { mutateAsync, isLoading } = useMutateProject();

    const [ filteredCustomers, setCustomerFilter ] = useFilteredList(customers?.items ?? [], x => [x.name, x.vatNumber]);
    const [ filteredReferrals, setReferralFilter ] = useFilteredList(partners?.items ?? [], x => [x.name, x.vatNumber]);

    useEffect(() => {
        if (props.project) {
            reset(props.project);
            if (props.project.image) {
                setFile(props.project.image);
            }
        }
    }, [reset, props.project])
    
    const onSubmit = async(data: IProjectDto) => {
        await mutateAsync({ ...data, image: file });
        
        if (props.onSubmit) {
            props.onSubmit(data);
        }
        
        reset();
    }
    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Space direction={"vertical"} size={"large"}>
                
                <div className={styles.imageRow}>
                    <FileUpload callback={setFile} accept={['.jpeg', '.jpg', '.png']} render={loading => (
                        <div className={styles.fileUpload}>
                            {loading ? (<Loader color={"red"} height={50} width={50} />) : (<>
                                {!!file ? (
                                    <img height={150} width={150} src={file.url} alt={file.fileName} />
                                ) : (
                                    <>
                                        {t('upload')}
                                    </>
                                )}
                            </>)}
                        </div>
                    )} />
                    <ControlledInput rules={{required: true}} name={"name"} control={control} placeholder={t('name')} />
                </div>
                
                <ControlledInput rules={{required: true}} name={"initials"} control={control} placeholder={t('initials')} />

                <ControlledSelect rules={{required: true}} control={control} placeholder={t('customer')} name={"customerId"} onFilter={setCustomerFilter}>
                    {filteredCustomers?.map(x => <Select.Option key={x.id} label={x.name} value={x.id} />)}
                </ControlledSelect>

                <ControlledInput name={"address"} control={control} placeholder={t('address')} />
                <ControlledInput name={"zip"} control={control} placeholder={t('zip')} />
                <ControlledInput name={"city"} control={control} placeholder={t('city')} />
                <ControlledInput name={"email"} control={control} placeholder={t('email')} />
                <ControlledInput name={"phone"} control={control} placeholder={t('phone')} />

                <ControlledSelect control={control} placeholder={t('referral')} name={"referralId"} onFilter={setReferralFilter}>
                    {filteredReferrals?.map(x => <Select.Option key={x.id} label={x.name} value={x.id} />)}
                </ControlledSelect>
                
                <FlexRow justify={"end"}>
                    <Button buttonType={"submit"} type={"primary"} text={t('save')} loading={isLoading} />
                </FlexRow>
            </Space>
        </form>
    )
}

export default ProjectForm
