import { useTranslation } from "react-i18next";
import { useAuthProvider } from "Providers/AuthProvider";
import { useForm } from "react-hook-form";
import Space from "Components/Space";
import Input from "Components/Input";
import FlexRow from "Components/FlexRow";
import Button from "Components/Button";
import {useNavigate} from "react-router-dom";

interface EmailField {
    email: string
}
const ResetPasswordForm = () => {
    
    const { t } = useTranslation();
    
    const { resetPassword } = useAuthProvider();
    
    const { control, handleSubmit } = useForm<EmailField>();

    const navigate = useNavigate();
    
    const login = async (data: EmailField) => {
        await resetPassword(data);

        navigate('/signin');
    }
    
    return (
        <form onSubmit={handleSubmit(login)}>
            <Space direction={"vertical"} size={"large"}>
                <Input placeholder={t('email')} name={"email"} control={control} />
                
                <FlexRow justify={"end"}>
                    <Button buttonType={"submit"} text={t('auth:resetpassword')} type={"primary"} />
                </FlexRow>
            </Space>
        </form>
    )
}

export default ResetPasswordForm
