import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import { IPaginatedList } from "Apis/Models/IPaginatedList";
import { IUserDto } from "./Models/IUserDto";
import {IQueryParams} from "./Models/IQueryParams";

export const USERS_CACHE_KEY = 'USERS_CACHE_KEY'
export const useUsers = (queryable: Partial<IQueryParams> | null = null) => {
    return useQuery<IPaginatedList<IUserDto>>([USERS_CACHE_KEY, queryable], async () => {
        return await request<IPaginatedList<IUserDto>>({
            url: `/users`,
            method: 'GET',
            queryParams: queryable
        })
    });
}

export const useUser = (id: number) => {
    return useQuery<IUserDto>([USERS_CACHE_KEY, id], async () => {
        return await request<IUserDto>({
            url: `/users/${id}`,
            method: 'GET',
        })
    });
}

export const useMutateUser = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (user: IUserDto): Promise<IUserDto> => {
        if (!user.id) {
            return await request<IUserDto>({
                url: `/users`,
                method: 'POST',
                data: user
            })
        } else {
            return await request<IUserDto>({
                url: `/users/${user.id}`,
                method: 'PUT',
                data: user
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(USERS_CACHE_KEY)
        }
    })
}

export const useMutateApiUser = () => {

    const queryClient = useQueryClient();

    return useMutation(async (user: IUserDto): Promise<IUserDto> => {
        if (!user.id) {
            return await request<IUserDto>({
                url: `/users/api`,
                method: 'POST',
                data: user
            })
        } else {
            return await request<IUserDto>({
                url: `/users/${user.id}`,
                method: 'PUT',
                data: user
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(USERS_CACHE_KEY)
        }
    })
}