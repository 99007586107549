import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {IQueryParam} from "../../../Apis/Models/IQueryParams";
import {Input} from "../../../Components/Input";
import {useCustomers} from "../../../Apis/CustomerApi";
import useFilteredList from "../../../Hooks/UseFilteredList";
import Label from "../../../Components/Label";
import Select from "../../../Components/Select";
import {usePartners} from "../../../Apis/PartnerApi";

interface Fields {
    [key: string]: IQueryParam;
}
interface IProps {
    filterUpdated: (params: Fields, resetParams: boolean) => void;
    currentValue: Fields;
}
const ProjectListFilter = (props: IProps) => {

    const { filterUpdated, currentValue } = props;
    const { t } = useTranslation();

    const { data: customers } = useCustomers({ includeAll: true });
    const { data: partners } = usePartners({ includeAll: true });

    const [ query, setQuery ] = useState<Fields>(currentValue);

    useEffect(() => {
        setQuery(currentValue);
    }, [currentValue]);

    const [ filteredCustomers, setCustomerFilter, customerFilterValue ] = useFilteredList(customers?.items ?? [], x => [x.name]);
    const [ filteredPartners, setPartnerFilter, parterFilterValue ] = useFilteredList(partners?.items ?? [], x => [x.name]);


    const setQueryField = (fieldName: 'search' | 'customerId' | 'referralId', value: IQueryParam) => {
        const newValue = {...query, [fieldName]: value }
        setQuery(newValue);
        filterUpdated(newValue, true);
    }

    return (
        <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 20}}>
            <div>
                <Input name={"search"} value={query['search'] ?? ''} onChange={value => setQueryField('search', value)} placeholder={t('search')} />
            </div>

            <div>
                <Label placeholder={t('customer')} />
                <Select name={"customerId"} value={query['customerId']} onChange={value => setQueryField('customerId', value)} onFilter={setCustomerFilter}>
                    {!customerFilterValue && (
                        <Select.Option value={undefined} label={t('all')} />
                    )}
                    {filteredCustomers?.map(customer => <Select.Option key={customer.id} value={customer.id} label={customer.name} />)}
                </Select>
            </div>

            <div>
                <Label placeholder={t('referral')} />
                <Select name={"referralId"} value={query['referralId']} onChange={value => setQueryField('referralId', value)} onFilter={setPartnerFilter}>
                    {!parterFilterValue && (
                        <Select.Option value={undefined} label={t('all')} />
                    )}
                    {filteredPartners?.map(partner => <Select.Option key={partner.id} value={partner.id} label={partner.name} />)}
                </Select>
            </div>
        </div>
    )

}

export default ProjectListFilter