import {useTranslation} from "react-i18next";
import React, {useEffect, useMemo, useState} from "react";
import Label from "../../../Components/Label";
import Select from "../../../Components/Select";
import {IQueryParam} from "../../../Apis/Models/IQueryParams";
import useFilteredList from "../../../Hooks/UseFilteredList";
import {Input} from "../../../Components/Input";
import {useProjects} from "../../../Apis/ProjectApi";

interface Fields {
    [key: string]: IQueryParam;
}
interface IProps {
    filterUpdated: (params: Fields, resetParams: boolean) => void;
    currentValue: Fields;
}
const ProductListFilter = (props: IProps) => {

    const { filterUpdated, currentValue } = props;
    
    const { t } = useTranslation();

    const readyForUse = useMemo(() => [
        { value: undefined,label: t('productReadyForUse.all')},
        { value: "false" ,label: t('productReadyForUse.draft')},
        { value: "true",label: t('productReadyForUse.filled')},
    ], [t]);

    const { data: projects } = useProjects({ includeAll: true });

    const [ query, setQuery ] = useState<Fields>({
        readyForUse: undefined
    });

    useEffect(() => {
        setQuery(currentValue);
    }, [currentValue]);

    const [ filteredReadyForUse, setFilteredReadyForUse ] = useFilteredList(readyForUse, x => [x.label]);
    const [ filteredProjects, setProjectFilter, projectFilterValue ] = useFilteredList(projects?.items ?? [], x => [x.name]);
    
    const setQueryField = (fieldName: 'readyForUse' | 'search' | 'projectId', value: IQueryParam) => {
        let newValue = {...query, [fieldName]: value }

        if (fieldName === 'projectId' && !!value) {
            newValue = {...newValue, productType: 'Product'}
        } else {
            delete newValue['productType'];
        }

        setQuery(newValue);
        filterUpdated(newValue, false);
    }

    return (
        <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 20}}>
            <div>
                <Input name={"search"} value={query['search'] ?? ''} onChange={value => setQueryField('search', value)} placeholder={t('productSearchInputPlaceholder')} />
            </div>

            <div>
                <Label placeholder={t('project')} />
                <Select name={"projectId"} value={query['projectId']} onChange={value => setQueryField('projectId', value)} onFilter={setProjectFilter}>
                    {!projectFilterValue && (
                        <Select.Option value={undefined} label={t('all')} />
                    )}
                    {filteredProjects?.map(project => <Select.Option key={project.id} value={project.id} label={project.name} />)}
                </Select>
            </div>

            <div>
                <Label placeholder={t('Status')} />
                <Select name={"readyForUse"} value={query['readyForUse']} onChange={value => setQueryField('readyForUse', value)} onFilter={setFilteredReadyForUse}>
                    {filteredReadyForUse?.map((status, index) => <Select.Option key={index} value={status.value} label={status.label} />)}
                </Select>
            </div>
        </div>
    )

}

export default ProductListFilter