import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {useTag} from "../../Apis/TagApi";
import { TagForm } from "Features/Tags";
import TagIcon from "Assets/SVG/TagIcon";

const Edit = () => {
    
    const { t } = useTranslation();
    
    const { id } = useParams();
    
    const { data: tag } = useTag(parseInt(id!));
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={t('updateTag')} icon={<TagIcon />} includeBox>
            <TagForm tag={tag} onSubmit={_ => navigate(-1)} />
        </PageLayout>
    )
}

export default Edit;
