import styles from "./PageHeader.module.scss";
import {cloneElement, ReactElement} from "react";

interface IProps {
    title: string;
    icon: ReactElement;
    header?: ReactElement;
}
const PageHeader = (props: IProps) => {

    const { title, icon, header } = props;

    return (
        <div className={styles.header}>
            <div className={styles.icon}>
                {cloneElement(icon, {width: 50, height: 50})}
            </div>
            <div className={styles.titleContainer}>
                <div className={styles.title}>
                    {title}
                </div>
                <div className={styles.subTitle}>
                    Let’s rock & be awesome! 🤘🏽
                </div>
            </div>
            <div className={styles.headerContent}>
                {header}
            </div>
        </div>
    )

}

export default PageHeader