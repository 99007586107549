import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { usePrintPlacement } from "Apis/PrintPlacementApi";
import { PrintPlacementForm } from "Features/Prints";
import PrintIcon from "Assets/SVG/PrintIcon";

const Edit = () => {
    
    const { t } = useTranslation();
    
    const { id } = useParams();
    
    const { data: printPlacement } = usePrintPlacement(parseInt(id!));
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={t('updatePrintPlacement')} icon={<PrintIcon />} includeBox>
            <PrintPlacementForm printPlacement={printPlacement} onSubmit={_ => navigate(-1)} />
        </PageLayout>
    )
}

export default Edit;
