import * as React from "react"
import { SVGProps } from "react"

const PhoneIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={16.466} {...props} viewBox="0 0 20 16.466">
        <g data-name="004-old-typical-phone">
            <g data-name="Group 1405" fill={props.fill ?? "#ccc3c3"}>
                <path
                    data-name="Path 1347"
                    d="M10.11 3.008c3.37.033 5.481-.177 5.766 3.559h4.109c0-5.786-5.047-6.566-9.983-6.566S.02.78.02 6.566h4.078c.315-3.808 2.67-3.591 6.012-3.558Z"
                />
                <path
                    data-name="Path 1348"
                    d="M2.058 8.427c1 0 1.834.06 2.019-.925a2.513 2.513 0 0 0 .039-.461H0c0 1.465.922 1.386 2.058 1.386Z"
                />
                <path
                    data-name="Path 1349"
                    d="M15.903 7.041h-.039a2.2 2.2 0 0 0 .044.461c.2.908 1.027.852 2.024.852 1.142 0 2.068.075 2.068-1.313Z"
                />
                <path
                    data-name="Path 1350"
                    d="M13.895 6.044v-.582c0-.26-.3-.277-.67-.277h-.6c-.37 0-.67.016-.67.277v.89H7.671v-.89c0-.26-.3-.277-.67-.277h-.606c-.37 0-.67.016-.67.277v1.09a43.126 43.126 0 0 0-4.281 5.847v3.484a.584.584 0 0 0 .584.584H17.59a.584.584 0 0 0 .584-.584v-3.5a43.9 43.9 0 0 0-4.279-5.83ZM7.46 12.867a.567.567 0 1 1 .567-.567.567.567 0 0 1-.567.567Zm0-1.945a.567.567 0 1 1 .567-.567.567.567 0 0 1-.567.567Zm0-1.945a.567.567 0 1 1 .57-.563.567.567 0 0 1-.57.564Zm2.334 3.89a.567.567 0 1 1 .567-.567.567.567 0 0 1-.567.567Zm0-1.945a.567.567 0 1 1 .567-.567.567.567 0 0 1-.567.567Zm0-1.945a.567.567 0 1 1 .567-.567.567.567 0 0 1-.567.568Zm2.334 3.89a.567.567 0 1 1 .567-.567.567.567 0 0 1-.565.567Zm0-1.945a.567.567 0 1 1 .567-.567.567.567 0 0 1-.565.567Zm0-1.945a.567.567 0 1 1 .567-.567.567.567 0 0 1-.565.568Z"
                />
            </g>
        </g>
    </svg>
)

export default PhoneIcon
