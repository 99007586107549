import styles from './Select.module.scss';
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from "react";
import useOuterClick from "Hooks/UseOuterClick";
import Option from "./Option";
import Group from "./Group";
import { useTranslation } from 'react-i18next';

const CHILD_HEIGHT = 32;

export interface SelectContextType {
    onChange: (value: string | number | null, isSelected: boolean) => void,
    isValueSelected: (value: string | number) => boolean,
    focusedKey: string | number;
}
export const SelectContext = React.createContext<SelectContextType>({
    onChange: (value: string | number | null, isSelected: boolean) => {},
    isValueSelected: (value: any) => false,
    focusedKey: 0
})

interface Props {
    name: string;
    placeholder?: string;
    value?: string | number | any[] | boolean | null;
    error?: any;
    onChange: (newValue: any) => void;
    children: any;
    onFilter?: (newFiler: string) => void;
    multiple?: boolean;
    allowClear?: boolean;
    disabled?: boolean;
    inputRef?: any;
    styles?: CSSProperties;
}

const Select: React.FC<Props> & {
    Option: typeof Option;
    Group: typeof Group;
} = (props: Props) => {

    const { t } = useTranslation();

    const filterInputRef = useRef<HTMLInputElement>();
    const dropdownRef = useRef<HTMLDivElement>();
    
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [focusKey, setFocusKey] = useState<any>();
    
    const [inputFilterValue, setInputFilterValue] = useState<string>('');
    
    const [optionValues, setOptionValues] = useState<any[]>([]);

    const containerRef = useOuterClick(() => {
        setIsOpen(false);
    })
    
    useEffect(() => {
        if (optionValues.length > 0) {
            setFocusKey(optionValues[0].value);
        }
    }, [optionValues])
    
    const toggleOpen = (newState: boolean) => {
        if (props.disabled) {
            return;
        }

        setIsOpen(newState);

        if (newState) {
            setFocusKey(props.value ?? 0);
            filterInputRef.current?.focus();
            scrollToFocused('auto', props.value ?? 0);
        } else {
            filterInputRef.current?.blur();
        }
    }
    
    const setFilterValue = (newFilter: string) => {
        setInputFilterValue(newFilter === null ? '' : newFilter);
        if (props.onFilter) {
            props.onFilter(newFilter);
        }
    }
    
    const getMappedChildren = useMemo(() => props.children?.length > 0 ? props.children.filter((x: any) => !!x).map((child: any) => {
            return Array.isArray(child) ? child.flatMap(x => x) : [child];
        }).flatMap((x: any) => x).filter((x: any) => !!x) : [], [props.children]);
    
    useEffect(() => {
        let newOptionElements: any[] = [];
        
        getMappedChildren.forEach((child: any) => {
            if (child.type.displayName === 'Group') {
                child.props.children.forEach((groupChild: any) => {
                    if (groupChild.type.displayName === 'Option') {
                        newOptionElements.push(groupChild);
                    }
                })
            }
        
            if (child.type.displayName === 'Option') {
                newOptionElements.push(child);
            }
        });
        
        newOptionElements = newOptionElements.flatMap(x => x);
        
        const newOptionValues = newOptionElements.map((child: any) => {
            return {
                value: child.props.value,
                label: child.props.label,
            }
        });

        setOptionValues(newOptionValues);
    }, [getMappedChildren, props.children])
    
    const isValueSelected = (value: string | number | boolean): boolean => {
        if (props.multiple && Array.isArray(props.value)) {
            return props.value.includes(value);
        } else {
            return props.value === value;
        }
    }
    
    const getMultipleValues = () => {
        if (props.multiple) {
            if (!props.value) {
                return [];
            }

            if (!Array.isArray(props.value)) {
                return [];
            }
            
            let localValues = props.value as any[];
            
            return optionValues.filter(value => localValues.includes(value.value)).map(x => x.label);
        } else {
            console.error("You are using a single value select, but calls the multiple value function");
        }
    }
    
    const getValue = () => {
        if (props.multiple) {
            console.error("You are using a multiple value select, but calls the single value function");
        } else {
            return optionValues.find(value => value.value === props.value)?.label
        }
    }
    
    const onChange = (value: string | number | null, isSelected: boolean) => {
        setFilterValue('')
        
        if (!props.allowClear && value === '') {
            return;
        }
        
        if (props.multiple) {
            let localValues: unknown[] = [];
            
            if (Array.isArray(props.value)) {
                localValues = props.value as unknown[];
            }
            
            if (!value) {
                localValues = [];
            } else if (isSelected) {
                localValues.push(value);
            } else {
                localValues = localValues.filter(x => x !== value);
            }
            
            props.onChange(localValues);
        } else {
            if (isSelected) {
                props.onChange(value);
            } else {
                props.onChange(null);
            }
            setIsOpen(false)
        }
        
        setFocusKey(value)
        
        if (props.onFilter) {
            props.onFilter('');
        }
    }
    
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Escape") {
            toggleOpen(false);
        }
        
        if (e.key === "Enter") {
            e.preventDefault();
            onChange(focusKey, !isValueSelected(focusKey));
            toggleOpen(false);
        }
        
        if (e.key !== "ArrowDown" && e.key !== "ArrowUp") {
            return;
        }
    
        let currentlyFocusedOptionIndex = optionValues.findIndex(value => value.value === focusKey);
        
        if (e.key === "ArrowDown") {
            currentlyFocusedOptionIndex++;
        } else {
            currentlyFocusedOptionIndex--;
        }
        
        if (optionValues[currentlyFocusedOptionIndex]?.value) {
            setFocusKey(optionValues[currentlyFocusedOptionIndex]?.value);
        }
        
        scrollToFocused();
    }
    
    const scrollToFocused = ( behavior: ScrollBehavior = 'smooth', customFocusKey: any = null) => {
        const allChildren = getMappedChildren.map((child: any) => {
            if (child.type.displayName === "Group") {
                return [child, child.props.children];
            }
        
            return child;
        }).flatMap((x: any) => x).flatMap((x: any) => x);
        
        let focusedChildIndex = allChildren.findIndex((child: any) => child.type.displayName === "Option" && child.props.value === (customFocusKey ?? focusKey));

        setTimeout(() => {
            dropdownRef.current?.scrollTo({
                top: (focusedChildIndex - 2)  * CHILD_HEIGHT,
                behavior: behavior
            })
        }, 10)
    }
    
    return (
        <SelectContext.Provider value={{
            onChange: onChange,
            isValueSelected: isValueSelected,
            focusedKey: focusKey,
        }}>
            <div>
                <label className={`${styles.label} text-ellipsis`}>
                    {props.placeholder}
                </label>
                <div onClick={() => toggleOpen(true)} ref={e => containerRef.current = e!} className={styles.container}>
                    <div className={`${styles.trigger} ${props.disabled && styles.disabled} ${isOpen && styles.active} ${!!props.error ? styles.error : ''}`}>
                        <div className={styles.valueContainer}>
                            
                            <input
                                ref={e => {
                                    filterInputRef.current = e!;
                                    if (props.inputRef) {
                                        props.inputRef.current = e!
                                    }
                                }}
                                onFocus={() => toggleOpen(true)}
                                onBlur={() => setTimeout(() => { // To make sure the click handler has fired
                                    toggleOpen(false)
                                }, 200)}
                                className={`${styles.input} ${styles.floatingLabel} ${!isOpen && styles.hidden}`}
                                onChange={(e) => setFilterValue(e.target.value)}
                                onKeyDown={handleKeyDown}
                                value={inputFilterValue}
                            />
                            
                            <div className={`${styles.value} ${isOpen ? styles.hidden : ''} ${props.disabled ? styles.disabled : ''}`}>
                                {props.multiple ? getMultipleValues()?.join(', ') : getValue()}
                            </div>
        
                            <div className={styles.clear}>
                                {props.allowClear && (Array.isArray(props.value) ? props.value.length > 0 : !!props.value) ? (
                                    <div onClick={(e) => {
                                        e.stopPropagation();
                                        onChange('', false);
                                    }} style={{cursor: 'pointer'}}>X</div>
                                ) : <></>}
                            </div>
                        </div>
                    </div>

                    <div className={`${styles.dropdown} ${isOpen ? styles.open : styles.closed}`} ref={e => dropdownRef.current = e!}>
                        {props.children?.length > 0 ? props.children : <div className={styles.noResults}>{t('noResults')}</div>}
                    </div>
                </div >
            </div>
        </SelectContext.Provider>
    )
}

Select.Option = Option;
Select.Group = Group;

export default Select
