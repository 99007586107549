import { IOrderDto } from "Apis/Models/IOrderDto";
import HandsIcon from "Assets/SVG/HandsIcon";
import styles from './OrderDetails.module.scss';
import EmailIcon from "Assets/SVG/EmailIcon";
import PhoneIcon from "Assets/SVG/PhoneIcon";
import { useTranslation } from "react-i18next";
import BuildingIcon from "Assets/SVG/BuildingIcon";
import { cloneElement, ReactElement } from "react";
import ControlledTextArea from "Components/TextArea";
import { useForm } from "react-hook-form";
import CommentIcon from "Assets/SVG/CommentIcon";
import _ from "lodash";
import { useMutateOrder } from "Apis/OrderApi";
import useToasts from "Hooks/UseToasts";

interface IProps {
    order: IOrderDto
}
const OrderDetails = (props: IProps) => {
    
    const { t } = useTranslation();
    
    const { order } = props;
    
    const { control, handleSubmit } = useForm<{comments: string}>();
    
    const { mutateAsync: orderMutation } = useMutateOrder();
    
    const { showSuccessToast } = useToasts();
    
    const fadedIcon = (icon: ReactElement) => cloneElement(icon, {fill: "#ccc3c3", height: 20, width: 20})
    
    const saveComments = _.debounce(async (data: {comments: string}) => {
        await orderMutation({...order, comments: data.comments});
        
        showSuccessToast(t('orderCommentsSaved'));
    }, 1000);
    
    return (
        <div className={styles.container}>
            <div>
                <div className={styles.details}>
                    <div>
                        <div className={styles.detailsHeader}>
                            {t('customer')}
                        </div>
                        <div className={styles.customerName}>
                            <HandsIcon fill={"#6D4D4D"} />
                            {order.customer.name}
                        </div>
                    </div>
                    <div>
                        <div className={styles.detailsHeader}>
                            {t('contact')}
                        </div>
                        <div className={styles.centerHorizontal}>
                            {fadedIcon(<EmailIcon />)}
                            {order.customer.email}
                        </div>
                        <div className={styles.centerHorizontal}>
                            {fadedIcon(<PhoneIcon />)}
                            {order.customer.phone}
                        </div>
                    </div>
                </div>
                <div>
                    <div className={`${styles.commentHeader}`}>
                        <CommentIcon />
                        {t('orderComments')}
                    </div>
                    <ControlledTextArea onChange={handleSubmit(saveComments)} inputStyle={{height: '85%'}} control={control} name={"comments"} defaultValue={order.comments} />
                </div>
            </div>
            <div className={styles.addressDetails}>
                <div className={styles.imageContainer}>
                    <img width={"auto"} height={100} src={order?.customer.image?.url} alt={`${order?.customer.name} Logo`}/>
                </div>
                <div>
                    <div className={`${styles.detailsHeader} ${styles.padding}`}>
                        {t('deliveryDetails')}
                    </div>
                    {!!order.deliveryAddress.name && (
                        <div className={styles.centerHorizontal}>
                            {fadedIcon(<HandsIcon />)}
                            {order.deliveryAddress.name}
                        </div>
                    )}
                    {!!order.deliveryAddress.att && (
                        <div className={styles.centerHorizontal}>
                            {fadedIcon(<HandsIcon />)}
                            {order.deliveryAddress.att}
                        </div>
                    )}
                    <div className={styles.centerHorizontal}>
                        {fadedIcon(<BuildingIcon />)}
                        {order.deliveryAddress.street}
                    </div>
                    <div className={styles.centerHorizontal}>
                        {fadedIcon(<BuildingIcon />)}
                        {order.deliveryAddress.zip} {order.deliveryAddress.city}
                    </div>
                    {!!order.deliveryAddress.email && (
                        <div className={styles.centerHorizontal}>
                            {fadedIcon(<EmailIcon />)}
                            {order.deliveryAddress.email}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default OrderDetails
