import { useTranslation } from "react-i18next";
import List from "Components/List/List";
import FlexRow from "Components/FlexRow";
import DotsIcon from "Assets/SVG/DotsIcon";
import {useNavigate} from "react-router-dom";
import {IDesignDto} from "../../../Apis/Models/IDesignDto";
import CopyIcon from "../../../Assets/SVG/CopyIcon";

interface IProps {
    designs?: IDesignDto[];
    isLoading: boolean;
}
const DesignList = (props: IProps) => {
    
    const { t } = useTranslation();
    
    const { designs, isLoading } = props;

    const navigate = useNavigate();
    
    return (
        <List<IDesignDto> data={designs} loading={isLoading} columns={[
            {
                title: t('name'),
                textRender: (record) => record.name
            },
            {
                title: t('project'),
                textRender: (record) => record.project.name
            },
            {
                title: t('printCount'),
                textRender: (record) => record.prints.length.toString()
            },
            {
                title: t('unitCostPrice'),
                textRender: (record) => `${record.unitCostPrice} DKK`,
            },
            {
                title: t('unitSalesPrice'),
                textRender: (record) => `${record.unitSalesPrice} DKK`,
            },
            {
                title: t('functions'),
                size: "min-content",
                customRender: (record) => (
                    <FlexRow justify={"center"} align={"center"}>
                        <FlexRow className={"clickable"} justify={"center"} align={"center"} onClick={() => navigate(`/designs/clone/${record.id}`)} style={{paddingRight:15}}>
                            <CopyIcon width={30} height={30} />
                        </FlexRow>
                        <FlexRow className={"clickable"} justify={"center"} align={"center"} onClick={() => navigate(`/designs/${record.id}/edit`)}>
                            <DotsIcon />
                        </FlexRow>
                    </FlexRow>
                )
            }
        ]} keyExtractor={(record) => record.id.toString()} imageExtractor={record => record.prints.length > 0 ? record.prints.map(x => x.mockUpFile!) : []} />
    )
}

export default DesignList
