import * as React from "react"
import { SVGProps } from "react"

interface Props extends SVGProps<SVGSVGElement> {
    color?: 'red'
}
const Loader = (props: Props) => {

    const fill = props.color === 'red' ? '#e91d25' : props.fill ?? '#fff';

    return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         viewBox="0 0 100 100" enableBackground="new 0 0 100 100" height={props.height ?? 20} width={props.width ?? 20}>
        <rect fill={fill} width="5" height="100" transform="translate(0) rotate(180 3 50)">
          <animate
              attributeName="height"
              attributeType="XML"
              dur="1s"
              values="30; 100; 30"
              repeatCount="indefinite"/>
        </rect>
                <rect x="17" fill={fill} width="5" height="100" transform="translate(0) rotate(180 20 50)">
          <animate
              attributeName="height"
              attributeType="XML"
              dur="1s"
              values="30; 100; 30"
              repeatCount="indefinite"
              begin="0.1s"/>
        </rect>
                <rect x="40" fill={fill} width="5" height="100" transform="translate(0) rotate(180 40 50)">
          <animate
              attributeName="height"
              attributeType="XML"
              dur="1s"
              values="30; 100; 30"
              repeatCount="indefinite"
              begin="0.3s"/>
        </rect>
                <rect x="60" fill={fill} width="5" height="100" transform="translate(0) rotate(180 58 50)">
          <animate
              attributeName="height"
              attributeType="XML"
              dur="1s"
              values="30; 100; 30"
              repeatCount="indefinite"
              begin="0.5s"/>
        </rect>
                <rect x="80" fill={fill} width="5" height="100" transform="translate(0) rotate(180 76 50)">
          <animate
              attributeName="height"
              attributeType="XML"
              dur="1s"
              values="30; 100; 30"
              repeatCount="indefinite"
              begin="0.1s"/>
        </rect>
        </svg>
    )
}

export default Loader
