import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PageLayout from "Layouts/PageLayout";
import Button from "Components/Button";
import { PrintsList } from "Features/Prints";
import { usePrints } from "Apis/PrintApi";
import PrintIcon from "Assets/SVG/PrintIcon";
import Space from "Components/Space";
import Separator from "Components/Separator";
import Pagination from "../../Components/Pagination";
import useQueryParams from "../../Hooks/UseQueryParams";
import PrintListFilter from "../../Features/Prints/Components/PrintListFilter";

const Index = () => {
    
    const { t } = useTranslation();

    const [ filter, setFilter ] = useQueryParams();

    const { data: prints, isLoading } = usePrints(filter);
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={t('prints')} icon={<PrintIcon />} header={(
            <Button prefix={<PrintIcon fill={"#FFF"} />} text={t('createNewPrint')} type={"primary"} onClick={() => navigate("new")} />
        )}>
            <div>
                <PrintListFilter filterUpdated={setFilter} currentValue={filter} />

                <Separator style={{margin: '30px 0'}} />

                <div style={{width: 'fit-content'}}>
                    <Space direction={"horizontal"} size={"small"}>
                        <Button type={"tertiary"} text={t('printSizes')} onClick={() => navigate('/prints/size')} />
                        <Button type={"tertiary"} text={t('printPlacements')} onClick={() => navigate('/prints/placement')} />
                        <Button type={"tertiary"} text={t('foilTypes')} onClick={() => navigate('/prints/foilType')} />
                        <Button type={"tertiary"} text={t('transferTypes')} onClick={() => navigate('/prints/transferType')} />
                        <Button type={"tertiary"} text={t('printingTypes')} onClick={() => navigate('/prints/printingType')} />
                    </Space>
                </div>
    
                <Separator style={{margin: '30px 0'}} />
                
                <PrintsList prints={prints?.items} isLoading={isLoading} />

                <Pagination paginatedList={prints} onPageChange={newPage => setFilter({page: newPage})} />
            </div>
        </PageLayout>
    )
}

export default Index
