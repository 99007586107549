import PageLayout from "../../Layouts/PageLayout";
import {useTranslation} from "react-i18next";
import PrintsIcon from "../../Assets/SVG/PrintsIcon";
import DesignForm from "../../Features/Designs/Components/DesignForm";
import {useNavigate} from "react-router-dom";
import useQueryParams from "../../Hooks/UseQueryParams";

const New = () => {

    const { t } = useTranslation();

    const [queryParams]  = useQueryParams({printId: null});

    const navigate = useNavigate();

    return (
        <PageLayout title={t('newDesign')} icon={<PrintsIcon />} includeBox>
            <DesignForm onSubmit={_ => navigate("/designs")} printId={queryParams.printId?.toString()} />
        </PageLayout>
    )
}

export default New;