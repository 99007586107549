import * as React from "react"
import { SVGProps } from "react"
const ExternalLinkIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width ?? 14} height={props.height ?? 14} viewBox={"0 0 14 14"} {...props}>
        <path
            fill="#ccc3c3"
            d="M0 2.8v8.4A2.8 2.8 0 0 0 2.8 14h8.4a2.8 2.8 0 0 0 2.8-2.8V2.8A2.8 2.8 0 0 0 11.2 0H2.8A2.8 2.8 0 0 0 0 2.8Zm9.624 7.045a.656.656 0 0 1-.655.655H4.155a.656.656 0 0 1-.655-.655V5.032a.656.656 0 0 1 .655-.655h2.187a.219.219 0 0 1 .218.218v.437a.219.219 0 0 1-.218.218H4.374v4.376H8.75V8.095a.219.219 0 0 1 .218-.218h.437a.218.218 0 0 1 .218.216Zm.876-4.267a.328.328 0 0 1-.56.232l-.49-.487-3.332 3.332a.328.328 0 0 1-.465 0l-.308-.308a.328.328 0 0 1 0-.465L8.677 4.55l-.49-.49a.328.328 0 0 1 .232-.56h1.75a.33.33 0 0 1 .33.328Z"
            data-name="006-link"
        />
    </svg>
)
export default ExternalLinkIcon
