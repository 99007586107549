import { useQuery } from "react-query";
import { request } from "Apis/BaseApi";
import { IPaginatedList } from "Apis/Models/IPaginatedList";
import { IInventoryItemDto } from "./Models/IInventoryItemDto";
import {IQueryParams} from "./Models/IQueryParams";

export const INVENTORY_ITEMS_CACHE_KEY = 'INVENTORY_ITEMS_CACHE_KEY '
export const useInventoryItems = (queryable: Partial<IQueryParams> | null = null) => {

    return useQuery<IPaginatedList<IInventoryItemDto>>([INVENTORY_ITEMS_CACHE_KEY, queryable], async () => {
        return await request<IPaginatedList<IInventoryItemDto>>({
            url: `/inventory`,
            method: 'GET',
            queryParams: queryable
        })
    });
}
