import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InventoryIcon from "Assets/SVG/InventoryIcon";
import { ProductForm } from "Features/Products";

const NewProduct = () => {
    
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={t('newProduct')} icon={<InventoryIcon />} includeBox>
            <ProductForm product={{productType: 'Product'}} onSubmit={_ => navigate(-1)} />
        </PageLayout>
    )
}

export default NewProduct
