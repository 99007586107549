import styles from "Components/Input/ControlledInput.module.scss";

interface IProps {
    placeholder: string;
}
const Label = (props: IProps) => {
    
    return (
        <label className={`${styles.label} text-ellipsis`}>
            {props.placeholder}
        </label>
    )
}

export default Label
