import FlexRow from "Components/FlexRow";
import Box from "Components/Box";
import ResetPasswordForm from "Features/Authentication/Components/ResetPasswordForm";

const ResetPassword = () => {
    
    return (
        <FlexRow className={"h-100"} justify={"center"} align={"center"}>
            <div style={{width: 400}}>
                <Box>
                    <img alt={""} style={{paddingBottom: 20}} src={"/images/AnotherSystemLogo.svg"} />
                    <ResetPasswordForm />
                </Box>
            </div>
        </FlexRow>
    )
}

export default ResetPassword
