import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import { IPaginatedList } from "Apis/Models/IPaginatedList";
import {IQueryParams} from "./Models/IQueryParams";
import {IDesignDto, IMutateDesignDto} from "./Models/IDesignDto";

export const DESIGNS_CACHE_KEY = 'DESIGNS_CACHE_KEY '
export const useDesigns = (queryable: Partial<IQueryParams> | null = null) => {
    return useQuery<IPaginatedList<IDesignDto>>([DESIGNS_CACHE_KEY, queryable], async () => {
        return await request<IPaginatedList<IDesignDto>>({
            url: `/designs`,
            method: 'GET',
            queryParams: queryable
        })
    });
}

export const useDesign = (id: number) => {
    return useQuery<IDesignDto>([DESIGNS_CACHE_KEY, id], async () => {
        return await request<IDesignDto>({
            url: `/designs/${id}`,
            method: 'GET',
        })
    });
}

export const useMutateDesign = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (design: IMutateDesignDto): Promise<IDesignDto> => {
        if (!design.id) {
            return await request<IDesignDto>({
                url: `/designs`,
                method: 'POST',
                data: design
            })
        } else {
            return await request<IDesignDto>({
                url: `/designs/${design.id}`,
                method: 'PUT',
                data: design
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(DESIGNS_CACHE_KEY)
        }
    })
}

export const useDeleteDesign = () => {

    const queryClient = useQueryClient();

    return useMutation(async (design: IDesignDto): Promise<IDesignDto> => {
        return await request<IDesignDto>({
            url: `/designs/${design.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(DESIGNS_CACHE_KEY)
        }
    })
}
