import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import CustomerForm from "Features/Customers/Components/CustomerForm";
import { useNavigate, useParams } from "react-router-dom";
import { useCustomer } from "Apis/CustomerApi";
import HandsIcon from "Assets/SVG/HandsIcon";

const Edit = () => {
    
    const { t } = useTranslation();
    
    const { id } = useParams();
    
    const { data: customer } = useCustomer(parseInt(id!));
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={t('updateCustomer')} icon={<HandsIcon />} includeBox>
            <CustomerForm customer={customer} onSubmit={_ => navigate(-1)} />
        </PageLayout>
    )
}

export default Edit;
