import styles from "./ShowOrderLines.module.scss";
import InventoryIcon from "../../../Assets/SVG/InventoryIcon";
import { useTranslation } from "react-i18next";
import {usePartners} from "Apis/PartnerApi";
import {IOrderDto, IOrderLineDto} from "Apis/Models/IOrderDto";
import OrderLineGroup from "./OrderLineGroup";

interface IProps {
    order: IOrderDto;
}
const ShowOrderLines = (props: IProps) => {

    const { t } = useTranslation();

    const { order } = props;

    const { data: partners } = usePartners();

    const getGroupedOrderLines = () => {
        const groups = new Set(order?.orderLines.map(x => x.outsourcingPartnerId));

        let groupesLines: IOrderLineDto[][] = [];

        groups.forEach(group => {
            groupesLines.push(order?.orderLines.filter(x => x.outsourcingPartnerId === group)!);
        });

        return groupesLines;
    }

    return (
        <div>
            <div className={styles.sectionTitle}>
                <InventoryIcon fill={"#6D4D4D"} />
                {t('orderLines')}
            </div>
            {getGroupedOrderLines().map((group, index) => (
                <OrderLineGroup key={index} lines={group} partners={partners?.items ?? []} />
            ))}
        </div>
    )

}

export default ShowOrderLines