interface Config {
    baseUrl: string,
    firebaseConfig: FirebaseConfig;
}

interface FirebaseConfig {
    apiKey: string,
    authDomain: string,
    projectId: string,
    appId?: string
}


let config: Config

if (process.env.REACT_APP_ENV === 'Production') {
    config = {
        baseUrl: 'https://app.anothersolution.dk/app',
        firebaseConfig: {
            apiKey: "AIzaSyDDPhRUfi941fB9Ah57Ojl5g7m8xaLv4KE",
            authDomain: "anothersystem-production.firebaseapp.com",
            projectId: "anothersystem-production",
        },
    }
}
else {
    config = {
        baseUrl: 'http://localhost:3000/api',
        firebaseConfig: {
            apiKey: "AIzaSyByjNOJPdYGgFXDUVEJrCbpaFkYh7HUWek",
            authDomain: "anothersystem-dev.firebaseapp.com",
            projectId: "anothersystem-dev",
        },
    }
}

export default config;
