import { Controller } from "react-hook-form";
import React, { CSSProperties, Ref } from "react";
import styles from './ControlledInput.module.scss';
import FlexRow from "Components/FlexRow";
import Label from "Components/Label/Label";
interface IProps {
    errors?: any,
    name: string,
    rules?: any,
    control: any,
    prefix?: React.ReactNode;
    suffix?: string;
    number?: boolean;
    containerStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    inputRef?: Ref<HTMLInputElement>;
    placeholder: string;
    defaultValue?: string;
    onChange?: (value: any) => void;
    type?: string;
    disabled?: boolean;
    label?: React.ReactElement;
}
const ControlledInput = (props: IProps) => {
    
    return (
        <Controller
            name={props.name}
            rules={props.rules}
            control={props.control}
            defaultValue={props.defaultValue ?? undefined}
            render={(
                { field: { onChange, value }, fieldState: { error } },
            ) => (
                <FlexRow direction={"column"} align={"start"}>
                    {props.label ? props.label : <Label placeholder={props.placeholder} />}
                    <div className={`${styles.container}`}>
                        {props.prefix && (
                            <div className={styles.prefix}>
                                {props.prefix}
                            </div>
                        )}
                        <input
                            className={`${styles.input} ${!!error ? styles.error : ''}`}
                            disabled={props.disabled}
                            onChange={(value) => {
                                if (props.number) {
                                    onChange(value.currentTarget.value.replace(',', '.'));
                                } else {
                                    onChange(value.currentTarget.value);
                                }
            
                                if (props.onChange) {
                                    props.onChange(value)
                                }
                            }}
                            onWheel={e => e.currentTarget.blur()}
                            value={value ?? ''}
                            type={props.type}
                            style={props.inputStyle}
                            ref={props.inputRef}
                        />
                        {props.suffix && (
                            <div className={styles.suffix}>
                                {props.suffix}
                            </div>
                        )}
                    </div>
                </FlexRow>
            )}
        />
    )
}

export default ControlledInput
