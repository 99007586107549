import {SVGProps} from "react";

type Props = {
    direction: 'ASCENDING' | 'DESCENDING'
} & SVGProps<any>

const SmallCaret = (props: Props) => {

    return (
        <svg width="12" height="12" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform: props.direction === 'ASCENDING' ? 'rotate(180deg)' : ''}} {...props}>
            <g clipPath="url(#clip0_52_2)">
                <path
                    d="M1.13145 8.18117L12.6419 19.6924C12.8253 19.8758 13.15 19.8758 13.3334 19.6924L24.8449 8.18093C24.9366 8.08923 24.9882 7.96476 24.9882 7.83516C24.9882 7.70556 24.9366 7.5811 24.8449 7.4894L21.6689 4.3137C21.5733 4.21809 21.4486 4.17041 21.3232 4.17041C21.198 4.17041 21.0733 4.21809 20.9774 4.3137L12.9879 12.3032L4.99794 4.31395C4.81454 4.13031 4.48981 4.13055 4.30641 4.31395L1.13145 7.48964C0.940232 7.68062 0.940232 7.99019 1.13145 8.18117Z"
                    fill={props.fill ?? "rgba(109, 77, 77, 0.6)"}/>
            </g>
            <defs>
                <clipPath id="clip0_52_2">
                    <rect width="24" height="24" fill="white" transform="translate(0.987793)"/>
                </clipPath>
            </defs>
        </svg>
    )

}

export default SmallCaret