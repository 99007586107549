import styles from './Dropdown.module.scss';
import React, {useRef, useState} from "react";
import { useMultipleOuterClick } from "Hooks/UseOuterClick";
import { createPortal } from "react-dom";
import { CSSTransition } from "react-transition-group";

const POINTER_SIZE = 15;
const POINTER_ROTATED_SIZE = Math.sqrt(Math.pow(POINTER_SIZE, 2) * 2);
const POINTER_BORDER_WIDTH = 2;

interface Props {
    toggle: React.ReactElement;
    children: React.ReactElement | React.ReactElement[] | undefined;
    onToggleOpen?: Function;
    position?: 'Under Right' | 'Under Left' | 'Right';
    stayOpenAfterClick?: boolean;
    changeState?: boolean;
}
const Dropdown = (props: Props) => {
    
    const [isOpen, setIsOpen] = useState<boolean>(false);
    
    const containerRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    
    useMultipleOuterClick(props.stayOpenAfterClick ? [containerRef, dropdownRef] : [containerRef],() => {
        if (isOpen) {
            toggleDropdown();
        }
    });
    
    const toggleDropdown = (newValue: boolean | undefined = undefined) => {
        if (props.onToggleOpen) {
            props.onToggleOpen(newValue ?? !isOpen);
        }
        
        setIsOpen(newValue ?? !isOpen);
    }
    
    const getPointerStyle = () => {
        const containerBoundingRect = containerRef.current?.getBoundingClientRect();
        
        if (!containerBoundingRect) {
            return;
        }
        
        if (props.position === 'Right') {
            return {
                width: POINTER_SIZE,
                height: POINTER_SIZE,
                transform: `rotate(-45deg)`,
                left: -(POINTER_ROTATED_SIZE / 2) + POINTER_BORDER_WIDTH,
                top: containerBoundingRect?.height / 2 - POINTER_SIZE / 2 + POINTER_BORDER_WIDTH,
            }
        }
        
        if (props.position === 'Under Left') {
            return {
                width: POINTER_SIZE,
                height: POINTER_SIZE,
                transform: `rotate(45deg)`,
                top: -(POINTER_ROTATED_SIZE / 2) + POINTER_BORDER_WIDTH,
                left: POINTER_ROTATED_SIZE / 2 + POINTER_BORDER_WIDTH,
            }
        }
        
        return {
            width: POINTER_SIZE,
            height: POINTER_SIZE,
            transform: `rotate(45deg)`,
            top: -(POINTER_ROTATED_SIZE / 2) + POINTER_BORDER_WIDTH,
            right: containerBoundingRect?.width / 2 - POINTER_ROTATED_SIZE / 2 + POINTER_BORDER_WIDTH,
        }
    }
    
    const getPlacementStyle = () => {
        const dropDownBoundingRect = dropdownRef.current?.getBoundingClientRect();
        const containerBoundingRect = containerRef.current?.getBoundingClientRect();
    
        if (!containerBoundingRect || !dropDownBoundingRect) {
            return;
        }
    
        if (props.position === 'Right') {
            return {
                top: containerBoundingRect?.top!,
                left: containerBoundingRect?.x + containerBoundingRect?.width + POINTER_SIZE
            }
        }
    
        if (props.position === 'Under Left') {
            return {
                top: containerBoundingRect?.top! + containerBoundingRect?.height + POINTER_SIZE,
                left: containerBoundingRect?.x
            }
        }
        
        return {
            top: containerBoundingRect?.top! + containerBoundingRect?.height + POINTER_SIZE,
            left: containerBoundingRect?.x + containerBoundingRect?.width - (dropDownBoundingRect?.width!)
        }
    }

    
    return (
        <div className={styles.container} ref={containerRef}>
            {React.cloneElement(props.toggle, {
                onClick: () => toggleDropdown(true)
            })}
    
            {createPortal(
                <CSSTransition
                    in={isOpen}
                    timeout={{ enter: 0, exit: 150 }}
                    classNames={{
                        enterDone: styles.open,
                        exit: styles.closed,
                    }}
                >
                    <div className={styles.dropdown}
                         ref={dropdownRef}
                         style={getPlacementStyle()}
                    >
                        <div className={styles.pointer} style={getPointerStyle()} />
                        
                        {props.children}
                        
                    </div>
                </CSSTransition>
                ,document.getElementById('root')!)}
        </div>
    )
}

export default Dropdown
