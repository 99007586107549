import { Navigate, Route, Routes } from "react-router-dom";
import Index from "./Index";
import Edit from "./Edit";
import New from "./New";
import Clone from "./Clone";

const DesignRoutes = () => {
    
    return (
        <Routes>
            <Route path={`clone/:id`} element={<Clone />}/>
            <Route path={`:id/edit`} element={<Edit />}/>
            <Route path={`new`} element={<New />}/>

            <Route path={`/`} element={<Index />}/>

            <Route path={"*"} element={<Navigate to={"/designs"}/>}/>
        </Routes>
    )
}

export default DesignRoutes
