import {IQueryParams} from "./Models/IQueryParams";
import {useQuery} from "react-query";
import {request} from "./BaseApi";
import {IBiDto} from "./Models/IBiDto";

export const BI_CACHE_KEY = 'BI_CACHE_KEY '
export const useBI = (queryable: Partial<IQueryParams> | null = null) => {
    return useQuery<IBiDto>([BI_CACHE_KEY, queryable], async () => {
        return await request<IBiDto>({
            url: `/bi`,
            method: 'GET',
            queryParams: queryable
        })
    }, {
        enabled: (queryable && queryable['from'] !== undefined && queryable['to'] !== undefined) ?? false
    });
}