import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PrintsIcon from "Assets/SVG/PrintsIcon";
import React from "react";
import PrintForm from "Features/Prints/Components/PrintForm";
import {usePrint} from "../../Apis/PrintApi";

const Clone = () => {
    
    const { t } = useTranslation();
    
    const { id } = useParams();
    
    const { data: print } = usePrint(parseInt(id!));
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={`${t('clonePrint')} - ${print?.name}`} icon={<PrintsIcon />} includeBox>
            <PrintForm print={{...print, id: undefined}} onSubmit={_ => navigate(-1)} />
        </PageLayout>
    )
}

export default Clone;
