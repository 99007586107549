import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import List from "Components/List/List";
import FlexRow from "Components/FlexRow";
import DotsIcon from "Assets/SVG/DotsIcon";
import {ITagDto} from "../../../Apis/Models/ITagDto";

interface IProps {
    tags?: ITagDto[];
    isLoading: boolean;
}
const TagList = (props: IProps) => {
    
    const { t } = useTranslation();
    
    const { tags, isLoading } = props;
    
    const navigate = useNavigate();
    
    return (
        <List<ITagDto> data={tags} loading={isLoading} columns={[
            {
                title: t('name'),
                textRender: (record) => record.name
            },
            {
                title: t('functions'),
                size: "min-content",
                customRender: (record) => <FlexRow className={"h-100 clickable"} justify={"center"} onClick={() => navigate(`/tags/edit/${record.id}`)} ><DotsIcon /></FlexRow>
            }
        ]} keyExtractor={(record) => record.id.toString()} />
    )
}

export default TagList
