import styles from './SideMenuLayout.module.scss';
import AnotherSolutionIcon from "Assets/SVG/AnotherSolutionIcon";
import {cloneElement, ReactElement, ReactNode, useState} from "react";
import { Link, useMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PeopleIcon from "Assets/SVG/PeopleIcon";
import HandsIcon from "Assets/SVG/HandsIcon";
import GuitarIcon from "Assets/SVG/GuitarIcon";
import OrdersIcon from "Assets/SVG/OrdersIcon";
import PrintIcon from "Assets/SVG/PrintIcon";
import InventoryIcon from "Assets/SVG/InventoryIcon";
import CalculatorIcon from "../Assets/SVG/CalculatorIcon";
import moment from 'moment';
import SmallCaret from "../Assets/SVG/SmallCaret";
import TagIcon from "../Assets/SVG/TagIcon";

interface IProps {
    children: ReactNode;
}
const SideMenuLayout = (props: IProps) => {
    
    const { t } = useTranslation();

    const [open, setOpen] = useState<boolean>(true)
    const [isHoveringMenu, setIsHoveringMenu] = useState<boolean>(false)

    const { children } = props;
    
    const getBiUrl = () => {
        return `/bi?display=Order&statusDate=Received&from=${moment().startOf('month').format('YYYY-MM-DD')}&to=${moment().endOf('month').format('YYYY-MM-DD')}`;
    }
    
    return (
        <div className={`${styles.layout} ${(open || isHoveringMenu) && styles.open}`}>
            <div className={`${styles.menu}`} onMouseEnter={() => setIsHoveringMenu(true)} onMouseLeave={() => setIsHoveringMenu(false)}>
                <div className={styles.toggle} onClick={_ => setOpen(current => !current)}>
                    <SmallCaret direction={"ASCENDING"} style={{transform: `rotate(${open ? 90 : -90}deg)`}} fill={"white"} />
                </div>
                <div className={styles.logoContainer}>
                    <AnotherSolutionIcon />
                </div>
                <div>
                    <MenuItem to={"/orders?page=0&status=Open&reverseTagSearch=false"} text={t('orders')} icon={<OrdersIcon />} matchUrl={"orders"} />
                    <MenuItem to={getBiUrl()} text={t('bi.bi')} icon={<CalculatorIcon />} matchUrl={"/bi"} />
                    <MenuItem to={"/customers?page=0"} text={t('customers')} icon={<HandsIcon />} matchUrl={"customers"} />
                    <MenuItem to={"/projects?page=0"} text={t('projects')} icon={<GuitarIcon />} matchUrl={"projects"} />
                    <MenuItem to={"/partners?page=0"} text={t('partners')} icon={<HandsIcon />} matchUrl={"partners"} />
                    <MenuItem to={"/products?page=0&readyForUse=true"} text={t('products')} icon={<InventoryIcon />} matchUrl={"products"} />
                    <MenuItem to={"/designs?page=0"} text={t('designs')} icon={<PrintIcon />} matchUrl={"designs"} />
                    <MenuItem to={"/prints?page=0"} text={t('prints')} icon={<PrintIcon />} matchUrl={"prints"} />
                    <MenuItem to={"/tags?page=0"} text={t('tags')} icon={<TagIcon />} matchUrl={"tags"} />
                    <MenuItem to={"/users?page=0"} text={t('users')} icon={<PeopleIcon />} matchUrl={"users"} />
                </div>
                <div>
                
                </div>
            </div>
            <div className={styles.content}>
                {children}
            </div>
        </div>
    )
}

interface IMenuItemProps {
    to: string;
    matchUrl?: string;
    text: string;
    icon: ReactElement;
}
const MenuItem = (props: IMenuItemProps) => {
    
    const { to, matchUrl, text, icon } = props;
    
    const match = useMatch({ path: matchUrl ?? to, end: false })
    
    return (
        <Link to={to} className={`${styles.menuItem} ${match && styles.active} `}>
            <div className={styles.icon}>
                {cloneElement(icon, { fill: match ? "#E91D25" : "#707070", height: 23, width: 23 })}
            </div>
            <span>
                {text}
            </span>
            {match && (
                <div className={styles.activeIcon}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14.999" height="30.985" viewBox="0 0 14.999 30.985">
                        <path id="Intersection_1" data-name="Intersection 1" d="M23,28.5A15.5,15.5,0,0,1,38,13.008v8.009a7.5,7.5,0,0,0,0,14.967v8.009A15.5,15.5,0,0,1,23,28.5Z" transform="translate(-23 -13.008)" fill="#e91d25"/>
                    </svg>
                </div>
            )}
        </Link>
    )
}

export default SideMenuLayout
