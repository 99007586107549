import { Navigate, Route, Routes } from "react-router-dom";
import New from "./New";
import Edit from "./Edit";
import Index from "./Index";

const ProjectRoutes = () => {
    
    return (
        <Routes>
            <Route path={`new`} element={<New />}/>

            <Route path={`edit/:id`} element={<Edit />}/>

            <Route path={`/`} element={<Index />}/>

            <Route path={"*"} element={<Navigate to={"/projects"}/>}/>
        </Routes>
    )
}

export default ProjectRoutes
