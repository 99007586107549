import Space from "Components/Space";
import ControlledInput from "Components/Input";
import FlexRow from "Components/FlexRow";
import Button from "Components/Button";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { IPrintDto } from "Apis/Models/IPrintDto";
import { usePrintSizes } from "Apis/PrintSizeApi";
import React, { useEffect, useState } from "react";
import { useMutatePrint } from "Apis/PrintApi";
import Select from "Components/Select";
import { useProjects } from "Apis/ProjectApi";
import { usePrintPlacements } from "Apis/PrintPlacementApi";
import ControlledSelect from "Components/Select/ControlledSelect";
import FileUpload from "Components/FileUpload/FileUpload";
import { IFileInformationDto } from "Apis/Models/IFileDto";
import styles from './PrintForm.module.scss';
import Loader from "../../../Assets/SVG/Loader";
import useFilteredList from "../../../Hooks/UseFilteredList";
import useToasts from "../../../Hooks/UseToasts";
import {useNavigate} from "react-router-dom";
import {useTransferTypes} from "../../../Apis/TransferTypeApi";
import {useFoilTypes} from "../../../Apis/FoilTypeApi";
import {usePrintingTypes} from "../../../Apis/PrintingTypeApi";
import {usePrintType} from "../Hooks/UsePrintType";

interface IProps {
    print?: Partial<IPrintDto>;
    onSubmit?: (data: IPrintDto) => void;
}
const PrintForm = (props: IProps) => {
    
    const { t } = useTranslation();

    const { showSuccessToastWithContent } = useToasts()
    const navigate = useNavigate();
    
    const { watch, control, handleSubmit, reset } = useForm<IPrintDto>();
    const [mockupFile, setMockupFile] = useState<IFileInformationDto>();
    
    const { data: projects } = useProjects({includeAll: true});
    const { data: printSizes } = usePrintSizes({includeAll: true});
    const { data: printPlacements } = usePrintPlacements({includeAll: true});
    const { data: printingTypes } = usePrintingTypes({includeAll: true});
    const { data: foilTypes } = useFoilTypes({includeAll: true});
    const { data: transferTypes } = useTransferTypes({includeAll: true});
    const { printTypes } = usePrintType()

    const { mutateAsync, isLoading } = useMutatePrint();

    const [ filteredProjects, setProjectFilter ] = useFilteredList(projects?.items ?? [], x => [x.name]);
    const [ filteredPrintsSizes, setPrintSizeFilter ] = useFilteredList(printSizes?.items ?? [], x => [x.name]);
    const [ filteredPrintPlacements, setPrintPlacementsFilter ] = useFilteredList(printPlacements?.items ?? [], x => [x.name]);
    const [ filteredPrintingTypes, setPrintingTypesFilter ] = useFilteredList(printingTypes?.items ?? [], x => [x.name]);
    const [ filteredFoilTypes, setFoilTypesFilter ] = useFilteredList(foilTypes?.items ?? [], x => [x.name]);
    const [ filteredTransferTypes, setTransferTypesFilter ] = useFilteredList(transferTypes?.items ?? [], x => [x.name]);
    const [ filteredPrintTypes, setPrintTypeFilter ] = useFilteredList(printTypes.filter(x => !!x.value), x => [x.label]);


    useEffect(() => {
        if (props.print) {
            reset(props.print);
            setMockupFile(props.print.mockUpFile);
        }
    }, [reset, props.print])
    
    const onSubmit = async(data: IPrintDto) => {
        const response = await mutateAsync({ ...data, mockUpFile: mockupFile });
        
        if (props.onSubmit) {
            props.onSubmit(data);

            showSuccessToastWithContent(<div onClick={() => navigate(`/designs/new?printId=${response.id}`)}>{t('printSavedCreateDesign')}</div>, { autoClose: 4000 });
        }
        
        reset();
    }
    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Space direction={"vertical"} size={"large"}>
                
                <div className={styles.imageRow}>
                    <FileUpload callback={setMockupFile} accept={['.jpeg', '.jpg', '.png']} render={loading => (
                        <div className={styles.mockupFileContainer}>
                            {loading ? (<Loader color={"red"} height={50} width={50} />) : (<>
                                {!!mockupFile ? (
                                    <img height={150} width={150} src={mockupFile.url} alt={mockupFile.fileName} />
                                ) : (
                                    <>
                                        {t('upload')}
                                    </>
                                )}
                            </>)}
                        </div>
                    )} />
                    <ControlledInput rules={{required: true}} name={"name"} control={control} placeholder={t('name')} />
                </div>
                
                <ControlledSelect rules={{required: true}} control={control} placeholder={t('project')} name={"projectId"} onFilter={setProjectFilter}>
                    {filteredProjects?.map(project => <Select.Option key={project.id} value={project.id} label={project.name} />)}
                </ControlledSelect>

                <ControlledSelect rules={{required: true}} control={control} placeholder={t('printType.printType')} name={"printType"} onFilter={setPrintTypeFilter}>
                    {filteredPrintTypes?.map((printType, index) => <Select.Option key={index} value={printType.value} label={printType.label} />)}
                </ControlledSelect>

                <ControlledSelect rules={{required: true}} control={control} placeholder={t('printPlacement')} name={"printPlacementId"} onFilter={setPrintPlacementsFilter}>
                    {filteredPrintPlacements?.map(printPlacement => <Select.Option key={printPlacement.id} value={printPlacement.id} label={printPlacement.name} />)}
                </ControlledSelect>

                {watch('printType') === 'DTG' && (
                    <>
                        <ControlledSelect rules={{required: true}} control={control} placeholder={t('printSize')} name={"printSizeId"} onFilter={setPrintSizeFilter}>
                            {filteredPrintsSizes?.map(printSize => <Select.Option key={printSize.id} value={printSize.id} label={printSize.name} />)}
                        </ControlledSelect>

                        <ControlledInput name={"pretreatment"} number defaultValue={undefined} control={control} placeholder={t('pretreatment')} />
                    </>
                )}

                {watch('printType') === 'Printing' && (
                    <ControlledSelect rules={{required: true}} control={control} placeholder={t('printingType')} name={"printingTypeId"} onFilter={setPrintingTypesFilter}>
                        {filteredPrintingTypes?.map(printingType => <Select.Option key={printingType.id} value={printingType.id} label={printingType.name} />)}
                    </ControlledSelect>
                )}

                {watch('printType') === 'Plot' && (
                    <ControlledSelect rules={{required: true}} control={control} placeholder={t('foilType')} name={"foilTypeId"} onFilter={setFoilTypesFilter}>
                        {filteredFoilTypes?.map(foilType => <Select.Option key={foilType.id} value={foilType.id} label={foilType.name} />)}
                    </ControlledSelect>
                )}

                {watch('printType') === 'Transfer' && (
                    <ControlledSelect rules={{required: true}} control={control} placeholder={t('transferType')} name={"transferTypeId"} onFilter={setTransferTypesFilter}>
                        {filteredTransferTypes?.map(transferType => <Select.Option key={transferType.id} value={transferType.id} label={transferType.name} />)}
                    </ControlledSelect>
                )}

                {["Embroidery", "Printing", "DTF", "Transfer", "Plot"].includes(watch('printType')) && (
                    <ControlledInput name={"customSize"} number defaultValue={undefined} control={control} placeholder={t('customSize')} />
                )}

                {["Embroidery", "DTF", "Transfer", "Plot"].includes(watch('printType')) && (
                    <ControlledInput name={"color"} number defaultValue={undefined} control={control} placeholder={t('color')} />
                )}

                {["Embroidery"].includes(watch('printType')) && (
                    <ControlledInput name={"vendor"} number defaultValue={undefined} control={control} placeholder={t('vendor')} />
                )}

                <ControlledInput name={"unitCostPrice"} number defaultValue={undefined} control={control} placeholder={t('unitCostPrice')} />
                <ControlledInput name={"unitSalesPrice"} number defaultValue={undefined} control={control} placeholder={t('unitSalesPrice')} />

                <FlexRow justify={"end"}>
                    <Button buttonType={"submit"} type={"primary"} text={t('save')} loading={isLoading} />
                </FlexRow>
            </Space>
        </form>
    )
}

export default PrintForm
