import {useFieldArray, useForm} from "react-hook-form";
import ControlledInput from "Components/Input";
import { useTranslation } from "react-i18next";
import Button from "Components/Button";
import Space from "Components/Space";
import FlexRow from "Components/FlexRow";
import { useEffect, useState } from "react";
import { ICustomerDto } from "Apis/Models/ICustomerDto";
import { useMutateCustomer } from "Apis/CustomerApi";
import styles from './CustomerForm.module.scss';
import FileUpload from "Components/FileUpload/FileUpload";
import { IFileInformationDto } from "Apis/Models/IFileDto";
import Loader from "../../../Assets/SVG/Loader";
import XIcon from "../../../Assets/SVG/XIcon";
import Separator from "../../../Components/Separator";
import Label from "../../../Components/Label";

interface IProps {
    customer?: ICustomerDto;
    onSubmit?: (data: ICustomerDto) => void;
}
const CustomerForm = (props: IProps) => {
    
    const { t } = useTranslation();
    
    const { register, control, handleSubmit, reset } = useForm<ICustomerDto>();
    const { fields, append, remove } = useFieldArray({
        control,
        name: "contacts",
        keyName: 'fieldId'
    });

    const [file, setFile] = useState<IFileInformationDto>();
    const { mutateAsync, isLoading } = useMutateCustomer();
    
    useEffect(() => {
        if (props.customer) {
            reset(props.customer);
            if (props.customer.image) {
                setFile(props.customer.image);
            }
        }
    }, [reset, props.customer])
    
    const onSubmit = async(data: ICustomerDto) => {
        await mutateAsync({ ...data, image: file });
        
        if (props.onSubmit) {
            props.onSubmit(data);
        }
        
        reset();
    }
    
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Space direction={"vertical"} size={"large"}>
                
                <div className={styles.imageRow}>
                    <FileUpload callback={setFile} accept={['.jpeg', '.jpg', '.png']} render={loading => (
                        <div className={styles.fileUpload}>
                            {loading ? (<Loader color={"red"} height={50} width={50} />) : (<>
                                {!!file ? (
                                    <img height={150} width={150} src={file.url} alt={file.fileName} />
                                ) : (
                                    <>
                                        {t('upload')}
                                    </>
                                )}
                            </>)}
                        </div>
                    )} />

                    <ControlledInput rules={{required: true}} name={"name"} control={control} placeholder={t('name')} />
                </div>
                <ControlledInput name={"vatNumber"} control={control} placeholder={t('vatNumber')} />
                <ControlledInput disabled={!!props.customer}  name={"customerNumber"} control={control} placeholder={t('customerNumber')} type={"number"} number />
                <ControlledInput name={"ean"} control={control} placeholder={t('ean')} />
                <ControlledInput name={"address"} control={control} placeholder={t('address')} />
                <ControlledInput name={"zip"} control={control} placeholder={t('zip')} />
                <ControlledInput name={"city"} control={control} placeholder={t('city')} />
                <ControlledInput name={"email"} control={control} placeholder={t('email')} />
                <ControlledInput name={"phone"} control={control} placeholder={t('phone')} />

                <Separator />

                <Label placeholder={t('contacts')} />

                {fields.map((field, index) => (
                    <div key={field.fieldId} className={styles.contactContainer}>
                        <div className={styles.delete} onClick={() => remove(index)}>
                            <XIcon />
                        </div>
                        <input style={{display: 'none'}} {...register(`contacts.${index}.id`)} value={field.id ?? undefined} />

                        <ControlledInput name={`contacts.${index}.name`} control={control} placeholder={t('name')} />
                        <ControlledInput name={`contacts.${index}.phone`} control={control} placeholder={t('phone')} />
                        <ControlledInput name={`contacts.${index}.email`} control={control} placeholder={t('email')} />
                    </div>
                ))}

                <FlexRow justify={"end"}>
                    <Button buttonType={"button"} type={"tertiary"} text={t('addContact')} onClick={() => append({id: undefined})} />
                </FlexRow>
                
                <FlexRow justify={"end"}>
                    <Button buttonType={"submit"} type={"primary"} text={t('save')} loading={isLoading} />
                </FlexRow>
            </Space>
        </form>
    )
}

export default CustomerForm
