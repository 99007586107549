import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PrintsIcon from "Assets/SVG/PrintsIcon";
import {ProductForm} from "../../Features/Products";
import {useProduct} from "../../Apis/ProductApi";

const Clone = () => {
    
    const { t } = useTranslation();
    
    const { id } = useParams();
    
    const { data: product } = useProduct(parseInt(id!));
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={`${t('cloneProduct')} - ${product?.name}`} icon={<PrintsIcon />} includeBox>
            <ProductForm product={{...product, id: undefined}} onSubmit={_ => navigate(-1)} />
        </PageLayout>
    )
}

export default Clone;
