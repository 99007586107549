import * as React from "react"
import { SVGProps } from "react"

const EmailIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={13.75} {...props} viewBox="0 0 20 13.75">
        <g data-name="003-email">
            <g data-name="Group 1398">
                <g data-name="Group 1397">
                    <path
                        data-name="Path 1343"
                        d="M13.257 6.978 20 11.241V2.539Z"
                        fill={props.fill ?? "#ccc3c3"}
                    />
                </g>
            </g>
            <g data-name="Group 1400">
                <g data-name="Group 1399">
                    <path
                        data-name="Path 1344"
                        d="M0 2.539v8.7l6.742-4.263Z"
                        fill={props.fill ?? "#ccc3c3"}
                    />
                </g>
            </g>
            <g data-name="Group 1402">
                <g data-name="Group 1401">
                    <path
                        data-name="Path 1345"
                        d="M18.749 0h-17.5A1.234 1.234 0 0 0 .037 1.064l9.962 6.564 9.962-6.564A1.234 1.234 0 0 0 18.749 0Z"
                        fill={props.fill ?? "#ccc3c3"}
                    />
                </g>
            </g>
            <g data-name="Group 1404">
                <g data-name="Group 1403">
                    <path
                        data-name="Path 1346"
                        d="m12.116 7.732-1.769 1.165a.628.628 0 0 1-.687 0L7.887 7.731.04 12.695a1.232 1.232 0 0 0 1.21 1.055h17.5a1.232 1.232 0 0 0 1.21-1.055Z"
                        fill={props.fill ?? "#ccc3c3"}
                    />
                </g>
            </g>
        </g>
    </svg>
)

export default EmailIcon
