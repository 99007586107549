import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {OrderStatus} from "../../../Apis/Models/IOrderDto";


interface OrderStatusWithLabel {
    value: string | undefined;
    label: string;
}
export const useOrderStatus = (): {statusses: OrderStatusWithLabel[], actualtOrderStatus: OrderStatusWithLabel[], getOrderStatusLabel: (orderStauts: OrderStatus) => string} => {

    const { t } = useTranslation();

    const orderStatusses = useMemo(() => [
        { value: 'Open',label: t('invoiceStatus.open')},
        { value: undefined, label: t('invoiceStatus.all')},
        { value: 'Received', label: t('invoiceStatus.received')},
        { value: 'Accepted', label: t('invoiceStatus.accepted')},
        { value: 'Produced', label: t('invoiceStatus.produced')},
        { value: 'Delivered', label: t('invoiceStatus.delivered')},
        { value: 'Finished', label: t('invoiceStatus.finished')},
        { value: 'Cancelled', label: t('invoiceStatus.cancelled')},
    ], [t]);
    
    const getOrderStatusLabel = (status: OrderStatus): string => {
        return orderStatusses.find(x => x.value === status)?.label ?? '';
    }

    const actualtOrderStatus = useMemo(() => {
        return orderStatusses?.filter(x => x.value !== "Open" && x.value !== undefined)
    }, [orderStatusses])
    
    return { statusses: orderStatusses, actualtOrderStatus, getOrderStatusLabel: getOrderStatusLabel };
    
}