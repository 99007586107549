import {useMemo, useState} from "react";

const useFilteredList = <T, >(items: T[], getValueFunction: (item: T) => string[]): [T[], (value: string) => void, string] => {

    const [ filterValue, setFilterValue ] = useState<string>("");

    const filteredItems = useMemo(() => {

        if (!filterValue) {
            return items;
        }

        let lowercaseValue = filterValue.toLowerCase();

        return items.filter(x => {
            return getValueFunction(x).filter(x => !!x).map(x => x.toString().toLowerCase()).filter(x => x.includes(lowercaseValue)).length > 0;
        });

    }, [items, getValueFunction, filterValue]);

    return [ filteredItems, setFilterValue, filterValue ];
}

export default useFilteredList