import React, { ChangeEventHandler, useEffect, useState } from "react";
import styles from './DatePicker.module.scss';
import { ClassNames, DayPicker } from "react-day-picker";
import Dropdown from "Components/Dropdown";
import da from 'date-fns/locale/da';
import default_styles from 'react-day-picker/dist/style.module.css';
import Label from "../Label";
import FlexRow from "Components/FlexRow";
import moment from "moment";
import {parse} from "date-fns";

interface Props {
    name: string;
    placeholder?: string;
    value?: string | null;
    errors?: any;
    onChange: (newValue: string | null) => void;
    allowClear?: boolean
}
const DatePicker = (props: Props) => {
    
    const [selected, setSelectedDate] = useState<string | null | undefined>();
    const [inputValue, setValue] = useState<string>('');
    
    const classNames: ClassNames = {
        ...default_styles,
        head_cell: styles.head_cell,
        caption_label: styles.caption_label,
        day_selected: styles.daySelected,
        day: styles.day,
        button: styles.button,
        button_reset: styles.buttonReset,
        months: styles.months,
        month: styles.month,
    };
    
    useEffect(() => {
        setSelectedDate(!!props.value ? stringToMachineDate(props.value) : undefined);
    
        if (props.value) {
            setValue(stringToReadableDate(props.value));
        }
    }, [props.value])
    
    const onChange = (newValue: Date | null) => {
        props.onChange(!!newValue ? stringToMachineDate(newValue) : null)
    }
    
    const handleDaySelect = (date: Date) => {
        setSelectedDate(stringToMachineDate(date));

        if (date) {
            setValue(stringToReadableDate(date));
            onChange(date);
        } else {
            setValue('');
        }
    };

    const stringToReadableDate = (date: string | Date) => {
        return moment(date).format('DD-MM-YYYY');
    }

    const stringToMachineDate = (date: string | Date) => {
        return moment(date).format('YYYY-MM-DD');
    }
    
    const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const date = moment(e.currentTarget.value);

        if (date.isValid()) {
            setSelectedDate(stringToMachineDate(date.toDate()));
            setValue(stringToReadableDate(date.toDate()));
            onChange(date.toDate());
        } else {
            setSelectedDate(undefined);
        }
    };
    
    const hasValue = () => {
        return !!props.value
    }
    
    const clear = () => {
        setSelectedDate(undefined);
        setValue('')
        onChange(null);
    }
    
    return (
        <Dropdown position={'Under Left'} stayOpenAfterClick={true} toggle={
            <FlexRow direction={"column"} align={"start"}>
                <Label placeholder={props.placeholder ?? ''} />
                <div className={`${styles.container}`}>
                    <input
                        className={`${styles.input}`}
                        value={inputValue}
                        onChange={handleInputChange}
                    />
                    <div className={styles.clear}>
                        {props.allowClear && hasValue() && (
                            <div onClick={(e) => {
                                e.stopPropagation();
                                clear()
                            }} style={{cursor: 'pointer'}}>X</div>
                        )}
                    </div>
                </div>
            </FlexRow>
        }>
            <DayPicker classNames={classNames} selected={selected === null ? undefined : parse(selected!, 'y-MM-dd', new Date())} mode={'single'} locale={da} onSelect={e => handleDaySelect(e!)} />
        </Dropdown>
    )
}

export default DatePicker
