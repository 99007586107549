import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import { IPaginatedList } from "Apis/Models/IPaginatedList";
import {IQueryParams} from "./Models/IQueryParams";
import {ITagDto} from "./Models/ITagDto";

export const TAGS_CACHE_KEY = 'TAGS_CACHE_KEY '
export const useTags = (queryable: Partial<IQueryParams> | null = null) => {
    return useQuery<IPaginatedList<ITagDto>>([TAGS_CACHE_KEY, queryable], async () => {
        return await request<IPaginatedList<ITagDto>>({
            url: `/tags`,
            method: 'GET',
            queryParams: queryable
        })
    });
}

export const useTag = (id: number) => {
    return useQuery<ITagDto>([TAGS_CACHE_KEY, id], async () => {
        return await request<ITagDto>({
            url: `/tags/${id}`,
            method: 'GET',
        })
    });
}

export const useMutateTag = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (tag: ITagDto): Promise<ITagDto> => {
        if (!tag.id) {
            return await request<ITagDto>({
                url: `/tags`,
                method: 'POST',
                data: tag
            })
        } else {
            return await request<ITagDto>({
                url: `/tags/${tag.id}`,
                method: 'PUT',
                data: tag
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(TAGS_CACHE_KEY)
        }
    })
}
