import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PageLayout from "Layouts/PageLayout";
import Button from "Components/Button";
import PrintIcon from "Assets/SVG/PrintIcon";
import Pagination from "../../../Components/Pagination";
import useQueryParams from "../../../Hooks/UseQueryParams";
import TransferTypeList from "../../../Features/Prints/Components/TransferTypeList";
import {useTransferTypes} from "../../../Apis/TransferTypeApi";

const Index = () => {
    
    const { t } = useTranslation();

    const [ filter, setFilter ] = useQueryParams();

    const { data: foilTypes, isLoading } = useTransferTypes(filter);
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={t('transferTypes')} icon={<PrintIcon />} header={(
            <Button prefix={<PrintIcon fill={"#FFF"} />} text={t('createNewTransferType')} type={"primary"} onClick={() => navigate("new")} />
        )}>
            <>
                <TransferTypeList transferTypes={foilTypes?.items} isLoading={isLoading} />

                <Pagination paginatedList={foilTypes} onPageChange={newPage => setFilter({page: newPage})} />
            </>
        </PageLayout>
    )
}

export default Index
