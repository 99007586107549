import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import { IPaginatedList } from "Apis/Models/IPaginatedList";
import { IPartnerDto } from "Apis/Models/IPartnerDto";
import {IQueryParams} from "./Models/IQueryParams";

export const PARTNERS_CACHE_KEY = 'PARTNERS_CACHE_KEY '
export const usePartners = (queryable: Partial<IQueryParams> | null = null) => {
    return useQuery<IPaginatedList<IPartnerDto>>([PARTNERS_CACHE_KEY, queryable], async () => {
        return await request<IPaginatedList<IPartnerDto>>({
            url: `/partners`,
            method: 'GET',
            queryParams: queryable
        })
    });
}

export const usePartner = (id: number) => {
    return useQuery<IPartnerDto>([PARTNERS_CACHE_KEY, id], async () => {
        return await request<IPartnerDto>({
            url: `/partners/${id}`,
            method: 'GET',
        })
    });
}

export const useMutatePartner = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (partner: IPartnerDto): Promise<IPartnerDto> => {
        if (!partner.id) {
            return await request<IPartnerDto>({
                url: `/partners`,
                method: 'POST',
                data: partner
            })
        } else {
            return await request<IPartnerDto>({
                url: `/partners/${partner.id}`,
                method: 'PUT',
                data: partner
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(PARTNERS_CACHE_KEY)
        }
    })
}

export const useDeletePartner = () => {

    const queryClient = useQueryClient();

    return useMutation(async (partner: IPartnerDto): Promise<IPartnerDto> => {
        return await request<IPartnerDto>({
            url: `/partners/${partner.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(PARTNERS_CACHE_KEY)
        }
    })
}

