import Label from "../Label";
import styles from "./ControlledInput.module.scss";
import FlexRow from "../FlexRow";
import React, {CSSProperties, Ref} from "react";

interface IProps {
    name?: string,
    prefix?: React.ReactNode;
    suffix?: string;
    number?: boolean;
    value: any
    containerStyle?: CSSProperties;
    inputStyle?: CSSProperties;
    inputRef?: Ref<HTMLInputElement>;
    placeholder: string;
    defaultValue?: string;
    onChange: (value: any) => void;
    type?: string;
    disabled?: boolean;
}
const Input = (props: IProps) => {

    return (
        <FlexRow direction={"column"} align={"start"}>
            <Label placeholder={props.placeholder} />
            <div className={styles.container}>
                {props.prefix && (
                    <div className={styles.prefix}>
                        {props.prefix}
                    </div>
                )}
                <input
                    defaultValue={props.defaultValue}
                    className={`${styles.input}`}
                    onChange={(value) => {
                        if (props.number) {
                            props.onChange(value.currentTarget.value.replace(',', '.'));
                        } else {
                            props.onChange(value.currentTarget.value);
                        }
                    }}
                    onWheel={e => e.currentTarget.blur()}
                    value={props.value ?? props.defaultValue ?? ''}
                    type={props.type}
                    style={props.inputStyle}
                    disabled={props.disabled}
                    ref={props.inputRef}
                />
                {props.suffix && (
                    <div className={styles.suffix}>
                        {props.suffix}
                    </div>
                )}
            </div>
        </FlexRow>
    )

}

export default Input