import * as React from "react"
import { SVGProps } from "react"

const PrintsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width ?? 25} height={props.height ?? 25} viewBox={"0 0 25 25"} {...props}>
        <path
            data-name="print,-industrial,-production,-press,-picture"
            d="M2.574 8.088C1.036 8.088 0 6.223 0 4.044S1.036 0 2.574 0h19.853c1.538 0 2.574 1.865 2.574 4.044s-1.036 4.044-2.574 4.044Zm19.853-1.471c.493 0 1.1-1.1 1.1-2.574s-.61-2.574-1.1-2.574-1.1 1.1-1.1 2.574.607 2.575 1.099 2.575Zm-3.309 12.5V13.6c0-1.556.621-2.574 1.1-2.574h2.206c1.538 0 2.574 1.865 2.574 4.044s-1.036 4.044-2.574 4.044Zm3.309-1.471c.493 0 1.1-1.1 1.1-2.574s-.61-2.574-1.1-2.574-1.1 1.1-1.1 2.574.607 2.575 1.099 2.575ZM3.309 19.117h-.735C1.036 19.117 0 17.252 0 15.073s1.036-4.044 2.574-4.044h1.351a8.724 8.724 0 0 0-.616 3.309Zm14.338 5.147a.735.735 0 0 1-.735.735h-11.4a.735.735 0 0 1-.735-.735V22.73l3.309-3.309 1.166 1.166-.583.583a.735.735 0 0 0 1.04 1.04l3.524-3.524 4.412 4.412Zm0-10.662v7.416l-3.892-3.892a.735.735 0 0 0-1.04 0l-2.421 2.421-1.686-1.686a.735.735 0 0 0-1.04 0L4.779 20.65v-6.313c0-2.586 1.2-4.779 2.941-4.779h12.5c-1.537.001-2.573 1.865-2.573 4.042Zm-8.456 2.573a2.206 2.206 0 1 0-2.206-2.206 2.206 2.206 0 0 0 2.206 2.207Zm0-1.471a.735.735 0 1 1 .735-.735.735.735 0 0 1-.735.737Z"
            fill={props.fill ?? "#707070"}
        />
    </svg>
)

export default PrintsIcon
