import * as React from "react"
import { SVGProps } from "react"

const PeopleIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name="002-customer"
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? 25}
        height={props.height ?? 21.813}
        viewBox={"0 0 25 21.813"}
        {...props}
    >
        <circle
            data-name="Ellipse 1"
            cx={4.412}
            cy={4.412}
            r={4.412}
            transform="translate(8.355)"
            fill={props.fill ?? "#707070"}
        />
        <circle
            data-name="Ellipse 2"
            cx={2.758}
            cy={2.758}
            r={2.758}
            transform="translate(18.282 3.309)"
            fill={props.fill ?? "#707070"}
        />
        <ellipse
            data-name="Ellipse 3"
            cx={3.309}
            cy={2.758}
            rx={3.309}
            ry={2.758}
            transform="translate(.633 3.309)"
            fill={props.fill ?? "#707070"}
        />
        <path
            data-name="Path 3"
            d="M6.552 10.908c-1.057-.866-2.015-.751-3.237-.751A3.309 3.309 0 0 0 0 13.452v5.333a1.435 1.435 0 0 0 1.436 1.431c3.419 0 3.007.062 3.007-.147 0-3.78-.443-6.551 2.109-9.161Z"
            fill={props.fill ?? "#707070"}
        />
        <path
            data-name="Path 4"
            d="M13.663 10.176c-2.135-.178-3.991 0-5.591 1.323-2.679 2.146-2.163 5.034-2.163 8.568a1.713 1.713 0 0 0 1.71 1.71c10.307 0 10.717.332 11.328-1.021.2-.458.145-.312.145-4.691a5.92 5.92 0 0 0-5.429-5.889Z"
            fill={props.fill ?? "#707070"}
        />
        <path
            data-name="Path 5"
            d="M21.685 10.157c-1.229 0-2.181-.113-3.237.751 2.537 2.591 2.109 5.173 2.109 9.16 0 .211-.342.147 2.956.147A1.486 1.486 0 0 0 25 18.734v-5.282a3.309 3.309 0 0 0-3.315-3.295Z"
            fill={props.fill ?? "#707070"}
        />
    </svg>
)

export default PeopleIcon
