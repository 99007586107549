import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

const languages = [
    'da',
]

const namespaces = [
    'auth',
    'common',
    'errors',
]

let resources = {};

languages.forEach(language => {

    resources[language] = {}

    namespaces.forEach(ns => {
        resources[language][ns] = require(`./locales/${language}/${ns}.json`);
    })
})

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
            resources: resources,

            defaultNS: 'common',

            fallbackLng: 'da',

            debug: true,
            
            returnNull: false,

            saveMissing: true,

            react: {
                useSuspense: false
            },

            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
        }
    );


export default i18n;
