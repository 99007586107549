import React, { ReactElement } from "react";
import styles from "./OrderFunction.module.scss";
import CaretIcon from "Assets/SVG/CaretIcon";
import Loader from "../../../Assets/SVG/Loader";

interface IProps {
    icon: ReactElement;
    title: string;
    subtitle: string;
    color: 'red' | 'green' | 'yellow';
    onClick: () => void;
    isLoading?: boolean;
}
const OrderFunction = (props: IProps) => {

    const { isLoading, icon, color, title, subtitle, onClick} = props;

    const getBackgroundColor = () => {
        const green = "#21BF2D";
        const red = "#E91D25";
        const yellow = "#FFBA00";

        switch (color) {
            case "red":
                return red;
            case "green":
                return green;
            case "yellow":
                return yellow;
        }
    }

    return (
        <div onClick={onClick} className={styles.container}>
            <div style={{backgroundColor: getBackgroundColor()}}>
                {isLoading ? (
                    <Loader height={30} width={30} />
                ) : (
                    React.cloneElement(icon, {fill: 'white', height: 30, width: 30})
                )}
            </div>
            <div>
                <div className={styles.subTitle}>
                    {subtitle}
                </div>
                <div className={styles.title}>
                    {title}
                </div>
            </div>
            <div>
                <CaretIcon />
            </div>
        </div>
    )

}

export default OrderFunction