import {useTranslation} from "react-i18next";
import React, {useEffect, useMemo, useState} from "react";
import Label from "../../../Components/Label";
import Select from "../../../Components/Select";
import {IQueryParam} from "../../../Apis/Models/IQueryParams";
import useFilteredList from "../../../Hooks/UseFilteredList";
import {Input} from "../../../Components/Input";

interface Fields {
    [key: string]: IQueryParam;
}
interface IProps {
    filterUpdated: (params: Fields, resetParams: boolean) => void;
    currentValue: Fields;
}
const CustomerListFilter = (props: IProps) => {

    const { filterUpdated, currentValue } = props;
    const { t } = useTranslation();

    const statuses = useMemo(() => [
        { value: undefined,label: t('customerBlocked.all')},
        { value: "true" ,label: t('customerBlocked.blocked')},
        { value: "false",label: t('customerBlocked.notBlocked')},
    ], [t]);

    const [ query, setQuery ] = useState<Fields>({
        barred: "false"
    });

    useEffect(() => {
        setQuery(currentValue);
    }, [currentValue]);

    const [ filteredStatuses, setFilteredStatuses ] = useFilteredList(statuses, x => [x.label]);

    const setQueryField = (fieldName: 'barred' | 'search', value: IQueryParam) => {
        const newValue = {...query, [fieldName]: value }
        setQuery(newValue);
        filterUpdated(newValue, true);
    }

    return (
        <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 20}}>
            <div>
                <Input name={"search"} value={query['search']} onChange={value => setQueryField('search', value)} placeholder={t('search')} />
            </div>

            <div>
                <Label placeholder={t('blocked')} />
                <Select name={"barred"} value={query['barred']} onChange={value => setQueryField('barred', value)} onFilter={setFilteredStatuses}>
                    {filteredStatuses?.map((status, index) => <Select.Option key={index} value={status.value} label={status.label} />)}
                </Select>
            </div>
        </div>
    )

}

export default CustomerListFilter