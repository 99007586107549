import * as React from "react"
import { SVGProps } from "react"

const OrdersIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name="001-shopping-list"
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? 16.999}
        height={props.height ?? 22.665}
        viewBox={"0 0 16.999 22.665"}
        {...props}
    >
        <g data-name="Group 2">
            <g data-name="Group 1">
                <path
                    data-name="Path 1"
                    d="M12.041 1.417h-1.538a2.125 2.125 0 0 0-4.007 0H4.959a.709.709 0 0 0-.708.708v2.833a.709.709 0 0 0 .708.708h7.083a.709.709 0 0 0 .708-.708V2.125a.709.709 0 0 0-.709-.708Z"
                    fill={props.fill ?? "#707070"}
                />
            </g>
        </g>
        <g data-name="Group 4">
            <g data-name="Group 3">
                <path
                    data-name="Path 2"
                    d="M15.582 2.833h-1.416v2.125a2.127 2.127 0 0 1-2.125 2.125H4.958a2.127 2.127 0 0 1-2.125-2.125V2.833H1.417A1.419 1.419 0 0 0 0 4.25v17a1.4 1.4 0 0 0 1.417 1.417h14.165A1.4 1.4 0 0 0 17 21.248v-17a1.4 1.4 0 0 0-1.418-1.415Zm-7.29 12.542-2.833 2.833a.711.711 0 0 1-1 0L3.04 16.792a.708.708 0 0 1 1-1l.917.915 2.332-2.332a.709.709 0 0 1 1 1Zm0-5.666-2.833 2.833a.711.711 0 0 1-1 0L3.04 11.126a.708.708 0 0 1 1-1l.917.915 2.332-2.332a.709.709 0 0 1 1 1Zm5.165 7.29h-2.833a.709.709 0 1 1 0-1.417h2.833a.709.709 0 0 1 0 1.417Zm0-5.666h-2.833a.709.709 0 1 1 0-1.417h2.833a.709.709 0 0 1 0 1.417Z"
                    fill={props.fill ?? "#707070"}
                />
            </g>
        </g>
    </svg>
)

export default OrdersIcon
