import * as React from "react"
import { SVGProps } from "react"

const XIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={12.828}
        height={12.828}
        {...props}
        viewBox={"0 0 12.828 12.828"}
    >
        <g
            data-name="Group 1452"
            fill="none"
            stroke={props.fill ?? "#e91d25"}
            strokeLinecap="round"
            strokeWidth={2}
        >
            <path data-name="Line 77" d="m11.415 11.414-10-10" />
            <path data-name="Line 78" d="m1.415 11.414 10-10" />
        </g>
    </svg>
)

export default XIcon
