import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import { IPaginatedList } from "Apis/Models/IPaginatedList";
import { IPrintingTypeDto} from "Apis/Models/IPrintDto";
import {IQueryParams} from "./Models/IQueryParams";

export const PRINTING_TYPE_CACHE_KEY = 'PRINTING_TYPE_CACHE_KEY '
export const usePrintingTypes = (queryable: Partial<IQueryParams> | null = null) => {
    return useQuery<IPaginatedList<IPrintingTypeDto>>([PRINTING_TYPE_CACHE_KEY, queryable], async () => {
        return await request<IPaginatedList<IPrintingTypeDto>>({
            url: `/prints/printingType`,
            method: 'GET',
            queryParams: queryable
        })
    });
}

export const usePrintingType = (id: number) => {
    return useQuery<IPrintingTypeDto>([PRINTING_TYPE_CACHE_KEY, id], async () => {
        return await request<IPrintingTypeDto>({
            url: `/prints/printingType/${id}`,
            method: 'GET',
        })
    });
}

export const useMutatePrintingType = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (printingType: IPrintingTypeDto): Promise<IPrintingTypeDto> => {
        if (!printingType.id) {
            return await request<IPrintingTypeDto>({
                url: `/prints/printingType`,
                method: 'POST',
                data: printingType
            })
        } else {
            return await request<IPrintingTypeDto>({
                url: `/prints/printingType/${printingType.id}`,
                method: 'PUT',
                data: printingType
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(PRINTING_TYPE_CACHE_KEY)
        }
    })
}
