import * as RadixSeparator from '@radix-ui/react-separator';
import { CSSProperties } from "react";
import styles from './Separator.module.scss';

interface IProps {
    style?: CSSProperties;
}
const Separator = (props: IProps) => {
    
    const { style } = props;
    
    return (
        <RadixSeparator.Root style={style} className={styles.root} />
    )
}

export default Separator
