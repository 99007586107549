import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import { IPaginatedList } from "Apis/Models/IPaginatedList";
import {ITransferTypeDto} from "Apis/Models/IPrintDto";
import {IQueryParams} from "./Models/IQueryParams";

export const TRANSFER_TYPE_CACHE_KEY = 'TRANSFER_TYPE_CACHE_KEY '
export const useTransferTypes = (queryable: Partial<IQueryParams> | null = null) => {
    return useQuery<IPaginatedList<ITransferTypeDto>>([TRANSFER_TYPE_CACHE_KEY, queryable], async () => {
        return await request<IPaginatedList<ITransferTypeDto>>({
            url: `/prints/transferType`,
            method: 'GET',
            queryParams: queryable
        })
    });
}

export const useTransferType = (id: number) => {
    return useQuery<ITransferTypeDto>([TRANSFER_TYPE_CACHE_KEY, id], async () => {
        return await request<ITransferTypeDto>({
            url: `/prints/transferType/${id}`,
            method: 'GET',
        })
    });
}

export const useMutateTransferType = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (transferType: ITransferTypeDto): Promise<ITransferTypeDto> => {
        if (!transferType.id) {
            return await request<ITransferTypeDto>({
                url: `/prints/transferType`,
                method: 'POST',
                data: transferType
            })
        } else {
            return await request<ITransferTypeDto>({
                url: `/prints/transferType/${transferType.id}`,
                method: 'PUT',
                data: transferType
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(TRANSFER_TYPE_CACHE_KEY)
        }
    })
}
