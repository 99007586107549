import * as React from "react"
import { SVGProps } from "react"

const InventoryIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} {...props} viewBox={"0 0 25 25"}>
    <path
      data-name="Path 548"
      d="M23.438 21.875H1.563a1.042 1.042 0 1 1 0-2.083h21.875a1.042 1.042 0 1 1 0 2.083Z"
      fill={props.fill ?? "#707070"}
    />
    <path
      data-name="Path 549"
      d="M23.438 10.416H1.563a1.042 1.042 0 0 1 0-2.083h21.875a1.042 1.042 0 0 1 0 2.083Z"
      fill={props.fill ?? "#707070"}
    />
    <path
      data-name="Path 550"
      d="M1.042 25A1.042 1.042 0 0 1 0 23.958V1.042a1.042 1.042 0 0 1 2.083 0v22.916A1.042 1.042 0 0 1 1.042 25Z"
      fill={props.fill ?? "#707070"}
    />
    <path
      data-name="Path 551"
      d="M23.959 25a1.042 1.042 0 0 1-1.042-1.042V1.042a1.042 1.042 0 1 1 2.083 0v22.916A1.042 1.042 0 0 1 23.959 25Z"
      fill={props.fill ?? "#707070"}
    />
    <path
      data-name="Path 552"
      d="M8.073 13.542v.781a.781.781 0 0 1-1.563 0v-.781H4.688a.521.521 0 0 0-.521.521v4.167a.521.521 0 0 0 .521.521h5.208a.521.521 0 0 0 .521-.521v-4.167a.521.521 0 0 0-.521-.521Z"
      fill={props.fill ?? "#707070"}
    />
    <path
      data-name="Path 553"
      d="M16.927 12.5v.781a.781.781 0 0 1-1.562 0V12.5h-2.344a.521.521 0 0 0-.521.521v5.208a.521.521 0 0 0 .521.521h6.25a.521.521 0 0 0 .521-.521v-5.208a.521.521 0 0 0-.521-.521Z"
      fill={props.fill ?? "#707070"}
    />
    <path
      data-name="Path 554"
      d="M10.156 2.083v.781a.781.781 0 1 1-1.563 0v-.781H5.729a.521.521 0 0 0-.521.521v4.167a.521.521 0 0 0 .521.521h7.292a.521.521 0 0 0 .521-.521V2.604a.521.521 0 0 0-.521-.521Z"
      fill={props.fill ?? "#707070"}
    />
  </svg>
)

export default InventoryIcon
