import Label from "Components/Label/Label";
import Select from "Components/Select/index";
import { Control, Controller } from "react-hook-form";
import React from "react";
import {RegisterOptions} from "react-hook-form/dist/types/validator";

interface IProps {
    control: Control<any, any> | undefined;
    children?: React.ReactNode;
    placeholder: string;
    name: string;
    onFilter?: (value: string) => void;
    onChange?: (value: number) => void;
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    disabled?: boolean;
    label?: React.ReactElement;
    multiple?: boolean;
}
const ControlledSelect = (props: IProps) => {
    
    const { control, children, name, placeholder, onChange } = props;

    return (
        <Controller rules={props.rules} control={control} name={name} render={({ field, fieldState }) => (
            <>
                {props.label ? props.label : <Label placeholder={placeholder} />}
                <Select multiple={props.multiple} disabled={props.disabled} error={fieldState.error} onFilter={props.onFilter} name={props.name} onChange={(value) => {
                    field.onChange(value);
                    onChange?.(value);
                }} value={field.value}>
                    {children}
                </Select>
            </>
        )} />
    )
}

export default ControlledSelect
