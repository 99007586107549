import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PrintPlacementForm } from "Features/Prints";
import PrintIcon from "Assets/SVG/PrintIcon";

const New = () => {
    
    const { t } = useTranslation();
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={t('newPrintPlacement')} icon={<PrintIcon />} includeBox>
            <PrintPlacementForm onSubmit={_ => navigate(-1)} />
        </PageLayout>
    )
}

export default New
