import PageLayout from "Layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PrintIcon from "Assets/SVG/PrintIcon";
import {useTransferType} from "../../../Apis/TransferTypeApi";
import TransferTypeForm from "../../../Features/Prints/Components/TransferTypeForm";

const Edit = () => {
    
    const { t } = useTranslation();
    
    const { id } = useParams();
    
    const { data: transferType } = useTransferType(parseInt(id!));
    
    const navigate = useNavigate();
    
    return (
        <PageLayout title={t('updateTransferType')} icon={<PrintIcon />} includeBox>
            <TransferTypeForm transferType={transferType} onSubmit={_ => navigate(-1)} />
        </PageLayout>
    )
}

export default Edit;
