import * as React from "react"
import { SVGProps } from "react"
const TagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox={"0 0 24 24"} width={24} height={24} fill="none" {...props}>
    <g fill={props.fill ?? "#707070"} clipPath="url(#a)">
      <path d="M18.72 1.277h-7.369a1.76 1.76 0 0 0-1.244.516l-9.592 9.59a1.76 1.76 0 0 0 0 2.49l7.37 7.368a1.76 1.76 0 0 0 2.488 0l9.592-9.59c.33-.33.515-.778.515-1.246V3.037a1.76 1.76 0 0 0-1.76-1.76Zm-3.08 6.16a1.32 1.32 0 1 1 .001-2.64 1.32 1.32 0 0 1 0 2.64Z" />
      <path d="m22.24 3.037-.002 8.192c0 .405-.16.795-.448 1.082l-9.747 9.747.15.15a1.76 1.76 0 0 0 2.488 0l8.802-8.8c.33-.33.516-.778.516-1.245V4.797a1.76 1.76 0 0 0-1.76-1.76Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill={props.fill ?? "#fff"} d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default TagIcon
