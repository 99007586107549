import * as React from "react"
import { SVGProps } from "react"

const GuitarIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name="003-classic-acoustic-guitar"
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? 25}
        height={props.height ?? 25.278}
        viewBox={"0 0 25 25.278"}
        {...props}
    >
        <g data-name="Group 5">
            <path
                data-name="Path 6"
                d="M12.21 23.759a5.8 5.8 0 0 0 1.3-5.606 1.692 1.692 0 0 1 1.294-1.5 4.032 4.032 0 0 0 .85-.166c1.8-.562 3.1-2.383 2.583-3.2-.344-.545-.689-.862-1.711-.357q-.175.086-.377.206a1.1 1.1 0 0 1-1.3-.105c-.372-.415-.174-1.1.022-1.542l5.06-5.14.026-.03.133-.136a1.06 1.06 0 0 1 1.424-.16c.015.015.024.034.038.05.02-.05.042-.1.066-.154a6.216 6.216 0 0 1 1.256-1.729A6.462 6.462 0 0 1 24.467 3c.049-.025.1-.045.142-.067l.257-.257A1 1 0 0 1 25 2.07a2.69 2.69 0 0 1-.452-.965.235.235 0 0 0-.244-.246.587.587 0 0 0-.2.034.587.587 0 0 0 .037-.193.235.235 0 0 0-.25-.25 2.688 2.688 0 0 1-.964-.45 1 1 0 0 1-.607.134l-.256.257c-.021.047-.042.094-.066.142a6.45 6.45 0 0 1-1.194 1.587 6.212 6.212 0 0 1-1.729 1.256q-.078.036-.154.066c.016.014.035.023.05.038a1.006 1.006 0 0 1-.047 1.287l-5.263 5.345c-.449.253-1.274.608-1.656.062a1.637 1.637 0 0 1-.027-1.614l.054-.1c.51-1.029.193-1.374-.353-1.719-.816-.515-2.636.781-3.2 2.583a4.043 4.043 0 0 0-.108.44s0 .013 0 .034c-.012.065-.025.13-.034.194a1.9 1.9 0 0 1-2.18 1.608 5.559 5.559 0 0 0-4.64 1.476c-2.378 2.378-1.914 6.7 1.039 9.652s7.276 3.409 9.654 1.031Z"
                fill={props.fill ?? "#707070"}
            />
        </g>
    </svg>
)

export default GuitarIcon
