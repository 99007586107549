import { Navigate, Route, Routes } from "react-router-dom";
import Index from "./Index";
import Show from "Pages/Orders/Show";
import Edit from "./Edit";
import New from "./New";

const OrderRoutes = () => {
    
    return (
        <Routes>
            <Route path={`:id`} element={<Show />}/>

            <Route path={`:id/edit`} element={<Edit />}/>
            <Route path={`new`} element={<New />}/>

            <Route path={`/`} element={<Index />}/>

            <Route path={"*"} element={<Navigate to={"/orders?page=0&status=Open&reverseTagSearch=false"}/>}/>
        </Routes>
    )
}

export default OrderRoutes
