import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {IQueryParam} from "../../../Apis/Models/IQueryParams";
import {Input} from "../../../Components/Input";

interface Fields {
    [key: string]: IQueryParam;
}
interface IProps {
    filterUpdated: (params: Fields, resetParams: boolean) => void;
    currentValue: Fields;
}
const UsersListFilter = (props: IProps) => {

    const { filterUpdated, currentValue } = props;
    const { t } = useTranslation();

    const [ query, setQuery ] = useState<Fields>(currentValue);

    useEffect(() => {
        setQuery(currentValue);
    }, [currentValue]);

    const setQueryField = (fieldName: 'search', value: IQueryParam) => {
        const newValue = {...query, [fieldName]: value }
        setQuery(newValue);
        filterUpdated(newValue, true);
    }

    return (
        <div style={{display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', gap: 20}}>
            <div>
                <Input name={"search"} value={query['search'] ?? ''} onChange={value => setQueryField('search', value)} placeholder={t('search')} />
            </div>
        </div>
    )

}

export default UsersListFilter