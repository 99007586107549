import * as React from "react"
import { SVGProps } from "react"

const HandsIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        data-name="004-partnership"
        xmlns="http://www.w3.org/2000/svg"
        width={props.width ?? 25}
        height={props.height ?? 21.932}
        viewBox={"0 0 25 21.932"}
        {...props}
    >
        <path
            data-name="Path 7"
            d="m3.908 11.77-1.554 1.554a1.1 1.1 0 0 0 1.554 1.554l1.554-1.554a1.1 1.1 0 0 0-1.554-1.554Zm0 0"
            fill={props.fill ?? "#707070"}
        />
        <path
            data-name="Path 8"
            d="m9.087 16.948-1.554 1.554a1.1 1.1 0 1 0 1.554 1.554l1.554-1.554a1.1 1.1 0 0 0-1.554-1.554Zm0 0"
            fill={props.fill ?? "#707070"}
        />
        <path
            data-name="Path 9"
            d="m12.713 21.61.518-.518a1.1 1.1 0 0 0-1.554-1.554l-.518.518a1.1 1.1 0 1 0 1.554 1.554Zm0 0"
            fill={props.fill ?? "#707070"}
        />
        <path
            data-name="Path 10"
            d="m7.533 13.323-2.589 2.589a1.1 1.1 0 0 0 1.554 1.554l2.59-2.589a1.1 1.1 0 0 0-1.554-1.554Zm0 0"
            fill={props.fill ?? "#707070"}
        />
        <path
            data-name="Path 11"
            d="M9.346 2.188 7.465.288a.732.732 0 0 0-1.036 0L.215 6.502a.732.732 0 0 0 0 1.036l1.27 1.289a3.665 3.665 0 0 1 .885 1.431l.245.734.259-.259a2.567 2.567 0 0 1 3.625 0 2.533 2.533 0 0 1 .637 1.1 2.546 2.546 0 0 1 3.44 3.44 2.517 2.517 0 0 1 1.849 2.48 2.554 2.554 0 0 1 1.843.747l1.032 1.038a1.1 1.1 0 1 0 1.554-1.554l-1.811-1.811a.733.733 0 1 1 1.036-1.036l1.813 1.813a1.1 1.1 0 0 0 1.554-1.554l-1.813-1.813a.733.733 0 1 1 1.036-1.036l1.813 1.813a1.1 1.1 0 1 0 1.554-1.554L17.58 8.351a3.668 3.668 0 0 1-4.609-.466.733.733 0 0 0-1.036 0 3.666 3.666 0 0 1-5.179 0 2.2 2.2 0 0 1 0-3.107Zm0 0"
            fill={props.fill ?? "#707070"}
        />
        <path
            data-name="Path 12"
            d="M18.571.215a.732.732 0 0 0-1.036 0l-1.549 1.549a1.45 1.45 0 0 1-1.025.425h-2.633a2.2 2.2 0 0 0-1.554.644L7.792 5.815a.725.725 0 0 0 0 1.036 2.194 2.194 0 0 0 3.107 0 2.2 2.2 0 0 1 2.848-.223 2.816 2.816 0 0 0 3.707-.117l.179-.179 5.039 5.039a1.512 1.512 0 0 0 0-.785 1.592 1.592 0 0 1 .375-1.385l1.736-1.736a.732.732 0 0 0 0-1.036Zm0 0"
            fill={props.fill ?? "#707070"}
        />
    </svg>
)

export default HandsIcon
