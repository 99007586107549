import styles from "Pages/Orders/Show.module.scss";
import OrderLineDetails from "./OrderLineDetails";
import {IOrderLineDto} from "Apis/Models/IOrderDto";
import { useTranslation } from "react-i18next";
import {IPartnerDto} from "Apis/Models/IPartnerDto";
import FlexRow from "Components/FlexRow";
import {formatNumber} from "../../../Utils/NumberUtils";
import {getOrderLinesTotal, getOrderLinesTotalInclVat, getOrderLinesTotalVat} from "../Utils/OrderLineUtils";

interface IProps {
    lines: IOrderLineDto[];
    partners?: IPartnerDto[];
}
const OrderLineGroup = ({lines, partners}: IProps) => {

    const { t } = useTranslation();

    return (
        <div key={lines[0].outsourcingPartnerId ?? 'inhouse'}>
            <FlexRow justify={"space-between"}>
                <div className={styles.outsourcingPartnerHeader}>
                    {!lines[0].outsourcingPartnerId ? t('inhouse') : partners?.find(x => x.id === lines[0].outsourcingPartnerId)?.name}
                </div>
            </FlexRow>
            {lines.map(x => <OrderLineDetails key={x.id} orderLine={x} />)}
            
            <FlexRow justify={"end"}>
                <div className={styles.total}>
                    <div>
                        {t('total')}
                    </div>
                    <div>
                        {formatNumber(getOrderLinesTotal(lines))} DKK
                    </div>
                    <div>
                        {t('vat')}
                    </div>
                    <div>
                        {formatNumber(getOrderLinesTotalVat(lines))} DKK
                    </div>
                    <div>
                        {t('totalInclVat')}
                    </div>
                    <div>
                        {formatNumber(getOrderLinesTotalInclVat(lines))} DKK
                    </div>
                </div>
            </FlexRow>
        </div>
    )

}

export default OrderLineGroup