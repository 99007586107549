import * as React from "react"
import { SVGProps } from "react"

const FileIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={12.643}
        height={16.597}
        {...props}
        viewBox={"0 0 12.643 16.597"}
    >
        <g fill={props.fill ?? "#cfc6c6"}>
            <path data-name="Path 556" d="M2.918 6.84h.972v.973h-.972Z" />
            <path data-name="Path 557" d="M9.725.285v2.633h2.633Z" />
            <path data-name="Path 558" d="M5.835 10.73h.973v.972h-.973Z" />
            <path data-name="Path 559" d="M8.753 6.84h.972v.973h-.972Z" />
            <path
                data-name="Path 560"
                d="M9.239 3.89a.486.486 0 0 1-.486-.486V0H.486A.486.486 0 0 0 0 .486v15.625a.486.486 0 0 0 .486.486h11.67a.486.486 0 0 0 .486-.486V3.89ZM10.7 8.3a.486.486 0 0 1-.486.486h-.517a3.4 3.4 0 0 1-1.914 2.622v.782a.486.486 0 0 1-.486.486H5.349a.486.486 0 0 1-.486-.486v-.781a3.4 3.4 0 0 1-1.914-2.622h-.517a.486.486 0 0 1-.486-.486V6.354a.486.486 0 0 1 .486-.486h1.945a.486.486 0 0 1 .486.486V8.3a.486.486 0 0 1-.486.486h-.443a2.459 2.459 0 0 0 .929 1.49.488.488 0 0 1 .486-.517h1.945a.488.488 0 0 1 .486.521 2.414 2.414 0 0 0 .93-1.493h-.444A.486.486 0 0 1 7.78 8.3V6.354a.486.486 0 0 1 .486-.486h1.945a.486.486 0 0 1 .486.486Z"
            />
        </g>
    </svg>
)

export default FileIcon
