import { useTranslation } from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import { useOrder } from "Apis/OrderApi";
import OrdersIcon from "Assets/SVG/OrdersIcon";
import OrderDetails from "Features/Orders/Components/OrderDetails";
import OrderProjectDetails from "Features/Orders/Components/OrderProjectDetails";
import Space from "Components/Space";
import styles from './Show.module.scss';
import GuitarIcon from "../../Assets/SVG/GuitarIcon";
import OrderProgressBar from "../../Features/Orders/Components/OrderProgressBar";
import PageHeader from "../../Layouts/PageHeader";
import PageLoader from "../../Components/PageLoader";
import OrderFunctions from "../../Features/Orders/Components/OrderFunctions";
import Button from "../../Components/Button";
import ShowOrderLines from "../../Features/Orders/Components/ShowOrderLines";
import OrderLogs from "../../Features/Orders/Components/OrderLogs";
import DotsIcon from "../../Assets/SVG/DotsIcon";
import OrderInvoices from "Features/Orders/Components/OrderInvoices";

const Show = () => {
    
    const { t } = useTranslation();
    
    const { id } = useParams();

    const { data: order, isLoading: isLoadingOrder } = useOrder(parseInt(id!));

    const navigate = useNavigate();

    return (
        <PageLoader shown={isLoadingOrder}>
            <PageHeader title={`${t('order')} #${order?.orderNumber}`} icon={<OrdersIcon />} header={(
                <Button prefix={<OrdersIcon fill={"#FFF"} />} text={t('editOrder')} type={"primary"} onClick={() => navigate(`/orders/${id}/edit`)} />
            )} />

            <div className={styles.layout}>

                <OrderProgressBar order={order!} />

                <div className={styles.content}>
                    <div className={styles.container}>
                        <Space size={"large"} direction={"vertical"}>
                            <OrderDetails order={order!} />

                            {order?.project !== null && (
                                <div>
                                    <div className={styles.sectionTitle}>
                                        <GuitarIcon fill={"#6D4D4D"} />
                                        {t('project')}
                                    </div>
                                    <OrderProjectDetails order={order!} />
                                </div>
                            )}

                            <ShowOrderLines order={order!} />
                            
                            <OrderInvoices order={order!} />

                            <div>
                                <div className={styles.sectionTitle}>
                                    <DotsIcon fill={"#6D4D4D"} />
                                    {t('logs')}
                                </div>
                                <OrderLogs order={order!} />
                            </div>
                        </Space>
                    </div>

                    <div>
                        <OrderFunctions order={order!} />
                    </div>

                </div>
            </div>

        </PageLoader>
    )
}

export default Show
