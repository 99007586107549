import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import Label from "../../../Components/Label";
import Select from "../../../Components/Select";
import {IQueryParam} from "../../../Apis/Models/IQueryParams";
import {useProjects} from "../../../Apis/ProjectApi";
import useFilteredList from "../../../Hooks/UseFilteredList";
import {Input} from "../../../Components/Input";

interface Fields {
    [key: string]: IQueryParam;
}
interface IProps {
    filterUpdated: (params: Fields, resetParams: boolean) => void;
    currentValue: Fields;
}
const DesignListFilter = (props: IProps) => {

    const { filterUpdated, currentValue } = props;
    const { t } = useTranslation();

    const { data: projects } = useProjects({ includeAll: true });

    const [ query, setQuery ] = useState<Fields>(currentValue);

    useEffect(() => {
        setQuery(currentValue);
    }, [currentValue]);

    const [ filteredProjects, setProjectFilter, projectFilterValue ] = useFilteredList(projects?.items ?? [], x => [x.name]);

    const setQueryField = (fieldName: 'search' | 'projectId', value: IQueryParam) => {
        const newValue = {...query, [fieldName]: value }
        setQuery(newValue);
        filterUpdated(newValue, true);
    }

    return (
        <div style={{display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 20}}>

            <div>
                <Input name={"search"} value={query['search'] ?? ''} onChange={value => setQueryField('search', value)} placeholder={t('search')} />
            </div>

            <div>
                <Label placeholder={t('project')} />
                <Select name={"projectId"} value={query['projectId']} onChange={value => setQueryField('projectId', value)} onFilter={setProjectFilter}>
                    {!projectFilterValue && (
                        <Select.Option value={undefined} label={t('all')} />
                    )}
                    {filteredProjects?.map(project => <Select.Option key={project.id} value={project.id} label={project.name} />)}
                </Select>
            </div>
        </div>
    )

}

export default DesignListFilter