import {IOrderDto} from "./IOrderDto";

export interface IInvoiceDto {
    id: number;
    orderId: number;
    draftInvoiceNumber: number;
    bookedInvoiceNumber: number;
    pdfUri: string;
    economicUrl: string;
    invoiceReference: string;
    orders: IOrderDto[]
    invoiceReferenceNumber: number;
    invoiceStatus: InvoiceStatus
}

export enum InvoiceStatus {
    Draft = 'Draft',
    Booked = 'Booked',
    Paid = 'Paid',
}