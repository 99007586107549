import { useMutation, useQuery, useQueryClient } from "react-query";
import { request } from "Apis/BaseApi";
import { IPaginatedList } from "Apis/Models/IPaginatedList";
import { IPrintDto } from "Apis/Models/IPrintDto";
import {IQueryParams} from "./Models/IQueryParams";

export const PRINTS_CACHE_KEY = 'PRINTS_CACHE_KEY '
export const usePrints = (queryable: Partial<IQueryParams> | null = null) => {
    return useQuery<IPaginatedList<IPrintDto>>([PRINTS_CACHE_KEY, queryable], async () => {
        return await request<IPaginatedList<IPrintDto>>({
            url: `/prints`,
            method: 'GET',
            queryParams: queryable
        })
    });
}

export const usePrint = (id: number) => {
    return useQuery<IPrintDto>([PRINTS_CACHE_KEY, id], async () => {
        return await request<IPrintDto>({
            url: `/prints/${id}`,
            method: 'GET',
        })
    });
}

export const useMutatePrint = () => {
    
    const queryClient = useQueryClient();
    
    return useMutation(async (print: IPrintDto): Promise<IPrintDto> => {
        if (!print.id) {
            return await request<IPrintDto>({
                url: `/prints`,
                method: 'POST',
                data: print
            })
        } else {
            return await request<IPrintDto>({
                url: `/prints/${print.id}`,
                method: 'PUT',
                data: print
            })
        }
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(PRINTS_CACHE_KEY)
        }
    })
}

export const useDeletePrint = () => {

    const queryClient = useQueryClient();

    return useMutation(async (print: IPrintDto): Promise<IPrintDto> => {
        return await request<IPrintDto>({
            url: `/prints/${print.id}`,
            method: 'DELETE',
        })
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(PRINTS_CACHE_KEY)
        }
    })
}
